import { Component, Input, OnInit } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-tabs-footer',
  templateUrl: './tabs-footer.component.html',
  styleUrls: ['./tabs-footer.component.scss'],
})
export class TabsFooterComponent implements OnInit {

  @Input() tabAtual: String;
  public tab_atual: String;
  constructor(
    private utils: UtilsService
  ) { }

  ngOnInit() {
    
  }

  irPara(pagina){
    this.tab_atual = pagina;
    
    this.utils.navegarPagina(pagina);
  }

}
