import { ModalsService } from 'src/app/services/modals/modals.service';
import { Component, OnInit } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-avaliacao',
  templateUrl: './avaliacao.page.html',
  styleUrls: ['./avaliacao.page.scss'],
})
export class AvaliacaoPage implements OnInit {

  constructor(
    private utils: UtilsService,
    private platform: Platform,
    private modals: ModalsService
  ) { }

  ngOnInit() {
  }

  abrir() {
    if (this.platform.is('iphone') || this.platform.is('ios')) {
      this.utils.link('appstore');
    } else {
      this.utils.link('playstore');
    }

    this.utils.setStorage('avaliacao-app', false);
    this.modals.fecharModal();
  }

}
