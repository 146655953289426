import { Location } from '@angular/common';
import { EventsService } from './../../services/events.service';
import { UtilsService } from './../../services/utils.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { MetatagService } from 'src/app/services/metatag.service';

declare let dataLayer: any;

@Component({
  selector: 'app-modal-produto',
  templateUrl: './modal-produto.page.html',
  styleUrls: ['./modal-produto.page.scss'],
})
export class ModalProdutoPage implements OnInit {
  @ViewChild('target') target;
  public endereco: any;
  public produto: any;
  public dados_produto: any;
  public isBrowser: Boolean;
  public pesquisa: Boolean;
  public adicionais_selecionado: any;
  public usuario: any;
  public rede_logo;
  public marca: any = {};
  public modalidade: any;
  public totalSacola: any = {
    valor: 0,
    texto: 'R$ 0,00',
  };
  public carrinhoFilter: any = null;

  constructor(
    private modalController: ModalController,
    private utils: UtilsService,
    private events: EventsService,
    private alertController: AlertController,
    private metatag: MetatagService,
    private location: Location,
  ) { }

  ionViewDidLeave() {
    this.metatag.removeMetaTag();
  }

  ngOnInit() {
    if (this.utils.getStorage('usuario')) {
      this.usuario = this.utils.getStorage('usuario');
    }
    this.rede_logo = this.utils.getStorage('loja');

    this.modalidade = this.utils.getStorage('modalidade').codigo;

    this.endereco = this.utils.getStorage('endereco');

    const carrinho = this.utils.getStorage('carrinho');
    if (this.utils.getStorage('carrinho') !== null) {

      carrinho.forEach((item: any) => {
        item.preco.unitario.valor = item.preco.total.valor;
        this.totalSacola.valor += item.preco.total.valor;

        this.totalSacola.texto = this.utils.floatToReal(this.totalSacola.valor);
      });
    }

    this.marca = this.utils.getStorage('toda_loja');
    this.produto = this.utils.getStorage('produto');
    this.produto.qtd = 1;

    if (this.produto.preco_alternativos != null) {
      this.produto.precos = [
        {
          'preco': this.produto.preco,
          'minimo': 0
        },
        {
          'preco': this.produto.preco_alternativos,
          'minimo': this.produto.preco_alternativos.valor_minimo
        },
      ]
    }
    this.carrinhoFilter = carrinho?.filter(el => (el.categoriaId != 95)).length

    this.produto.preco = (this.produto.preco_alternativos != null && this.totalSacola.valor >= this.produto.preco_alternativos.valor_minimo && this.carrinhoFilter > 0) ? this.produto.preco_alternativos : this.produto.preco;

    this.produto.preco.unitario =
      this.produto.preco_obs === 1 ? 0 : this.produto.preco.valor;
    this.produto.preco.valor_obrigatorio = this.produto.preco.valor_obrigatorio > 0 ? this.produto.preco.valor_obrigatorio : 0;



    if (this.pesquisa === true) {
      let event = {
        event: 'view_item',
        ecommerce: {
          currency: 'BRL',
          value:
            this.produto.preco_obs === 1
              ? this.produto.preco_partir.valor
              : this.produto.preco.valor,
          origin: this.utils.getStorage('canal-compra') == "whatsapp" ? 'CHATBOT' : 'PWA',
          items: [
            {
              item_id: String(this.produto.id),
              item_name: this.produto.produto,
              currency: 'BRL',
              item_brand: this.marca.nome,
              item_category: this.produto.categoria,
              item_list_id: String(this.produto.categoriaId),
              item_list_name: 'Pesquisa',
              price:
                this.produto.preco_obs === 1
                  ? this.produto.preco_partir.valor
                  : this.produto.preco.valor,
              quantity: 1,
            },
          ],
        },
      };

      dataLayer.push({ ecommerce: null });
      dataLayer.push(event);
    } else {
      let event = {
        event: 'view_item',
        ecommerce: {
          currency: 'BRL',
          value:
            this.produto.preco_obs === 1
              ? this.produto.preco_partir.valor
              : this.produto.preco.valor,
          origin: this.utils.getStorage('canal-compra') == "whatsapp" ? 'CHATBOT' : 'PWA',
          items: [
            {
              item_id: String(this.produto.id),
              item_name: this.produto.produto,
              currency: 'BRL',
              item_brand: this.marca.nome,
              item_category: this.produto.categoria,
              item_list_id: String(this.produto.categoriaId),
              item_list_name: this.produto.categoria,
              price:
                this.produto.preco_obs === 1
                  ? this.produto.preco_partir.valor
                  : this.produto.preco.valor,
              quantity: 1,
            },
          ],
        },
      };

      dataLayer.push({ ecommerce: null });
      dataLayer.push(event);
    }

    this.metatag.setMetaData(
      this.produto.metatitle,
      this.produto.metadescription
    );

    if (this.produto.uri)
      this.location.replaceState(`/tabs/home/${this.produto.uri}`, '', '');
    else
      this.location.replaceState('tabs/home');
  }

  fechar() {
    this.modalController.dismiss();

    this.location.replaceState('/tabs/home', '', '');
  }
  tabelaNutricional() {
    this.utils.link('http://cib.alphacode.com.br/img/tabela_nutricional.pdf', true)

  }
  alteraProduto(tipo: string) {
    if (tipo === 'add') {
      this.produto.preco.valor_obrigatorio =
        this.produto.preco.valor_obrigatorio / this.produto.qtd;
      this.produto.qtd++;
    } else {
      if (this.produto.qtd === 1) {
        return;
      }
      this.produto.preco.valor_obrigatorio =
        this.produto.preco.valor_obrigatorio / this.produto.qtd;
      this.produto.qtd--;
    }

    let totalAdicionais = 0;
    this.produto.observacoes?.forEach((observacoes: any) => {
      if (observacoes.tipo_opcional == 'Adicional') {
        observacoes.adicionais.forEach((item) => {
          totalAdicionais += item.qtd * item.preco.valor;
        });
      }
    });

    this.produto.preco.valor_obrigatorio =
      this.produto.qtd * this.produto.preco.valor_obrigatorio;

    this.produto.preco.valor =
      this.produto.qtd * this.produto.preco.unitario +
      this.produto.preco.valor_obrigatorio +
      this.produto.qtd * totalAdicionais;
    this.produto.preco.texto = this.utils.floatToReal(this.produto.preco.valor);
  }

  alteraAdicional(tipo: string, i: number, index: number) {
    let observacoes = this.produto.observacoes[index];
    let qtdSelecionada = 0;
    if (tipo === 'add') {
      observacoes.adicionais.forEach((adicional) => {
        qtdSelecionada += adicional.qtd;
      });

      if (observacoes.max < qtdSelecionada + 1) {
        this.utils.exibirToast(
          'A quantidade máxima para essa opção já foi atingida.'
        );
        return;
      }

      this.produto.observacoes[index].adicionais[i].qtd++;
    } else {
      if (this.produto.observacoes[index].adicionais[i].qtd === 0) {
        return;
      }

      this.produto.observacoes[index].adicionais[i].qtd--;
    }

    let totalAdicionais = 0;

    this.produto.observacoes.forEach((element) => {
      element.adicionais.forEach((item: any) => {
        totalAdicionais += item.qtd * item.preco.valor;
      });
    });

    this.produto.preco.valor =
      this.produto.qtd * this.produto.preco.unitario +
      this.produto.preco.valor_obrigatorio +
      this.produto.qtd * totalAdicionais;
    this.produto.preco.texto = this.utils.floatToReal(this.produto.preco.valor);
  }

  async addSacola() {
    const loja = this.utils.getStorage('loja');
    const modalidade = this.utils.getStorage('modalidade');
    const modalidade_carrinho = this.utils.getStorage('modalidade_carrinho');

    if (
      (loja.id == 713 || loja.id == 715) &&
      this.usuario != null &&
      (this.usuario?.liberado == null ||
        this.usuario.liberado == undefined ||
        !this.usuario.liberado)
    ) {
      this.utils.exibirToast(
        'Encontramos um erro na sua loja, estamos recarregando seu cardápio!',
        'erro'
      );

      this.modalController.dismiss();

      this.events.publish('atualizar-cardapio', modalidade);

      return;
    }

    if (!loja.statusLoja) {
      this.utils.exibirToast('Restaurante fechado no momento!', 'erro');
      return;
    }

    if (this.utils.getStorage('carrinho') != null) {
      if (
        loja.id != this.utils.getStorage('lojaId') ||
        loja.marcaId != this.utils.getStorage('loja_carrinho').marcaId
      ) {
        const alert = await this.alertController.create({
          header: 'Deseja limpar o carrinho ?',
          message:
            'Você já tem itens adicionados de outra marca no seu carrinho. Deseja limpar o carrinho ?',
          buttons: [
            {
              text: 'Não',
              role: 'cancel',
            },
            {
              text: 'Sim',
              handler: async () => {
                this.utils.removeStorage('carrinho');
                this.utils.removeStorage('modalidade_carrinho');
                this.events.publish('carrinho-numero');

                this.utils.setStorage('lojaId', loja.id);
                this.utils.setStorage('loja_carrinho', loja);
                this.utils.setStorage(
                  'modalidade_carrinho',
                  this.utils.getStorage('modalidade')
                );
                this.addSacola();
              },
            },
          ],
        });
        return await alert.present();
      }
    }

    let carrinho: Array<any> = [];

    if (this.utils.getStorage('carrinho') !== null) {
      carrinho = this.utils.getStorage('carrinho');
    }

    let adicionais = [];
    let opcionais = [];
    let obrigatorios = [];
    let opcionais_gerais = [];
    let erros = 0;

    if (this.produto.observacoes != undefined) {
      this.produto.observacoes.forEach((observacoes: any, index) => {
        switch (observacoes.tipo_opcional) {
          case 'Adicional':
            let adicionalSelecionado = 0;

            observacoes.adicionais.forEach((adicional: any) => {

              adicionalSelecionado += adicional.qtd;

              adicionais.push({
                cod: adicional.cod,
                nome: adicional.opcional,
                preco: {
                  unitario: {
                    valor: adicional.preco.unitario,
                    texto: this.utils.floatToReal(adicional.preco.unitario),
                  },
                  total: {
                    valor: adicional.preco.unitario * adicional.qtd,
                    texto: this.utils.floatToReal(
                      adicional.preco.unitario * adicional.qtd
                    ),
                  },
                },
                itemCategory2:
                  observacoes.tipo_opcional == 'Obrigatório' ||
                    (observacoes.tipo_opcional == 'Adicional' &&
                      observacoes.min > 0) ||
                    observacoes.tipo_opcional == 'Obrigatório' ||
                    observacoes.tipo_opcional == 'Opcional'
                    ? false
                    : true,
                desconto: {
                  unitario: {
                    valor: adicional.preco.unitario,
                    texto: this.utils.floatToReal(adicional.preco.unitario),
                  },
                  total: {
                    valor: adicional.preco.unitario * adicional.qtd,
                    texto: this.utils.floatToReal(
                      adicional.preco.unitario * adicional.qtd
                    ),
                  },
                },
                qtd: adicional.qtd,
                sku: adicional.sku,
              });

              opcionais_gerais.push({
                cod: adicional.cod,
                nome: adicional.opcional,
                tipo: 'adicional',
                categoriaId: observacoes.id_categoria,
                preco: {
                  unitario: {
                    valor: adicional.preco.unitario,
                    texto: this.utils.floatToReal(adicional.preco.unitario),
                  },
                  total: {
                    valor: adicional.preco.unitario * adicional.qtd,
                    texto: this.utils.floatToReal(
                      adicional.preco.unitario * adicional.qtd
                    ),
                  },
                },
                itemCategory2:
                  observacoes.tipo_opcional == 'Obrigatório' ||
                    (observacoes.tipo_opcional == 'Adicional' &&
                      observacoes.min > 0) ||
                    observacoes.tipo_opcional == 'Obrigatório' ||
                    observacoes.tipo_opcional == 'Opcional'
                    ? false
                    : true,
                desconto: {
                  unitario: {
                    valor: adicional.preco.unitario,
                    texto: this.utils.floatToReal(adicional.preco.unitario),
                  },
                  total: {
                    valor: adicional.preco.unitario * adicional.qtd,
                    texto: this.utils.floatToReal(
                      adicional.preco.unitario * adicional.qtd
                    ),
                  },
                },
                qtd: adicional.qtd,
                sku: adicional.sku,
              });
            });
            if (adicionalSelecionado < observacoes.min) {
              this.produto.observacoes[index].sem_escolha = 'ativo';
              erros++;
            } else {
              this.produto.observacoes[index].sem_escolha = '';
            }
            break;

          case 'Opcional':
            let opcionalSelecionado = 0;

            if (observacoes.opcionais.length > 0) {
              
              observacoes.opcionais.forEach((item) => {

                if (item.selecionado) {
                  opcionalSelecionado++;
                }

                opcionais.push({
                  cod: item.cod,
                  opcional: item.opcional,
                  selecionado: item.selecionado,
                  obrigatorio: true,
                  sku: item.sku,
                });

                opcionais_gerais.push({
                  cod: item.cod,
                  opcional: item.opcional,
                  tipo: 'opcional',
                  categoriaId: observacoes.id_categoria,
                  selecionado: item.selecionado,
                  obrigatorio: true,
                  sku: item.sku,
                });
              });

              if (opcionalSelecionado == 0) {
                this.produto.observacoes[index].sem_escolha = 'ativo';
                erros++;
              } else {
                this.produto.observacoes[index].sem_escolha = '';
              }
            }
            break;

          case 'Obrigatório':
            let obrigatorioSelecionado = 0;

            if (observacoes.obrigatorios.length > 0) {
              observacoes.obrigatorios.forEach((item) => {

                if (item.selecionado) {
                  obrigatorioSelecionado++;
                }

                obrigatorios.push({
                  cod: item.cod,
                  opcional: item.opcional,
                  selecionado: item.selecionado,
                  obrigatorio: true,
                  preco: {
                    unitario: {
                      valor: item.preco.unitario,
                      texto: this.utils.floatToReal(item.preco.unitario),
                    },
                    total: {
                      valor: item.preco.unitario,
                      texto: this.utils.floatToReal(item.preco.unitario),
                    },
                  },
                  sku: item.sku,
                });

                opcionais_gerais.push({
                  cod: item.cod,
                  opcional: item.opcional,
                  tipo: 'obrigatorio',
                  categoriaId: observacoes.id_categoria,
                  selecionado: item.selecionado,
                  obrigatorio: true,
                  preco: {
                    unitario: {
                      valor: item.preco.unitario,
                      texto: this.utils.floatToReal(item.preco.unitario),
                    },
                    total: {
                      valor: item.preco.unitario,
                      texto: this.utils.floatToReal(item.preco.unitario),
                    },
                  },
                  sku: item.sku,
                });
              });

              if (obrigatorioSelecionado == 0) {
                this.produto.observacoes[index].sem_escolha = 'ativo';
                erros++;
              } else {
                this.produto.observacoes[index].sem_escolha = '';
              }
            }
            break;

          default:
            break;
        }
      });
    }

    if (erros != 0) {
      this.utils.exibirToast('Por favor preencha todos os campos.');

      setTimeout(() => {
        const alert = document.querySelectorAll('.alert');
        const primeiroAlert = alert[0];

        primeiroAlert.scrollIntoView({ behavior: 'smooth' });
      }, 100);

      return;
    }

    const produto = {
      sku: this.produto.sku,
      tipo: 'Individual',
      categoriaId: this.produto.categoriaId,
      categoria: this.produto.categoria,
      cardapioId: this.produto.cardapioId,
      cod: this.produto.cod,
      id: this.produto.id,
      imagem: this.produto.imagem,
      nome: this.produto.produto,
      descricao: this.produto.descricao,
      alt_produto: this.produto.alt_produto,
      observacao: this.produto.observacao,
      preco: {
        unitario: {
          valor: this.produto.preco.unitario,
          texto: this.utils.floatToReal(this.produto.preco.unitario),
        },
        opcional: {
          valor:
            (this.produto.preco.valor -
              this.produto.preco.unitario * this.produto.qtd) /
            this.produto.qtd,
          texto: this.utils.floatToReal(
            (this.produto.preco.valor -
              this.produto.preco.unitario * this.produto.qtd) /
            this.produto.qtd
          ),
        },
        total: {
          valor: this.produto.preco.valor,
          texto: this.utils.floatToReal(this.produto.preco.valor),
        },
      },
      precos: this.produto.precos,
      desconto: {
        unitario: {
          valor: this.produto.preco.unitario,
          texto: this.utils.floatToReal(this.produto.preco.unitario),
        },
        total: {
          valor: this.produto.preco.valor,
          texto: this.utils.floatToReal(this.produto.preco.valor),
        },
      },
      qtd: this.produto.qtd,
      adicionais: adicionais,
      obrigatorios: obrigatorios,
      opcionais: opcionais,
      opcionais_gerais: opcionais_gerais,
      pesquisa: false,
    };

    if (this.pesquisa === true) {
      produto.pesquisa = true;
    } else {
      produto.pesquisa = false;
    }

    carrinho.push(produto);

    this.adicionais_selecionado = this.filtroAdicionais(adicionais);
    this.dados_produto = produto;

    // this.utils.setStorage('sugestoes', this.utils.getStorage('sugestoes-loja'));
    this.utils.setStorage('lojaId', loja.id);
    this.utils.setStorage('loja_carrinho', loja);
    this.utils.setStorage(
      'modalidade_carrinho',
      this.utils.getStorage('modalidade')
    );
    this.utils.setStorage('carrinho', carrinho);
    this.events.publish('atualizar-tabs');
    this.events.publish('carrinho-numero');

    if (this.utils.getStorage('usuario') == null) {
      this.modalController.dismiss();
      this.utils.exibirToast(
        'Faça o login para adicionar um produto no carrinho!',
        'erro'
      );
      this.utils.navegarPaginaRoot('login');
      return;
    }

    if (this.pesquisa === true) {
      let obrigatorio = '';
      let opcionais = '';
      let preco = '';

      if (this.produto.observacoes === undefined) {
        obrigatorio = '';
      } else {
        this.produto.observacoes.forEach((element) => {
          if (element.tipo_opcional === 'Obrigatório') {
            element.obrigatorios.forEach((obr) => {
              if (obr.selecionado) {
                obrigatorio = obr.opcional;
                preco = obr.preco.unitario;
              }
            });
          }

          if (element.tipo_opcional === 'Opcional') {
            element.opcionais.forEach((opc) => {
              if (opc.selecionado) {
                opcionais = opc.opcional;
              }
            });
          }
        });
      }

      const event: any = {
        event: 'add_to_cart',
        ecommerce: {
          currency: 'BRL',
          value: produto.preco.total.valor,
          origin: this.utils.getStorage('canal-compra') == "whatsapp" ? 'CHATBOT' : 'PWA',
          items: [
            {
              item_id: String(produto.id),
              item_name: produto.nome,
              currency: 'BRL',
              item_brand: this.marca.nome,
              item_category: produto.categoria,
              item_list_id: String(produto.categoriaId),
              item_list_name: 'Pesquisa',
              item_variant:
                obrigatorio == ''
                  ? opcionais
                  : opcionais == ''
                    ? obrigatorio
                    : obrigatorio != '' && opcionais != ''
                      ? obrigatorio + ' | ' + opcionais
                      : '',
              price:
                produto.preco.unitario.valor == ''
                  ? preco
                  : produto.preco.unitario.valor,
              quantity: produto.qtd,
              item_category2: false,
            },
          ],
        },
      };

      this.adicionais_selecionado.forEach((item) => {
        event.ecommerce.items.push({
          item_id: String(item.cod),
          item_name: item.nome,
          currency: 'BRL',
          item_brand: this.marca.nome,
          item_category: this.dados_produto.categoria,
          item_list_id: String(this.dados_produto.categoriaId),
          item_list_name: 'Pesquisa',
          item_variant: '',
          price: item.preco.unitario.valor,
          quantity: item.qtd && produto.qtd > 1 ? produto.qtd : item.qtd,
          item_category2: item.itemCategory2,
          item_category3: this.dados_produto.id,
        });
      });

      dataLayer.push({ ecommerce: null });
      dataLayer.push(event);
    } else {
      let obrigatorio = '';
      let preco = '';
      let opcionais = '';

      if (this.produto.observacoes === undefined) {
        obrigatorio = '';
      } else {
        this.produto.observacoes.forEach((element) => {
          if (element.tipo_opcional === 'Obrigatório') {
            element.obrigatorios.forEach((obr) => {
              if (obr.selecionado) {
                obrigatorio = obr.opcional;
                preco = obr.preco.unitario;
              }
            });
          }

          if (element.tipo_opcional === 'Opcional') {
            element.opcionais.forEach((opc) => {
              if (opc.selecionado) {
                opcionais = opc.opcional;
              }
            });
          }
        });
      }

      const event: any = {
        event: 'add_to_cart',
        ecommerce: {
          currency: 'BRL',
          value: produto.preco.total.valor,
          origin: this.utils.getStorage('canal-compra') == "whatsapp" ? 'CHATBOT' : 'PWA',
          items: [
            {
              item_id: String(produto.id),
              item_name: produto.nome,
              currency: 'BRL',
              item_brand: this.marca.nome,
              item_category: produto.categoria,
              item_category2: false,
              item_list_id: String(produto.categoriaId),
              item_list_name: produto.categoria,
              item_variant:
                obrigatorio == ''
                  ? opcionais
                  : opcionais == ''
                    ? obrigatorio
                    : obrigatorio != '' && opcionais != ''
                      ? obrigatorio + ' | ' + opcionais
                      : '',
              price:
                produto.preco.unitario.valor == ''
                  ? preco
                  : produto.preco.unitario.valor,
              quantity: produto.qtd,
            },
          ],
        },
      };

      this.adicionais_selecionado.forEach((item) => {
        event.ecommerce.items.push({
          item_id: String(item.cod),
          item_name: item.nome,
          currency: 'BRL',
          item_brand: this.marca.nome,
          item_category: this.dados_produto.categoria,
          item_list_id: String(this.dados_produto.categoriaId),
          price: item.preco.unitario.valor,
          quantity: item.qtd && produto.qtd > 1 ? produto.qtd : item.qtd,
          item_category2: item.itemCategory2,
          item_category3: this.dados_produto.id,
        });
      });

      dataLayer.push({ ecommerce: null });
      dataLayer.push(event);
    }

    if (this.isBrowser == true) {
      this.modalController.dismiss();
      // this.modalService.modalBrowserConfirmaModalidade();
    } else {
      this.modalController.dismiss();
    }
  }

  filtroAdicionais(adicionais: Array<any>) {
    return adicionais.filter((item: any) => item.qtd > 0);
  }

  filtroObrigatorios(opcionais: Array<any>) {
    return opcionais.filter((item: any) => item.obrigatorio);
  }

  irPara() {
    this.modalController.dismiss();
    this.utils.navegarPaginaRoot('login');
  }

  async selecionarObservacao(item, indexOpc, indexObs, tipo) {
    let regra = this.produto.observacoes[indexObs].regra;
    let itensObservacoes;

    switch (tipo) {
      case 'obrigatorio':
        itensObservacoes = this.produto.observacoes[indexObs].obrigatorios;
        break;

      case 'opcional':
        itensObservacoes = this.produto.observacoes[indexObs].opcionais;
        break;
      default:
        break;
    }

    let qtdSelecionado = 0;
    itensObservacoes.forEach((element) => {
      if (element.selecionado) {
        qtdSelecionado++;
      }
    });

    setTimeout(() => {
      if (qtdSelecionado > regra && regra > 1) {
        item.selecionado = false;
        this.utils.exibirToast(
          'A quantidade máxima para essa opção já foi atingida'
        );
      } else {
        if (this.produto.observacoes[indexObs].tipo_preco == 'preco') {
          let totalAdicionais = 0;

          this.produto.observacoes.forEach((element) => {
            element.adicionais.forEach((item: any) => {
              totalAdicionais += item.qtd * item.preco.valor;
            });
          });

          if (tipo == 'obrigatorio' && regra == 1 && qtdSelecionado >= regra) {
            this.produto.preco.valor -=
              this.produto.observacoes[indexObs].preco_escolha *
              this.produto.qtd;
            this.produto.preco.valor_obrigatorio =
              this.produto.preco.valor - this.produto.qtd * totalAdicionais;
            this.produto.preco.texto = this.utils.floatToReal(
              this.produto.preco.valor
            );

            itensObservacoes.forEach((element, i) => {
              if (i != indexOpc) {
                if (element.selecionado) {
                  element.selecionado = false;
                }
              }

              if (i == indexOpc) {
                if (!element.selecionado) {
                  element.selecionado = false;
                } else {
                  this.produto.preco.valor +=
                    item.preco.valor * this.produto.qtd;
                  this.produto.preco.valor_obrigatorio =
                    this.produto.preco.valor -
                    this.produto.qtd * totalAdicionais;
                  this.produto.preco.texto = this.utils.floatToReal(
                    this.produto.preco.valor
                  );

                  this.produto.observacoes[indexObs].preco_escolha =
                    item.preco.valor;

                  element.selecionado = true;
                }
              }
            });
          } else if (item.selecionado) {
            this.produto.preco.valor += item.preco.valor * this.produto.qtd;
            this.produto.preco.valor_obrigatorio =
              this.produto.preco.valor - this.produto.qtd * totalAdicionais;
            this.produto.preco.texto = this.utils.floatToReal(
              this.produto.preco.valor
            );
            this.produto.observacoes[indexObs].preco_escolha = item.preco.valor;
          } else {
            this.produto.preco.valor -= item.preco.valor * this.produto.qtd;
            this.produto.preco.valor_obrigatorio =
              this.produto.preco.valor - this.produto.qtd * totalAdicionais;

            this.produto.preco.texto = this.utils.floatToReal(
              this.produto.preco.valor
            );
            this.produto.observacoes[indexObs].preco_escolha - item.preco.valor;
          }
        }
      }
    }, 10);
  }

  informaEndereco() {
    this.modalController.dismiss();
    this.utils.navegarPaginaRoot('endereco');
    this.utils.exibirToast('Informe um endereço para continuar');
  }
}
