import { ApiService } from '../../services/api.service';
import { Component, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { Endereco } from 'src/app/interfaces/endereco';
import { EventsService } from 'src/app/services/events.service';
import { MapsService } from 'src/app/services/maps.service';
import { UtilsService } from 'src/app/services/utils.service';
import { ModalsService } from 'src/app/services/modals/modals.service';
@Component({
  selector: 'app-meus-enderecos-pedido',
  templateUrl: './meus-enderecos-pedido.page.html',
  styleUrls: ['./meus-enderecos-pedido.page.scss'],
})
export class MeusEnderecosPedidoPage implements OnInit {

  public cor: string;
  public enderecos: Array<Endereco>;
  public isEndereco: boolean = false;
  public infoEndereco: Endereco;
  public loja: any;

  constructor(
    private utils: UtilsService,
    private modalController: ModalController,
    private maps: MapsService,
    private platform: Platform,
    private events: EventsService,
    private api: ApiService,
    private modals: ModalsService
  ) {

    var root = document.documentElement;
    this.cor = this.utils.getStorage('cor');
    root.style.setProperty('--cor', this.cor);

    this.enderecos = this.utils.getStorage('enderecos');
    this.loja = this.utils.getStorage('loja_carrinho');

  }

  async ngOnInit() {

    await this.platform.ready();

    if (this.utils.getStorage('pular') == null) {

      try {

        const localizacao = await this.maps.getLocalizacao();

        const req = {
          lat: localizacao.latLng.lat,
          lng: localizacao.latLng.lng
        };

        const res = await this.api.get('geocode', req);

        if (res.status === 'success') {
          this.isEndereco = true;
          this.infoEndereco = res.data;
        } else {
          this.isEndereco = false;
        }

        this.isEndereco = true;
      } catch (error) {
        this.isEndereco = false;
      }

    }

  }

  abrirCadastrarEndereco() {
    this.modalController.dismiss();
    this.utils.setStorage('novo-endereco', true);
    this.modals.modalAdicionarEndereco();
  }

  async selecionarLocalizacao() {

    this.utils.setStorage('endereco', this.infoEndereco);
    await this.modals.fecharModal();
  }

  async selecionarEndereco(i: number) {

    let endereco = this.enderecos[i];

    this.utils.setStorage('endereco-carrinho-index', i);

    if (!await this.verificarEndereco(endereco)) {
      this.trocarRestaurante();
      return;
    }

    endereco.principal = true;

    this.enderecos.splice(i, 1);
    this.enderecos.forEach(endereco => {
      endereco.principal = false;
    });

    this.enderecos.unshift(endereco);

    this.utils.setStorage('enderecos', this.enderecos);
    this.events.publish('atualizar-lista-endereco', this.enderecos);
    await this.modals.fecharModal();

  }

  async verificarEndereco(endereco: Endereco) {

    const req = {
      lojaId: this.loja.id,
      endereco: endereco.logradouro,
      numero: endereco.numero,
      bairro: endereco.bairro,
      cidade: endereco.cidade,
      estado: endereco.estado,
      lat: endereco.lat,
      lng: endereco.lng
    };

    await this.utils.exibirLoading();

    const res = await this.api.get('endereco', req);

    if (res.status === 'success') {
      return true;
    } else {
      return false;
    }

  }

  async trocarRestaurante() {
    await this.modals.fecharModal();
    this.modals.modalTrocarRestaurante();
  }

  fechar() {
    this.modalController.dismiss()
  }

}
