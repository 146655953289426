import { UtilsService } from './../../services/utils.service';
import { ApiService } from './../../services/api.service';
import { ModalController } from '@ionic/angular';
import { Component, ElementRef, OnInit } from '@angular/core';
import { ModalsService } from 'src/app/services/modals/modals.service';
import { Endereco } from '../../interfaces/endereco';

declare let dataLayer: any;

@Component({
  selector: 'app-search-produto-browser',
  templateUrl: './search-produto-browser.page.html',
  styleUrls: ['./search-produto-browser.page.scss'],
})
export class SearchProdutoBrowserPage implements OnInit {
  public localizacao = this.utils.getStorage('endereco');
  public modalidade = this.utils.getStorage('modalidade');
  public cardapios = [];
  public modalidadeAtiva: string = 'DLV';
  public idLoja;
  public rede_logo;
  public loja;
  public no_results = false;
  public error_msg: any;
  public brand: any;
  public observacoes: any;
  public loading = true;

  constructor(
    private modalController: ModalController,
    private http: ApiService,
    private utils: UtilsService,
    private modalService: ModalsService,
    private el: ElementRef
  ) {
    if (this.utils.getStorage('modalidade') != null) {
      this.modalidadeAtiva = this.utils.getStorage('modalidade').codigo;
    }
  }

  async ngOnInit() {
    this.brand = this.utils.getStorage('toda_loja')
    this.loja = this.utils.getStorage('loja');

    this.getProdutos();
  }

  ionViewWillEnter() { }

  async getProdutos() {
    this.loading = true;
    const loja = this.utils.getStorage('loja')
    const res = await this.http.get('cardapio', {
      lat: (this.modalidade.codigo == 'TTA') ? loja.latitude : ((this.localizacao != null) ? this.localizacao.lat : ''),
      lng: (this.modalidade.codigo == 'TTA') ? loja.longitude : ((this.localizacao != null) ? this.localizacao.lng : ''),
      loja_id: (this.modalidade.codigo == 'TTA') ? loja.loja_id : '',
      modalidadeId: this.modalidade.codigo,
      marcaId: 2,
    });

    if (res.status == 'success') {
      this.loading = false;
      this.cardapios = res.data.cardapio;
    } else {
      this.loading = false;
      this.no_results = true;
      this.error_msg = res.message;
    }

    this.cardapios.forEach((item) => {
      let event = {
        event: 'view_item_list',
        ecommerce: {
          origin: this.utils.getStorage('canal-compra') == "whatsapp" ? 'CHATBOT' : 'PWA',
          items: this.itemsEvent(item),
        },
      };

      dataLayer.push({ ecommerce: null });
      dataLayer.push(event);
    });
  }

  async buscarProdutos(keyword) {
    if (keyword == '') {
      this.cardapios = [];
      this.getProdutos();
      return;
    }

    let event = {
      event: 'search',
      search_term: keyword,
    };

    dataLayer.push({ ecommerce: null });
    dataLayer.push(event);

    const loja = this.utils.getStorage('loja')

    const res = await this.http.post('buscar_produtos', {
      keyword,
      lat: (this.modalidade.codigo == 'TTA') ? loja.latitude : ((this.localizacao != null) ? this.localizacao.lat : ''),
      lng: (this.modalidade.codigo == 'TTA') ? loja.longitude : ((this.localizacao != null) ? this.localizacao.lng : ''),
      loja_id: (this.modalidade.codigo == 'TTA') ? loja.loja_id : '',
      modalidade: this.modalidade,
      marcaId: 2,
    });

    if (res.status == 'success') {
      this.cardapios = [];
      this.cardapios = res.data.cardapio;
      // res.data.cardapios.forEach(cardapio => {
      //     cardapio.produtos.forEach(produto => {
      //       if(produto.result == 'empty'){
      //         cardapio.not_found = true;
      //       }
      //     });
      //     this.cardapios.push(cardapio)
      // });

      let event = {
        event: 'view_search_results',
        search_term: keyword,
      };

      dataLayer.push({ ecommerce: null });
      dataLayer.push(event);
    } else {
      this.no_results = true;
      this.error_msg = res.message;
    }
  }

  async abrirProduto(produto, marca_id = '') {
    this.utils.setStorage('modalidade', { codigo: this.modalidadeAtiva });
    this.utils.setStorage('produto', produto);
    await this.utils.ocultarLoading();
    this.utils.setStorage('sugestoes-loja', []);
    this.modalController.dismiss();
    this.modalService.modalProdutoBrowser(true, produto, true);

    let event = {
      event: 'select_item',
      ecommerce: {
        origin: this.utils.getStorage('canal-compra') == "whatsapp" ? 'CHATBOT' : 'PWA',
        items: [
          {
            item_id: String(produto.id),
            item_list_id: String(produto.categoriaId),
            item_list_name: "Pesquisa",
            item_name: produto.produto,
            currency: 'BRL',
            item_brand: this.brand.nome,
            item_category: produto.categoria,
            price: (produto.preco_obs == 1) ? produto.preco_partir.valor : produto.preco.valor,
            quantity: 1
          },
        ],
      },
    };

    dataLayer.push({ ecommerce: null });
    dataLayer.push(event);
  }

  fechar() {
    this.modalController.dismiss();
  }


  itemsEvent(produtos) {
    let items = [];

    produtos.cardapio.forEach((item) => {

      items.push({
        item_id: String(item.id),
        item_list_id: String(item.id),
        item_list_name: "Pesquisa",
        item_name: item.produto,
        currency: 'BRL',
        price: (item.preco.valor === 0) ? item.preco_partir.valor : item.preco.valor,
        item_category: produtos.categoria,
        quantity: 1,
        // item_variant: this.observacoes
      });
    });

    return items;
  }
}
