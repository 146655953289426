import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UtilsService } from 'src/app/services/utils.service';

declare let dataLayer: any;
@Component({
  selector: 'app-confirma-pedido',
  templateUrl: './confirma-pedido.page.html',
  styleUrls: ['./confirma-pedido.page.scss'],
})
export class ConfirmaPedidoPage implements OnInit {
  public pedido: any;

  constructor(
    private utils: UtilsService,
    private modalController: ModalController,
  ) { }

  ngOnInit() {
    dataLayer.push({ ecommerce: null });
    dataLayer.push({ event: 'modal_viewed_confirmation' });
  }

  fechar() {
    this.modalController.dismiss();

    dataLayer.push({ ecommerce: null });
    dataLayer.push({
      'event': 'order_decision_confirmation',
      'decision': 'Não'
    });
  }

  verPedidos() {
    this.modalController.dismiss();
    this.utils.navegarPaginaRoot('/tabs/historico');
  }

  confirmarPedido() {
    this.modalController.dismiss({ confirmar: true })
    
    dataLayer.push({ ecommerce: null });
    dataLayer.push({
      'event': 'order_decision_confirmation',
      'decision': 'Sim'
    });
  }

}
