import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { FinalizarPedidoService } from './services/finalizar-pedido.service';
import { AutenticacaoService } from './services/autenticacao.service';

function isAvaliationRoute(url: string): string {
  const isUrl = url.startsWith('/avaliacao');
  if (isUrl) {
    const url_string = url.split('avaliacao/')
    localStorage.setItem('hash', url_string[1]);
    return 'avaliacao';
  } else {
    return 'home';
  }
}

const routes: Routes = [
  {
    path: '',
    redirectTo: 'tabs/home',
    pathMatch: 'full',
  },
  {
    path: 'endereco',
    loadChildren: () =>
      import('./pages/localizacao/endereco/endereco.module').then(
        (m) => m.EnderecoPageModule
      ),
    // canActivate: [AutenticacaoService]
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomePageModule),
  },
  {
    path: 'produto',
    loadChildren: () =>
      import('./pages/produto/produto.module').then((m) => m.ProdutoPageModule),
  },
  {
    path: 'localizacao',
    loadChildren: () =>
      import('./pages/localizacao/localizacao/localizacao.module').then(
        (m) => m.LocalizacaoPageModule
      ),
  },
  // {
  //   path: 'mapa',
  //   loadChildren: () => import('./pages/localizacao/mapa/mapa.module').then(m => m.MapaPageModule)
  // },
  {
    path: 'mapa-confirmacao',
    loadChildren: () =>
      import(
        './pages/localizacao/mapa-confirmacao/mapa-confirmacao.module'
      ).then((m) => m.MapaConfirmacaoPageModule),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/usuario/login/login.module').then(
        (m) => m.LoginPageModule
      ),
    canActivate: [AutenticacaoService],
  },
  {
    path: 'login-telefone',
    loadChildren: () =>
      import('./pages/usuario/login-telefone/login-telefone.module').then(
        (m) => m.LoginTelefonePageModule
      ),
  },
  {
    path: 'cadastro-dados',
    loadChildren: () =>
      import('./pages/usuario/cadastro-dados/cadastro-dados.module').then(
        (m) => m.CadastroDadosPageModule
      ),
  },
  {
    path: 'cadastrar-endereco',
    loadChildren: () =>
      import(
        './pages/usuario/cadastrar-endereco/cadastrar-endereco.module'
      ).then((m) => m.CadastrarEnderecoPageModule),
  },
  {
    path: 'cadastro-permissoes',
    loadChildren: () =>
      import(
        './pages/usuario/cadastro-permissoes/cadastro-permissoes.module'
      ).then((m) => m.CadastroPermissoesModule),
  },
  {
    path: 'cadastro-termos',
    loadChildren: () =>
      import('./pages/usuario/cadastro-termos/cadastro-termos.module').then(
        (m) => m.CadastroTermosPageModule
      ),
  },
  {
    path: 'login-email',
    loadChildren: () =>
      import('./pages/usuario/login-email/login-email.module').then(
        (m) => m.LoginEmailPageModule
      ),
  },
  {
    path: 'esqueci-senha-etapa1',
    loadChildren: () =>
      import(
        './pages/usuario/esqueci-senha/esqueci-senha-etapa1/esqueci-senha-etapa1.module'
      ).then((m) => m.EsqueciSenhaEtapa1PageModule),
  },
  {
    path: 'esqueci-senha-etapa2',
    loadChildren: () =>
      import(
        './pages/usuario/esqueci-senha/esqueci-senha-etapa2/esqueci-senha-etapa2.module'
      ).then((m) => m.EsqueciSenhaEtapa2PageModule),
  },
  {
    path: 'esqueci-senha-etapa3',
    loadChildren: () =>
      import(
        './pages/usuario/esqueci-senha/esqueci-senha-etapa3/esqueci-senha-etapa3.module'
      ).then((m) => m.EsqueciSenhaEtapa3PageModule),
  },
  {
    path: 'esqueci-senha-etapa4',
    loadChildren: () =>
      import(
        './pages/usuario/esqueci-senha/esqueci-senha-etapa4/esqueci-senha-etapa4.module'
      ).then((m) => m.EsqueciSenhaEtapa4PageModule),
  },
  {
    path: 'perfil',
    loadChildren: () =>
      import('./pages/usuario/perfil/perfil.module').then(
        (m) => m.PerfilPageModule
      ),
  },
  {
    path: 'tabs',
    loadChildren: () =>
      import('./pages/tabs/tabs.module').then((m) => m.TabsPageModule),
  },
  {
    path: 'dados-pessoais',
    loadChildren: () =>
      import('./pages/usuario/dados-pessoais/dados-pessoais.module').then(
        (m) => m.DadosPessoaisPageModule
      ),
  },
  {
    path: 'alterar-senha',
    loadChildren: () =>
      import('./modals/alterar-senha/alterar-senha.module').then(
        (m) => m.AlterarSenhaPageModule
      ),
  },
  {
    path: 'lista-enderecos',
    loadChildren: () =>
      import('./modals/lista-enderecos/lista-enderecos.module').then(
        (m) => m.ListaEnderecosPageModule
      ),
  },
  {
    path: 'meus-enderecos',
    loadChildren: () =>
      import('./pages/usuario/meus-enderecos/meus-enderecos.module').then(
        (m) => m.MeusEnderecosPageModule
      ),
  },
  {
    path: 'excluir-endereco',
    loadChildren: () =>
      import('./modals/excluir-endereco/excluir-endereco.module').then(
        (m) => m.ExcluirEnderecoPageModule
      ),
  },
  {
    path: 'inativar-conta',
    loadChildren: () =>
      import('./modals/inativar-conta/inativar-conta.module').then(
        (m) => m.InativarContaPageModule
      ),
  },
  {
    path: 'simples',
    loadChildren: () =>
      import('./modals/simples/simples.module').then(
        (m) => m.SimplesPageModule
      ),
  },
  {
    path: 'excluir-conta',
    loadChildren: () =>
      import('./modals/excluir-conta/excluir-conta.module').then(
        (m) => m.ExcluirContaPageModule
      ),
  },
  {
    path: 'adicionar-endereco',
    loadChildren: () =>
      import('./modals/adicionar-endereco/adicionar-endereco.module').then(
        (m) => m.AdicionarEnderecoPageModule
      ),
  },
  {
    path: 'editar-endereco',
    loadChildren: () =>
      import('./modals/editar-endereco/editar-endereco.module').then(
        (m) => m.EditarEnderecoPageModule
      ),
  },
  {
    path: 'meus-enderecos2',
    loadChildren: () =>
      import('./pages/usuario/meus-enderecos2/meus-enderecos2.module').then(
        (m) => m.MeusEnderecos2PageModule
      ),
  },
  {
    path: 'adicionar-enderecos',
    loadChildren: () =>
      import(
        './pages/usuario/adicionar-enderecos/adicionar-enderecos.module'
      ).then((m) => m.AdicionarEnderecosPageModule),
  },
  {
    path: 'historico',
    loadChildren: () =>
      import('./pages/historico/historico.module').then(
        (m) => m.HistoricoPageModule
      ),
  },
  {
    path: 'detalhes-pedido',
    loadChildren: () =>
      import('./pages/pedidos/detalhes-pedido/detalhes-pedido.module').then(
        (m) => m.DetalhesPedidoPageModule
      ),
  },
  {
    path: 'detalhes-pedido',
    loadChildren: () =>
      import('./modals/detalhes-pedido/detalhes-pedido.module').then(
        (m) => m.DetalhesPedidoPageModule
      ),
  },
  {
    path: 'detalhes-loja',
    loadChildren: () =>
      import('./pages/detalhes-loja/detalhes-loja.module').then(
        (m) => m.DetalhesLojaPageModule
      ),
  },
  {
    path: 'meus-cartoes',
    loadChildren: () =>
      import('./pages/usuario/meus-cartoes/meus-cartoes.module').then(
        (m) => m.MeusCartoesPageModule
      ),
  },
  {
    path: 'adicionar-cartao',
    loadChildren: () =>
      import('./modals/adicionar-cartao/adicionar-cartao.module').then(
        (m) => m.AdicionarCartaoPageModule
      ),
  },
  {
    path: 'notificacoes',
    loadChildren: () =>
      import('./pages/usuario/notificacoes/notificacoes.module').then(
        (m) => m.NotificacoesPageModule
      ),
  },
  {
    path: 'configurar-notificacoes',
    loadChildren: () =>
      import(
        './pages/usuario/configurar-notificacoes/configurar-notificacoes.module'
      ).then((m) => m.ConfigurarNotificacoesPageModule),
  },
  {
    path: 'fale-conosco',
    loadChildren: () =>
      import('./pages/usuario/fale-conosco/fale-conosco.module').then(
        (m) => m.FaleConoscoPageModule
      ),
  },
  {
    path: 'termos-politicas',
    loadChildren: () =>
      import('./pages/usuario/termos-politicas/termos-politicas.module').then(
        (m) => m.TermosPoliticasPageModule
      ),
  },
  {
    path: 'termos-uso',
    loadChildren: () => import('./pages/usuario/termos-uso/termos-uso.module').then(m => m.TermosUsoPageModule)
  },
  {
    path: 'sobre',
    loadChildren: () =>
      import('./pages/usuario/sobre/sobre.module').then(
        (m) => m.SobrePageModule
      ),
  },
  {
    path: 'cupons',
    loadChildren: () =>
      import('./pages/cupons/cupons.module').then((m) => m.CuponsPageModule),
  },
  {
    path: 'cpf-nota',
    loadChildren: () =>
      import('./modals/cpf-nota/cpf-nota.module').then(
        (m) => m.CpfNotaPageModule
      ),
  },
  {
    path: 'meus-enderecos',
    loadChildren: () =>
      import(
        './modals/meus-enderecos-pedido/meus-enderecos-pedido.module'
      ).then((m) => m.MeusEnderecosPedidoPageModule),
  },
  {
    path: 'cartoes-cadastrados',
    loadChildren: () =>
      import('./modals/cartoes-cadastrados/cartoes-cadastrados.module').then(
        (m) => m.CartoesCadastradosPageModule
      ),
  },
  {
    path: 'excluir-cartao',
    loadChildren: () =>
      import('./modals/excluir-cartao/excluir-cartao.module').then(
        (m) => m.ExcluirCartaoPageModule
      ),
  },
  {
    path: 'limpar-sacola',
    loadChildren: () =>
      import('./modals/limpar-sacola/limpar-sacola.module').then(
        (m) => m.LimparSacolaPageModule
      ),
  },
  {
    path: 'produto',
    loadChildren: () =>
      import('./modals/produto/produto.module').then(
        (m) => m.ProdutoPageModule
      ),
  },
  {
    path: 'editar-produto',
    loadChildren: () =>
      import('./modals/editar-produto/editar-produto.module').then(
        (m) => m.EditarProdutoPageModule
      ),
  },
  {
    path: 'limpar-sacola-modalidade',
    loadChildren: () =>
      import(
        './modals/limpar-sacola-modalidade/limpar-sacola-modalidade.module'
      ).then((m) => m.LimparSacolaModalidadePageModule),
  },
  {
    path: 'pagamento-pix',
    loadChildren: () =>
      import('./modals/pagamento-pix/pagamento-pix.module').then(
        (m) => m.PagamentoPixPageModule
      ),
  },
  {
    path: 'cliente-importado',
    loadChildren: () =>
      import('./modals/cliente-importado/cliente-importado.module').then(
        (m) => m.ClienteImportadoPageModule
      ),
  },
  {
    path: 'modal-avaliacao',
    loadChildren: () =>
      import('./modals/avaliacao/avaliacao.module').then(
        (m) => m.AvaliacaoPageModule
      ),
  },
  {
    path: 'confirma-modalidade',
    loadChildren: () =>
      import('./modals/confirma-modalidade/confirma-modalidade.module').then(
        (m) => m.ConfirmaModalidadePageModule
      ),
  },
  {
    path: 'confirmar-edicao-restaurante',
    loadChildren: () =>
      import(
        './modals/confirmar-edicao-restaurante/confirmar-edicao-restaurante.module'
      ).then((m) => m.ConfirmarEdicaoRestaurantePageModule),
  },
  {
    path: 'trocar-restaurante',
    loadChildren: () =>
      import('./modals/trocar-restaurante/trocar-restaurante.module').then(
        (m) => m.TrocarRestaurantePageModule
      ),
  },
  {
    path: 'editar-produto',
    loadChildren: () =>
      import('./modals/editar-produto/editar-produto.module').then(
        (m) => m.EditarProdutoPageModule
      ),
  },
  {
    path: 'mudar-lojas',
    loadChildren: () =>
      import('./modals/mudar-lojas/mudar-lojas.module').then(
        (m) => m.MudarLojasPageModule
      ),
  },
  {
    path: 'duvidas-frequentes',
    loadChildren: () =>
      import(
        './pages/usuario/duvidas-frequentes/duvidas-frequentes.module'
      ).then((m) => m.DuvidasFrequentesPageModule),
  },
  {
    path: 'busca-restaurantes',
    loadChildren: () =>
      import('./modals/busca-restaurantes/busca-restaurantes.module').then(
        (m) => m.BuscaRestaurantesPageModule
      ),
  },
  {
    path: 'novo-chamado',
    loadChildren: () =>
      import('./pages/usuario/novo-chamado/novo-chamado.module').then(
        (m) => m.NovoChamadoPageModule
      ),
  },
  {
    path: 'ver-chamado/:id',
    loadChildren: () =>
      import('./pages/usuario/ver-chamado/ver-chamado.module').then(
        (m) => m.VerChamadoPageModule
      ),
  },
  {
    path: 'sugestoes',
    loadChildren: () =>
      import('./modals/sugestoes/sugestoes.module').then(
        (m) => m.SugestoesPageModule
      ),
  },
  {
    path: 'selecionar-modalidade',
    loadChildren: () =>
      import(
        './modals/selecionar-modalidade/selecionar-modalidade.module'
      ).then((m) => m.SelecionarModalidadePageModule),
  },
  {
    path: 'novo-chamado',
    loadChildren: () =>
      import('./modals/novo-chamado/novo-chamado.module').then(
        (m) => m.NovoChamadoPageModule
      ),
  },
  {
    path: 'inativar-conta-web',
    loadChildren: () =>
      import('./modals/inativar-conta-web/inativar-conta-web.module').then(
        (m) => m.InativarContaWebPageModule
      ),
  },
  {
    path: 'excluir-conta-web',
    loadChildren: () =>
      import('./modals/excluir-conta-web/excluir-conta-web.module').then(
        (m) => m.ExcluirContaWebPageModule
      ),
  },
  {
    path: 'alterar-senha-web',
    loadChildren: () =>
      import('./modals/alterar-senha-web/alterar-senha-web.module').then(
        (m) => m.AlterarSenhaWebPageModule
      ),
  },
  {
    path: 'simples-web',
    loadChildren: () =>
      import('./modals/simples-web/simples-web.module').then(
        (m) => m.SimplesWebPageModule
      ),
  },
  {
    path: 'excluir-cartao-web',
    loadChildren: () =>
      import('./modals/excluir-cartao-web/excluir-cartao-web.module').then(
        (m) => m.ExcluirCartaoWebPageModule
      ),
  },
  {
    path: 'excluir-endereco-web',
    loadChildren: () =>
      import('./modals/excluir-endereco-web/excluir-endereco-web.module').then(
        (m) => m.ExcluirEnderecoWebPageModule
      ),
  },
  {
    path: 'search-produto-browser',
    loadChildren: () =>
      import(
        './modals/search-produto-browser/search-produto-browser.module'
      ).then((m) => m.SearchProdutoBrowserPageModule),
  },
  {
    path: 'modal-produto',
    loadChildren: () =>
      import('./modals/modal-produto/modal-produto.module').then(
        (m) => m.ModalProdutoPageModule
      ),
  },
  {
    path: 'browser-confirma-modalidade',
    loadChildren: () =>
      import(
        './modals/browser-confirma-modalidade/browser-confirma-modalidade.module'
      ).then((m) => m.BrowserConfirmaModalidadePageModule),
  },
  {
    path: 'browser-sugestoes',
    loadChildren: () =>
      import('./modals/browser-sugestoes/browser-sugestoes.module').then(
        (m) => m.BrowserSugestoesPageModule
      ),
  },
  {
    path: 'modal-carrinho-browser',
    loadChildren: () =>
      import(
        './modals/modal-carrinho-browser/modal-carrinho-browser.module'
      ).then((m) => m.ModalCarrinhoBrowserPageModule),
  },
  {
    path: 'onboarding',
    loadChildren: () =>
      import('./pages/onboarding/onboarding.module').then(
        (m) => m.OnboardingPageModule
      ),
  },
  {
    path: 'finalizar-pedido-browser',
    loadChildren: () =>
      import(
        './pages/finalizar-pedido-browser/finalizar-pedido-browser.module'
      ).then((m) => m.FinalizarPedidoBrowserPageModule),
    canActivate: [FinalizarPedidoService],
  },
  {
    path: 'adicionar-cartao-browser',
    loadChildren: () =>
      import(
        './modals/adicionar-cartao-browser/adicionar-cartao-browser.module'
      ).then((m) => m.AdicionarCartaoBrowserPageModule),
  },
  {
    path: 'adicionar-cpf-browser',
    loadChildren: () =>
      import(
        './modals/adicionar-cpf-browser/adicionar-cpf-browser.module'
      ).then((m) => m.AdicionarCpfBrowserPageModule),
  },
  {
    path: 'selecionar-modalidade-browser',
    loadChildren: () =>
      import(
        './modals/selecionar-modalidade-browser/selecionar-modalidade-browser.module'
      ).then((m) => m.SelecionarModalidadeBrowserPageModule),
  },
  {
    path: 'detalhes-loja-browser',
    loadChildren: () =>
      import(
        './modals/detalhes-loja-browser/detalhes-loja-browser.module'
      ).then((m) => m.DetalhesLojaBrowserPageModule),
  },
  {
    path: 'cartoes-cadastrados-browser',
    loadChildren: () =>
      import(
        './modals/cartoes-cadastrados-browser/cartoes-cadastrados-browser.module'
      ).then((m) => m.CartoesCadastradosBrowserPageModule),
  },
  {
    path: 'selecionar-forma-pagamento',
    loadChildren: () =>
      import(
        './modals/selecionar-forma-pagamento/selecionar-forma-pagamento.module'
      ).then((m) => m.SelecionarFormaPagamentoPageModule),
  },
  {
    path: 'carrinho',
    loadChildren: () =>
      import('./pages/carrinho/carrinho.module').then(
        (m) => m.CarrinhoPageModule
      ),
  },
  {
    path: 'finalizar-pedido',
    loadChildren: () =>
      import('./pages/finalizar-pedido/finalizar-pedido.module').then(
        (m) => m.FinalizarPedidoPageModule
      ),
  },
  {
    path: 'fidelidade-extrato',
    loadChildren: () =>
      import(
        './pages/fidelidade/fidelidade-extrato/fidelidade-extrato.module'
      ).then((m) => m.FidelidadeExtratoPageModule),
  },
  {
    path: 'detalhes-fidelidade',
    loadChildren: () =>
      import(
        './pages/fidelidade/detalhes-fidelidade/detalhes-fidelidade.module'
      ).then((m) => m.DetalhesFidelidadePageModule),
  },
  {
    path: 'nivel-bronze',
    loadChildren: () =>
      import('./modals/nivel-bronze/nivel-bronze.module').then(
        (m) => m.NivelBronzePageModule
      ),
  },
  {
    path: 'nivel-prata',
    loadChildren: () =>
      import('./modals/nivel-prata/nivel-prata.module').then(
        (m) => m.NivelPrataPageModule
      ),
  },
  {
    path: 'nivel-ouro',
    loadChildren: () =>
      import('./modals/nivel-ouro/nivel-ouro.module').then(
        (m) => m.NivelOuroPageModule
      ),
  },
  {
    path: 'nivel-china',
    loadChildren: () =>
      import('./modals/nivel-china/nivel-china.module').then(
        (m) => m.NivelChinaPageModule
      ),
  },
  {
    path: 'filtro-fidelidade',
    loadChildren: () =>
      import('./modals/filtro-fidelidade/filtro-fidelidade.module').then(
        (m) => m.FiltroFidelidadePageModule
      ),
  },
  {
    path: 'editar-endereco-browser',
    loadChildren: () =>
      import(
        './modals/editar-endereco-browser/editar-endereco-browser.module'
      ).then((m) => m.EditarEnderecoBrowserPageModule),
  },
  {
    path: 'excluir-endereco-browser',
    loadChildren: () =>
      import(
        './modals/excluir-endereco-browser/excluir-endereco-browser.module'
      ).then((m) => m.ExcluirEnderecoBrowserPageModule),
  },
  {
    path: 'selecionar-forma-pagamento-browser',
    loadChildren: () =>
      import(
        './modals/selecionar-forma-pagamento-browser/selecionar-forma-pagamento-browser.module'
      ).then((m) => m.SelecionarFormaPagamentoBrowserPageModule),
  },
  {
    path: 'filtro-fidelidade-web',
    loadChildren: () =>
      import(
        './modals/filtro-fidelidade-web/filtro-fidelidade-web.module'
      ).then((m) => m.FiltroFidelidadeWebPageModule),
  },
  {
    path: 'confirma-cartao',
    loadChildren: () =>
      import('./modals/confirma-cartao/confirma-cartao.module').then(
        (m) => m.ConfirmaCartaoPageModule
      ),
  },
  {
    path: 'detalhes-loja',
    loadChildren: () =>
      import('./modals/detalhes-loja/detalhes-loja.module').then(
        (m) => m.DetalhesLojaPageModule
      ),
  },
  {
    path: 'termos-fidelidade',
    loadChildren: () =>
      import('./modals/termos-fidelidade/termos-fidelidade.module').then(
        (m) => m.TermosFidelidadePageModule
      ),
  },
  {
    path: 'fidelidade',
    loadChildren: () =>
      import('./pages/fidelidade/fidelidade/fidelidade.module').then(
        (m) => m.FidelidadePageModule
      ),
  },
  {
    path: 'politica-privacidade',
    loadChildren: () =>
      import('./modals/politica-privacidade/politica-privacidade.module').then(
        (m) => m.PoliticaPrivacidadePageModule
      ),
  },

  {
    path: 'missao',
    loadChildren: () =>
      import('./pages/usuario/missao/missao.module').then(
        (m) => m.MissaoPageModule
      ),
  },
  {
    path: 'regulamento-biscoito-premiado',
    loadChildren: () =>
      import(
        './pages/regulamento-biscoito-premiado/regulamento-biscoito-premiado.module'
      ).then((m) => m.RegulamentoBiscoitoPremiadoPageModule),
  },
  {
    path: 'confirma-pedido',
    loadChildren: () =>
      import('./modals/confirma-pedido/confirma-pedido.module').then(
        (m) => m.ConfirmaPedidoPageModule
      ),
  },
  {
    path: 'confirma-pedido-browser',
    loadChildren: () =>
      import(
        './modals/confirma-pedido-browser/confirma-pedido-browser.module'
      ).then((m) => m.ConfirmaPedidoBrowserPageModule),
  },
  {
    path: 'biscoito-sorte',
    loadChildren: () =>
      import('./pages/biscoito-sorte/biscoito-sorte.module').then(
        (m) => m.BiscoitoSortePageModule
      ),
  },
  {
    path: 'retirada-pedido',
    loadChildren: () => import('./modals/retirada-pedido/retirada-pedido.module').then(m => m.RetiradaPedidoPageModule)
  },
  {
    path: 'confirma-retirada-loja',
    loadChildren: () => import('./modals/confirma-retirada-loja/confirma-retirada-loja.module').then(m => m.ConfirmaRetiradaLojaPageModule)
  },
  {
    path: 'retirada-pedido-browser',
    loadChildren: () => import('./modals/retirada-pedido-browser/retirada-pedido-browser.module').then(m => m.RetiradaPedidoBrowserPageModule)
  },
  {
    path: 'confirma-retirada-loja-broswer',
    loadChildren: () => import('./modals/confirma-retirada-loja-broswer/confirma-retirada-loja-broswer.module').then(m => m.ConfirmaRetiradaLojaBroswerPageModule)
  },
  {
    path: 'whatsapp/:uuid',
    loadChildren: () => import('./pages/whatsapp/whatsapp.module').then(m => m.WhatsappPageModule)
  },
  {
    path: 'cadastrar-senha',
    loadChildren: () => import('./modals/cadastrar-senha/cadastrar-senha.module').then(m => m.CadastrarSenhaPageModule)
  },
  {
    path: 'cadastrar-senha-web',
    loadChildren: () => import('./modals/cadastrar-senha-web/cadastrar-senha-web.module').then(m => m.CadastrarSenhaWebPageModule)
  },
  {
    path: 'classicos-do-delivery',
    loadChildren: () => import('./pages/classicos-do-delivery/classicos-do-delivery.module').then(m => m.ClassicosDoDeliveryPageModule)
  },
  {
    path: 'influenciadores',
    loadChildren: () => import('./pages/influenciadores/influenciadores.module').then(m => m.InfluenciadoresPageModule)
  },
  {
    path: 'regras-influenciadores',
    loadChildren: () => import('./modals/influenciadores/regras-influenciadores/regras-influenciadores.module').then(m => m.RegrasInfluenciadoresPageModule)
  },
  {
    path: 'influenciador-cadastrado',
    loadChildren: () => import('./modals/influenciadores/influenciador-cadastrado/influenciador-cadastrado.module').then(m => m.InfluenciadorCadastradoPageModule)
  },
  {
    path: 'pagamento-pedido-pix',
    loadChildren: () => import('./pages/pagamento-pedido-pix/pagamento-pedido-pix.module').then(m => m.PagamentoPedidoPixPageModule)
  },
  {
    path: 'avaliacao',
    loadChildren: () => import('./pages/avaliacao/avaliacao.module').then(m => m.AvaliacaoPageModule)
  },
  {
    path: 'avaliacao/**',
    loadChildren: () =>
      import('./pages/home/home.module').then(
        (m) => m.HomePageModule
      ),
  },
  { path: '**', redirectTo: isAvaliationRoute(window.location.pathname) },
  {
    path: 'modal-pagamento-pedido-pix',
    loadChildren: () => import('./modals/modais-pagamento-pedido-pix/modal-pagamento-pedido-pix/modal-pagamento-pedido-pix.module').then(m => m.ModalPagamentoPedidoPixPageModule)
  },
  {
    path: 'modal-pagamento-pix-falha',
    loadChildren: () => import('./modals/modais-pagamento-pedido-pix/modal-pagamento-pix-falha/modal-pagamento-pix-falha.module').then(m => m.ModalPagamentoPixFalhaPageModule)
  },
  {
    path: 'adicionar-pagamento',
    loadChildren: () => import('./modals/adicionar-pagamento/adicionar-pagamento.module').then(m => m.AdicionarPagamentoPageModule)
  },
  {
    path: 'adicionar-cartao-browser-mp',
    loadChildren: () => import('./modals/adicionar-cartao-browser-mp/adicionar-cartao-browser-mp.module').then(m => m.AdicionarCartaoBrowserMpPageModule)
  },
  {
    path: 'adicionar-cartao-mp',
    loadChildren: () => import('./modals/adicionar-cartao-mp/adicionar-cartao-mp.module').then(m => m.AdicionarCartaoMpPageModule)
  },
  {
    path: 'confirmar-loja-refazer-pedido',
    loadChildren: () => import('./modals/confirmar-loja-refazer-pedido/confirmar-loja-refazer-pedido.module').then(m => m.ConfirmarLojaRefazerPedidoPageModule)
  },
  {
    path: 'confirmar-loja-refazer-pedido-browser',
    loadChildren: () => import('./modals/confirmar-loja-refazer-pedido-browser/confirmar-loja-refazer-pedido-browser.module').then(m => m.ConfirmarLojaRefazerPedidoBrowserPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
