import { UtilsService } from './../../services/utils.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-caixa-voltar-web',
  templateUrl: './caixa-voltar-web.component.html',
  styleUrls: ['./caixa-voltar-web.component.scss'],
})
export class CaixaVoltarWebComponent implements OnInit {

  constructor(
    private utils: UtilsService
  ) { }

  ngOnInit() {}

  voltarPage() {


    if (this.utils.getStorage('pagina_anterior')) {
      if (this.utils.getStorage('pagina_anterior') == 'cardapio') {
        this.utils.voltarPagina('cardapio');
      } else {
        this.utils.voltarPagina('tabs/home');
      }
      this.utils.removeStorage('pagina_anterior');
    } else {
      this.utils.voltarUltima();
    }

  }

}
