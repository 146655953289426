import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { EventsService } from 'src/app/services/events.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-notificacoes-web',
  templateUrl: './notificacoes-web.component.html',
  styleUrls: ['./notificacoes-web.component.scss'],
})
export class NotificacoesWebComponent implements OnInit {

  public notificacoes: Array<any> = [];
  public notificoesGerais: any;
  public notificoesMarketing: any;
  public loading: boolean = false;
  public importado: boolean = false;

  constructor(
    private utils: UtilsService,
    private api: ApiService,
    private events: EventsService
  ) {
    this.notificoesGerais = {
      email: true,
      sms: true
    };

    this.notificoesMarketing = {
      email: true,
      sms: true,
      push: true
    };

    if (this.utils.getStorage('cliente-importado') != null) {
      this.importado = true;
    }
  }

  ngOnInit() {
    this.buscarNotificacoes();
  }

  voltar() {
    this.utils.voltarPagina('/tabs/perfil');
  }

  async buscarNotificacoes() {

    await this.utils.exibirLoading();

    const res = await this.api.get('notificacoes');

    await this.utils.ocultarLoading();

    if (res.status === 'success') {
      this.notificacoes = res.data;
    }

  }

  async configurarNotificacoes() {
    this.utils.navegarPagina('configurar-notificacoes');
  }

  async cadastrar() {

    const dadosPessoais = this.utils.getStorage('dados-pessoais');
    const dadosEndereco = this.utils.getStorage('dados-endereco');
    const dadosCartao = this.utils.getStorage('dados-cartao');

    const req = {
      customerId: this.importado ? this.utils.getStorage('cliente-importado').customerId : '',
      dadosPessoais: dadosPessoais,
      endereco: dadosEndereco,
      cartao: dadosCartao,
      notificacoes: {
        gerais: this.notificoesGerais,
        marketing: this.notificoesMarketing,
      }
    };

    this.loading = true;

    const res = await this.api.post('cliente', req);

    this.loading = false;

    if (res.status === 'success') {

      // this.firebase.criarEvento('APP_SIGN_UP');

      let enderecos = this.utils.getStorage('enderecos');

      if (enderecos != null) {
        res.data.enderecos.unshift(enderecos[0]);
      }

      this.utils.exibirToast(res.message);
      this.utils.removeStorage('cliente-importado');
      this.utils.setStorage('usuario', res.data);
      this.utils.setStorage('endereco', res.data.enderecos[0]);
      this.utils.setStorage('enderecos', res.data.enderecos);
      this.utils.setStorage('endereco_confirmado', true);
      this.utils.navegarPaginaRoot('tabs/home');

    }
  }
}