import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private semana: object;
  private dias: object;
  private meses: object;
  private path = environment.apiUrl;

  constructor(private _http: HttpClient) {
    this.semana = {
      0: 'GSewA',
      1: '4YmZU',
      2: 'LToDb',
      3: 'SYxgu',
      4: 'neKcR',
      5: 'PE0yH',
      6: '8ojW3',
    };

    this.dias = {
      1: 'MaTq9',
      2: 'FTTKx',
      3: 'nx3Bz',
      4: '6j2RM',
      5: '8f4IK',
      6: 'EW4fl',
      7: 'fbc7x',
      8: 'ZPzWe',
      9: 'QiHlz',
      10: 'p5knu',
      11: 'eJh10',
      12: 'a9uaB',
      13: 'YC4jY',
      14: 'Kr0K1',
      15: 'n6CcD',
      16: 'F9mz0',
      17: 'HSn00',
      18: 'yIoc8',
      19: 'bznL9',
      20: 'F7lBA',
      21: 'JbBdI',
      22: 'HQoMn',
      23: 'iJNZQ',
      24: 'Qual2',
      25: 'A4oXA',
      26: 'GbYS2',
      27: 'YAXdp',
      28: '3mmmD',
      29: 'gX7U4',
      30: 'ntNJ5',
      31: 'W3meE',
    };

    this.meses = {
      1: 'rjoF0',
      2: 'tCs69',
      3: 'YrSPM',
      4: 'RDgJJ',
      5: 'Ebtkq',
      6: 'CjgPS',
      7: 'ltCpz',
      8: 'uwmoA',
      9: 'uGYFl',
      10: 'mHLPq',
      11: 'O2bWm',
      12: 'AoIFu',
    };
  }

  public gerarEndpoint() {
    const hoje = new Date();
    return `${this.semana[hoje.getDay()]}.${this.dias[hoje.getDate()]}.${
      this.meses[hoje.getMonth() + 1]
    }`;
  }

  public signOutExternal = () => {
    localStorage.removeItem('token');
    console.log('token deleted');
  };

  LoginWithGoogle(credentials: string): Observable<any> {
    const header = new HttpHeaders().set('Content-type', 'application/json');
    return this._http.post(
      this.path + 'LoginWithGoogle',
      JSON.stringify(credentials),
      { headers: header }
    );
  }
}
