import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-detalhes-pedido',
  templateUrl: './detalhes-pedido.page.html',
  styleUrls: ['./detalhes-pedido.page.scss'],
})
export class DetalhesPedidoPage implements OnInit {
  public pedido: any;

  constructor(
    private utils: UtilsService,
    private modalController: ModalController
  ) {
    this.pedido = this.utils.getStorage('pedido');
  }

  ngOnInit() {}

  voltar() {
    this.utils.voltarPagina('detalhes-pedido');
  }

  fechar() {
    this.modalController.dismiss();
  }
}
