import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MobileService {
  private celularSubject = new BehaviorSubject<boolean>(false);
  celular$ = this.celularSubject.asObservable();

  constructor() {
    this.detectarCelular();
    window.addEventListener('resize', () => this.detectarCelular());
  }

  private detectarCelular() {
    this.celularSubject.next(window.innerWidth < 768);
  }
}
