import { Component, OnInit } from '@angular/core';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import { ApiService } from 'src/app/services/api.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-cupons-web',
  templateUrl: './cupons-web.component.html',
  styleUrls: ['./cupons-web.component.scss'],
})
export class CuponsWebComponent implements OnInit {
  public cupons: Array<any> = [];

  constructor(
    private utils: UtilsService,
    private clipboard: Clipboard,
    private api: ApiService
  ) { }

  ngOnInit() {
    this.buscarCupons();
  }

  async buscarCupons() {

    await this.utils.exibirLoading();

    const res = await this.api.get('cupom');

    await this.utils.ocultarLoading();

    if (res.status === 'success') {
      this.cupons = res.data;
    }

  }

  async copiarCodigo(cupom) {
    await this.clipboard.copy(cupom.codigo)
      .then(() => this.utils.exibirToast('Código copiado com sucesso!'))
      .catch(() => this.utils.exibirToast('Houve um problema ao copiar o texto!', 'erro'));
  }
}
