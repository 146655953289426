import { DetalhesLojaPage } from './../../modals/detalhes-loja/detalhes-loja.page';
import { FiltroFidelidadePage } from './../../modals/filtro-fidelidade/filtro-fidelidade.page';
import { NivelBronzePage } from './../../modals/nivel-bronze/nivel-bronze.page';
import { SelecionarFormaPagamentoPage } from './../../modals/selecionar-forma-pagamento/selecionar-forma-pagamento.page';
import { ModalCarrinhoBrowserPage } from './../../modals/modal-carrinho-browser/modal-carrinho-browser.page';
import { BrowserSugestoesPage } from './../../modals/browser-sugestoes/browser-sugestoes.page';
import { BrowserConfirmaModalidadePage } from './../../modals/browser-confirma-modalidade/browser-confirma-modalidade.page';
import { SearchProdutoBrowserPage } from './../../modals/search-produto-browser/search-produto-browser.page';
import { ModalProdutoPage } from './../../modals/modal-produto/modal-produto.page';
import { SelecionarModalidadePage } from './../../modals/selecionar-modalidade/selecionar-modalidade.page';
import { SugestoesPage } from './../../modals/sugestoes/sugestoes.page';
import { MudarLojasPage } from './../../modals/mudar-lojas/mudar-lojas.page';
import { HostListener, Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AlterarSenhaPage } from 'src/app/modals/alterar-senha/alterar-senha.page';
import { ClienteImportadoPage } from 'src/app/modals/cliente-importado/cliente-importado.page';
import { ConfirmaModalidadePage } from 'src/app/modals/confirma-modalidade/confirma-modalidade.page';
import { CpfNotaPage } from 'src/app/modals/cpf-nota/cpf-nota.page';
import { DetalhesPedidoPage } from 'src/app/modals/detalhes-pedido/detalhes-pedido.page';
import { EditarProdutoPage } from 'src/app/modals/editar-produto/editar-produto.page';
import { InativarContaPage } from 'src/app/modals/inativar-conta/inativar-conta.page';
import { LimparSacolaModalidadePage } from 'src/app/modals/limpar-sacola-modalidade/limpar-sacola-modalidade.page';
import { LimparSacolaPage } from 'src/app/modals/limpar-sacola/limpar-sacola.page';
import { ListaEnderecosPage } from 'src/app/modals/lista-enderecos/lista-enderecos.page';
import { ProdutoPage } from 'src/app/modals/produto/produto.page';
import { SimplesPage } from 'src/app/modals/simples/simples.page';
import { ExcluirEnderecoPage } from 'src/app/modals/excluir-endereco/excluir-endereco.page';
import { MeusEnderecosPedidoPage } from 'src/app/modals/meus-enderecos-pedido/meus-enderecos-pedido.page';
import { ConfirmarEdicaoRestaurantePage } from 'src/app/modals/confirmar-edicao-restaurante/confirmar-edicao-restaurante.page';
import { TrocarRestaurantePage } from 'src/app/modals/trocar-restaurante/trocar-restaurante.page';
import { AdicionarEnderecoPage } from 'src/app/modals/adicionar-endereco/adicionar-endereco.page';
import { ExcluirContaPage } from 'src/app/modals/excluir-conta/excluir-conta.page';
import { EditarEnderecoPage } from 'src/app/modals/editar-endereco/editar-endereco.page';
import { AvaliacaoPage } from 'src/app/modals/avaliacao/avaliacao.page';
import { PagamentoPixPage } from 'src/app/modals/pagamento-pix/pagamento-pix.page';
import { AdicionarCartaoPage } from 'src/app/modals/adicionar-cartao/adicionar-cartao.page';
import { CartoesCadastradosPage } from 'src/app/modals/cartoes-cadastrados/cartoes-cadastrados.page';
import { ExcluirCartaoPage } from 'src/app/modals/excluir-cartao/excluir-cartao.page';
import { BuscaRestaurantesPage } from 'src/app/modals/busca-restaurantes/busca-restaurantes.page';
import { NovoChamadoPage } from 'src/app/modals/novo-chamado/novo-chamado.page';
import { InativarContaWebPage } from 'src/app/modals/inativar-conta-web/inativar-conta-web.page';
import { ExcluirContaWebPage } from 'src/app/modals/excluir-conta-web/excluir-conta-web.page';
import { AlterarSenhaWebPage } from 'src/app/modals/alterar-senha-web/alterar-senha-web.page';
import { SimplesWebPage } from 'src/app/modals/simples-web/simples-web.page';
import { ExcluirCartaoWebPage } from 'src/app/modals/excluir-cartao-web/excluir-cartao-web.page';
import { ExcluirEnderecoWebPage } from 'src/app/modals/excluir-endereco-web/excluir-endereco-web.page';
import { AdicionarCartaoBrowserPage } from 'src/app/modals/adicionar-cartao-browser/adicionar-cartao-browser.page';
import { AdicionarCpfBrowserPage } from 'src/app/modals/adicionar-cpf-browser/adicionar-cpf-browser.page';
import { SelecionarModalidadeBrowserPage } from 'src/app/modals/selecionar-modalidade-browser/selecionar-modalidade-browser.page';
import { DetalhesLojaBrowserPage } from 'src/app/modals/detalhes-loja-browser/detalhes-loja-browser.page';
import { CartoesCadastradosBrowserPage } from 'src/app/modals/cartoes-cadastrados-browser/cartoes-cadastrados-browser.page';
import { NivelPrataPage } from 'src/app/modals/nivel-prata/nivel-prata.page';
import { NivelOuroPage } from 'src/app/modals/nivel-ouro/nivel-ouro.page';
import { NivelChinaPage } from 'src/app/modals/nivel-china/nivel-china.page';
import { ExcluirEnderecoBrowserPage } from 'src/app/modals/excluir-endereco-browser/excluir-endereco-browser.page';
import { EditarEnderecoBrowserPage } from 'src/app/modals/editar-endereco-browser/editar-endereco-browser.page';
import { SelecionarFormaPagamentoBrowserPage } from 'src/app/modals/selecionar-forma-pagamento-browser/selecionar-forma-pagamento-browser.page';
import { FiltroFidelidadeWebPage } from 'src/app/modals/filtro-fidelidade-web/filtro-fidelidade-web.page';
import { ConfirmaCartaoPage } from 'src/app/modals/confirma-cartao/confirma-cartao.page';
import { TermosFidelidadePage } from 'src/app/modals/termos-fidelidade/termos-fidelidade.page';
import { PoliticaPrivacidadePage } from 'src/app/modals/politica-privacidade/politica-privacidade.page';
import { ConfirmaPedidoBrowserPage } from 'src/app/modals/confirma-pedido-browser/confirma-pedido-browser.page';
import { ConfirmaPedidoPage } from 'src/app/modals/confirma-pedido/confirma-pedido.page';
import { RetiradaPedidoPage } from 'src/app/modals/retirada-pedido/retirada-pedido.page';
import { ConfirmaRetiradaLojaPage } from 'src/app/modals/confirma-retirada-loja/confirma-retirada-loja.page';
import { RetiradaPedidoBrowserPage } from 'src/app/modals/retirada-pedido-browser/retirada-pedido-browser.page';
import { ConfirmaRetiradaLojaBroswerPage } from 'src/app/modals/confirma-retirada-loja-broswer/confirma-retirada-loja-broswer.page';
import { CadastrarSenhaPage } from 'src/app/modals/cadastrar-senha/cadastrar-senha.page';
import { CadastrarSenhaWebPage } from 'src/app/modals/cadastrar-senha-web/cadastrar-senha-web.page';
import { AdicionarPagamentoPage } from 'src/app/modals/adicionar-pagamento/adicionar-pagamento.page';
import { AdicionarCartaoBrowserMpPage } from 'src/app/modals/adicionar-cartao-browser-mp/adicionar-cartao-browser-mp.page';
import { AdicionarCartaoMpPage } from 'src/app/modals/adicionar-cartao-mp/adicionar-cartao-mp.page';
import { ConfirmarLojaRefazerPedidoPage } from 'src/app/modals/confirmar-loja-refazer-pedido/confirmar-loja-refazer-pedido.page';
import { ConfirmarLojaRefazerPedidoBrowserPage } from 'src/app/modals/confirmar-loja-refazer-pedido-browser/confirmar-loja-refazer-pedido-browser.page';
@Injectable({
  providedIn: 'root',
})
export class ModalsService {
  private initialBreakpoint: number;

  constructor(private modalController: ModalController) { }

  @HostListener('window:resize')
  onWindowResize(size: number = 768) {
    if (window.innerWidth < size) return true;
    else return false;
  }

  sizeObservable() {
    if (this.onWindowResize(520)) {
      this.initialBreakpoint = 1;
    }
  }

  async modalDetalhesPedido() {
    const modal = await this.modalController.create({
      component: DetalhesPedidoPage,
      cssClass: 'modal-detalhes-pedido',
      swipeToClose: true,
      mode: 'ios',
      canDismiss: true,
      breakpoints: [0, 1],
      initialBreakpoint: 1,
      // showBackdrop: false,
      // presentingElement: await this.modalController.getTop()
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalCPFNota() {
    const modal = await this.modalController.create({
      component: CpfNotaPage,
      cssClass: 'modal-cpf-nota',
      swipeToClose: true,
      mode: 'ios',
      canDismiss: true,
      breakpoints: [0, 1],
      initialBreakpoint: 1,
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalCPFNotaBrowser() {
    const modal = await this.modalController.create({
      component: AdicionarCpfBrowserPage,
      cssClass: 'modal-cpf-nota-browser',
      swipeToClose: true,
      mode: 'ios',
      canDismiss: true,
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalLimparSacola() {
    const modal = await this.modalController.create({
      component: LimparSacolaPage,
      cssClass: 'modal-limpar-sacola',
      swipeToClose: true,
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalSimples({
    titulo = '',
    texto = '',
    pagina = '',
  }: {
    titulo?: string;
    texto?: string;
    pagina?: string;
  }) {
    const modal = await this.modalController.create({
      component: SimplesPage,
      cssClass: 'modal-simples',
      componentProps: {
        titulo: titulo,
        texto: texto,
        pagina: pagina,
      },
      mode: 'ios',
      canDismiss: true,
      swipeToClose: true,
      breakpoints: [0, 1],
      initialBreakpoint: 1,
      // presentingElement: await this.modalController.getTop()
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalLimparSacolaModalidade() {
    const modal = await this.modalController.create({
      component: LimparSacolaModalidadePage,
      cssClass: 'modal-limpar-sacola-modalidade',
      swipeToClose: true,
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalClienteImportado() {
    const modal = await this.modalController.create({
      component: ClienteImportadoPage,
      cssClass: 'modal-cliente-importado',
      swipeToClose: true,
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalEditarProduto() {
    const modal = await this.modalController.create({
      component: EditarProdutoPage,
      cssClass: 'modal-editar-produto',
      swipeToClose: true,
      presentingElement: await this.modalController.getTop(),
      id: 'modal-editar-produto',
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalAlterarSenha() {
    const modal = await this.modalController.create({
      component: AlterarSenhaPage,
      cssClass: 'modal-alterar-senha',
      swipeToClose: true,
    });
    await modal.present();
    return await modal.onDidDismiss();
  }
  async modalCadastrarSenha() {
    const modal = await this.modalController.create({
      component: CadastrarSenhaPage,
      cssClass: 'modal-alterar-senha',
      swipeToClose: false,
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalInativarConta(elemento: HTMLElement) {
    const modal = await this.modalController.create({
      component: InativarContaPage,
      cssClass: 'modal-inativar-conta',
      id: 'inativar-conta',
      mode: 'ios',
      canDismiss: true,
      swipeToClose: true,
      breakpoints: [0, 1],
      initialBreakpoint: 1,
      // presentingElement: elemento
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalMudarLoja(elemento: HTMLElement) {
    const modal = await this.modalController.create({
      component: MudarLojasPage,
      cssClass: 'modal-inativar-conta',
      id: 'mudar-loja',
      mode: 'ios',
      canDismiss: true,
      swipeToClose: true,
      breakpoints: [0, 1],
      initialBreakpoint: 1,
      presentingElement: elemento,
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalExcluirConta(elemento: HTMLElement) {
    const modal = await this.modalController.create({
      component: ExcluirContaPage,
      cssClass: 'modal-excluir-conta',
      id: 'excluir-conta',
      mode: 'ios',
      canDismiss: true,
      swipeToClose: true,
      breakpoints: [0, 1],
      initialBreakpoint: 1,
      // presentingElement: elemento
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalProduto(elemento: HTMLElement = null) {
    const modal = await this.modalController.create({
      component: ProdutoPage,
      cssClass: 'modal-produto',
      swipeToClose: true,
      presentingElement: elemento,
      id: 'modal-produto',
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async fecharModal() {
    await this.modalController.dismiss();
  }

  async modalEnderecos() {
    const modal = await this.modalController.create({
      component: ListaEnderecosPage,
      cssClass: 'modal-enderecos',
      mode: 'ios',
      canDismiss: true,
      swipeToClose: true,
      breakpoints: [0, 1],
      initialBreakpoint: 1,
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalExcluirEndereco(
    elemento: HTMLElement = null,
    endereco = null,
    isLocal = false,
    index = null
  ) {
    const modal = await this.modalController.create({
      component: ExcluirEnderecoPage,
      cssClass: 'modal-excluir-endereco',
      componentProps: {
        endereco,
        isLocal,
        index,
      },
      id: 'excluir-endereco',
      swipeToClose: true,
      breakpoints: [0, 1],
      initialBreakpoint: 1,
      // presentingElement: elemento
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalEditarEndereco(
    elemento: HTMLElement = null,
    index = null,
    isLocal = false
  ) {
    const modal = await this.modalController.create({
      component: EditarEnderecoPage,
      cssClass: 'modal-editar-endereco',
      id: 'editar-endereco',
      swipeToClose: true,
      breakpoints: [0, 1],
      initialBreakpoint: 1,
      // presentingElement: elemento,
      componentProps: {
        index,
        isLocal,
      },
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalExcluirEnderecoBrowser(index = null, isLocal = false) {
    const modal = await this.modalController.create({
      component: ExcluirEnderecoBrowserPage,
      cssClass: 'excluir-endereco-browser',
      id: 'excluir-endereco',
      componentProps: {
        index,
        isLocal,
      },
      canDismiss: true,
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalEditarEnderecoBrowser(index = null, isLocal = false) {
    const modal = await this.modalController.create({
      component: EditarEnderecoBrowserPage,
      cssClass: 'editar-endereco-browser',
      id: 'editar-endereco',
      componentProps: {
        index,
        isLocal,
      },
      canDismiss: true,
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalAdicionarEndereco() {
    const modal = await this.modalController.create({
      component: AdicionarEnderecoPage,
      cssClass: 'modal-adicionar-endereco',
      swipeToClose: true,
      id: 'adicionar-endereco',
      canDismiss: true,
      breakpoints: [0, 1],
      initialBreakpoint: 1,
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalMeusEnderecos() {
    const modal = await this.modalController.create({
      component: MeusEnderecosPedidoPage,
      cssClass: 'modal-meus-enderecos',
      swipeToClose: true,
      canDismiss: true,
      breakpoints: [0, 1],
      initialBreakpoint: 1,
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalAdicionarCartao() {
    const modal = await this.modalController.create({
      component: AdicionarCartaoPage,
      cssClass: 'modal-add-cartao',
      swipeToClose: true,
      mode: 'ios',
      canDismiss: true,
      breakpoints: [0, 1],
      initialBreakpoint: 1,
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalAdicionarCartaoBrowser(dados_cartao = '') {
    const modal = await this.modalController.create({
      component: AdicionarCartaoBrowserPage,
      cssClass: 'modal-add-cartao-browser',
      canDismiss: true,
      componentProps: {
        dados_cartao,
      },
    });
    await modal.present();
    return await modal.onDidDismiss();
  }
  async modalAdicionarCartaoMP(dados_cartao = '') {
    const modal = await this.modalController.create({
      component: AdicionarCartaoMpPage,
      cssClass: 'modal-add-cartao',
      canDismiss: true,
      componentProps: {
        dados_cartao,
      },
      breakpoints: [0, 1],
      initialBreakpoint: 1,
    });
    await modal.present();
    return await modal.onDidDismiss();
  }
  async modalAdicionarCartaoBrowserMP(dados_cartao = '') {
    const modal = await this.modalController.create({
      component: AdicionarCartaoBrowserMpPage,
      cssClass: 'modal-add-cartao-browser',
      canDismiss: true,
      componentProps: {
        dados_cartao,
      },
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalAdicionarPagamento(dados_cartao = '', tipo: string, css) {
    const modal = await this.modalController.create({
      component: AdicionarPagamentoPage,
      cssClass: css,
      canDismiss: true,
      componentProps: {
        dados_cartao,
        tipo,
      },
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalEditarRestaurante() {
    const modal = await this.modalController.create({
      component: ConfirmarEdicaoRestaurantePage,
      cssClass: 'modal-edicao-restaurante',
      swipeToClose: true,
      mode: 'ios',
      canDismiss: true,
      breakpoints: [0, 1],
      initialBreakpoint: 1,
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalCartoesCadastrados() {
    const modal = await this.modalController.create({
      component: CartoesCadastradosPage,
      cssClass: 'modal-cartoes-cadastrados',
      swipeToClose: true,
      breakpoints: [0, 1],
      initialBreakpoint: 1,
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalCartoesCadastradosBrowser() {
    const modal = await this.modalController.create({
      component: CartoesCadastradosBrowserPage,
      cssClass: 'modal-cartoes-cadastrados-browser',
      canDismiss: true,
    });
    await modal.present();
    return await modal.onDidDismiss();
  }
  async modalConfirmaPedido(pedido) {
    const modal = await this.modalController.create({
      component: ConfirmaPedidoPage,
      cssClass: 'modal-confirma-pedido',
      swipeToClose: true,
      breakpoints: [0, 1],
      initialBreakpoint: 1,
      componentProps: { pedido },
    });
    await modal.present();
    return await modal.onDidDismiss();
  }
  async modalConfirmaPedidoBrowser(pedido) {
    const modal = await this.modalController.create({
      component: ConfirmaPedidoBrowserPage,
      cssClass: 'modal-confirma-pedido-browser',
      componentProps: { pedido },
      canDismiss: true,
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalTrocarRestaurante() {
    const modal = await this.modalController.create({
      component: TrocarRestaurantePage,
      cssClass: 'modal-trocar-restaurante',
      swipeToClose: true,
      mode: 'ios',
      canDismiss: true,
      breakpoints: [0, 1],
      initialBreakpoint: 1,
      presentingElement: await this.modalController.getTop(),
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalPagamentoPix() {
    const modal = await this.modalController.create({
      component: PagamentoPixPage,
      cssClass: 'modal-pagamento-pix',
      swipeToClose: true,
    });
    await modal.present();
  }

  async modalAvaliacao() {
    const modal = await this.modalController.create({
      component: AvaliacaoPage,
      cssClass: 'modal-avaliacao',
      swipeToClose: true,
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalExcluirCartao(elemento: HTMLElement) {
    const modal = await this.modalController.create({
      component: ExcluirCartaoPage,
      cssClass: 'modal-excluir-conta',
      id: 'excluir-cartao',
      mode: 'ios',
      canDismiss: true,
      swipeToClose: true,
      breakpoints: [0, 1],
      initialBreakpoint: 1,
      presentingElement: elemento,
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalBuscaRestaurantes() {
    const modal = await this.modalController.create({
      component: BuscaRestaurantesPage,
      cssClass: 'modal-busca-restaurantes',
      id: 'busca-restaurantes',
      mode: 'ios',
      canDismiss: true,
      swipeToClose: true,
      breakpoints: [0, 1],
      initialBreakpoint: 1,
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  avaliarApp() {
    this.modalAvaliacao();
  }

  async modalConfirmaModalidade(carrinho, desconto) {
    const modal = await this.modalController.create({
      component: ConfirmaModalidadePage,
      cssClass: 'modal-confirma-modalidade',
      id: 'modal-confirma-modalidade',
      componentProps: { carrinho, desconto },
      swipeToClose: false,
      canDismiss: true,
      breakpoints: [0, 1],
      initialBreakpoint: 1,
      backdropDismiss: false,
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalSelecionaModalidade() {
    const modal = await this.modalController.create({
      component: SelecionarModalidadePage,
      cssClass: 'modal-seleciona-modalidade',
      id: 'modal-seleciona-modalidade',
      swipeToClose: true,
      canDismiss: true,
      breakpoints: [0, 1],
      initialBreakpoint: 1,
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalSelecionaModalidadeBrowser() {
    const modal = await this.modalController.create({
      component: SelecionarModalidadeBrowserPage,
      cssClass: 'modal-seleciona-modalidade-browser',
      id: 'modal-seleciona-modalidade-browser',
      canDismiss: true,
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalSugestoes() {
    const modal = await this.modalController.create({
      component: SugestoesPage,
      cssClass: 'modal-sugestoes',
      id: 'modal-sugestoes',
      mode: 'ios',
      canDismiss: true,
      swipeToClose: false,
      breakpoints: [0, 1],
      initialBreakpoint: 1,
      backdropDismiss: false,
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalNivelBronze() {
    const modal = await this.modalController.create({
      component: NivelBronzePage,
      cssClass: 'modal-nivel-bronze',
      mode: 'ios',
      canDismiss: true,
      breakpoints: [0, 1],
      initialBreakpoint: 1,
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalNivelPrata() {
    const modal = await this.modalController.create({
      component: NivelPrataPage,
      cssClass: 'modal-nivel-prata',
      mode: 'ios',
      canDismiss: true,
      breakpoints: [0, 1],
      initialBreakpoint: 1,
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalNivelOuro() {
    const modal = await this.modalController.create({
      component: NivelOuroPage,
      cssClass: 'modal-nivel-ouro',
      mode: 'ios',
      canDismiss: true,
      breakpoints: [0, 1],
      initialBreakpoint: 1,
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalNivelChina() {
    const modal = await this.modalController.create({
      component: NivelChinaPage,
      cssClass: 'modal-nivel-china',
      mode: 'ios',
      canDismiss: true,
      breakpoints: [0, 1],
      initialBreakpoint: 1,
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalFiltroFidelidade(meses) {
    const modal = await this.modalController.create({
      component: FiltroFidelidadePage,
      cssClass: 'modal-filtro-fidelidade',
      mode: 'ios',
      componentProps: { meses },
      canDismiss: true,
      breakpoints: [0, 1],
      initialBreakpoint: 1,
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  // TODO MODAIS CENTRALIZADAS PARA SEREM UTILIZADAS NO WEB

  async modalNovoChamado() {
    const modal = await this.modalController.create({
      component: NovoChamadoPage,
      cssClass: 'modal-novo-chamdo',
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalInativarContaWeb() {
    const modal = await this.modalController.create({
      component: InativarContaWebPage,
      cssClass: 'modal-inativar-conta-web',
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalExcluirContaWeb() {
    const modal = await this.modalController.create({
      component: ExcluirContaWebPage,
      cssClass: 'modal-excluir-conta-web',
      id: 'excluir-conta',
      canDismiss: true,
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalAlterarSenhaWeb() {
    const modal = await this.modalController.create({
      component: AlterarSenhaWebPage,
      cssClass: 'modal-pequena-web',
      canDismiss: true,
    });
    await modal.present();
    return await modal.onDidDismiss();
  }
  async modalCadastrarSenhaWeb() {
    const modal = await this.modalController.create({
      component: CadastrarSenhaWebPage,
      cssClass: 'modal-pequena-web',
      canDismiss: true,
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalSimplesWeb({
    titulo = '',
    texto = '',
    pagina = '',
    input = true,
  }: {
    titulo?: string;
    texto?: string;
    pagina?: string;
    input?: Boolean;
  }) {
    const modal = await this.modalController.create({
      component: SimplesWebPage,
      cssClass: 'confirmacao',
      componentProps: {
        titulo: titulo,
        texto: texto,
        pagina: pagina,
        input,
      },
      mode: 'ios',
      canDismiss: true,
      presentingElement: await this.modalController.getTop(),
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalExcluirCartaoWeb() {
    const modal = await this.modalController.create({
      component: ExcluirCartaoWebPage,
      cssClass: 'modal-simples-web',
      id: 'excluir-cartao',
      canDismiss: true,
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalExcluirEnderecoWeb(endereco) {
    const modal = await this.modalController.create({
      component: ExcluirEnderecoWebPage,
      cssClass: 'modal-simples-web',
      componentProps: {
        endereco: endereco,
      },
      id: 'excluir-endereco',
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalSearchProdutos() {
    const modal = await this.modalController.create({
      component: SearchProdutoBrowserPage,
      cssClass: 'modal-search-produtos',
      id: 'search-produtos',
      canDismiss: true,
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalProdutoBrowser(
    isBrowser: any = '',
    produto = '',
    pesquisa: boolean = false
  ) {
    const modal = await this.modalController.create({
      component: ModalProdutoPage,
      componentProps: {
        isBrowser,
        pesquisa: pesquisa,
      },
      cssClass: 'modal-produto',
      id: 'modal-produto',
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalBrowserConfirmaModalidade() {
    const modal = await this.modalController.create({
      component: BrowserConfirmaModalidadePage,
      cssClass: 'browser-confirma-modalidade',
      id: 'browser-confirma-modalidade',
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalBrowserSugestoes() {
    const modal = await this.modalController.create({
      component: BrowserSugestoesPage,
      cssClass: 'browser-sugestoes',
      id: 'browser-sugestoes',
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalCarrinhoBrowser() {
    const modal = await this.modalController.create({
      component: ModalCarrinhoBrowserPage,
      cssClass: 'modal-carrinho-browser',
      id: 'modal-carrinho-browser',
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalDetalhesLojaBrowser(loja) {
    const modal = await this.modalController.create({
      component: DetalhesLojaBrowserPage,
      cssClass: 'detalhes-loja-browser',
      id: 'detalhes-loja-browser',
      canDismiss: true,
      componentProps: {
        loja,
      },
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalFormasPagamento(formas, loja) {
    const modal = await this.modalController.create({
      component: SelecionarFormaPagamentoPage,
      cssClass: 'modal-forma',
      id: 'formas-pagamento',
      swipeToClose: true,
      canDismiss: true,
      breakpoints: [0, 1],
      initialBreakpoint: 1,
      componentProps: { formas: formas, loja: loja },
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalFormasPagamentoBrowser(formas, loja) {
    const modal = await this.modalController.create({
      component: SelecionarFormaPagamentoBrowserPage,
      cssClass: 'modal-forma-browser',
      id: 'formas-pagamento',
      swipeToClose: true,
      canDismiss: true,
      componentProps: { formas: formas, loja: loja },
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalFiltroFidelidadeWeb(mes) {
    const modal = await this.modalController.create({
      component: FiltroFidelidadeWebPage,
      cssClass: 'modal-fidelidade-web',
      mode: 'ios',
      componentProps: { mes },
      canDismiss: true,
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalNivelBronzeWeb() {
    const modal = await this.modalController.create({
      component: NivelBronzePage,
      cssClass: 'modal-nivel-bronze-web',
      mode: 'ios',
      canDismiss: true,
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalNivelPrataWeb() {
    const modal = await this.modalController.create({
      component: NivelPrataPage,
      cssClass: 'modal-nivel-prata-web',
      mode: 'ios',
      canDismiss: true,
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalNivelOuroWeb() {
    const modal = await this.modalController.create({
      component: NivelOuroPage,
      cssClass: 'modal-nivel-ouro-web',
      mode: 'ios',
      canDismiss: true,
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalNivelChinaWeb() {
    const modal = await this.modalController.create({
      component: NivelChinaPage,
      cssClass: 'modal-nivel-china-web',
      mode: 'ios',
      canDismiss: true,
    });
    await modal.present();
    return await modal.onDidDismiss();
  }
  async modalConfirmaCartao(cartao) {
    const modal = await this.modalController.create({
      component: ConfirmaCartaoPage,
      cssClass: 'modal-confirma-cartao',
      id: 'confirma-cartao',
      componentProps: { cartao },
      breakpoints: [0, 1],
      initialBreakpoint: 1,
    });
    await modal.present();
    return await modal.onDidDismiss();
  }
  async modalDetalhesLoja(loja) {
    const modal = await this.modalController.create({
      component: DetalhesLojaPage,
      cssClass: 'modal-detalhes-loja',
      id: 'detalhes-loja',
      swipeToClose: true,
      canDismiss: true,
      breakpoints: [0, 1],
      initialBreakpoint: 1,
      componentProps: { loja },
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalTermos(frame: string) {
    const modal = await this.modalController.create({
      component: TermosFidelidadePage,
      cssClass: 'modal-produto',
      id: 'detalhes-loja',
      swipeToClose: true,
      canDismiss: true,
      breakpoints: [0, 1],
      initialBreakpoint: 1,
      componentProps: {
        data: frame
      },
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalPoliticas() {
    const modal = await this.modalController.create({
      component: PoliticaPrivacidadePage,
      cssClass: 'modal-produto',
      id: 'detalhes-loja',
      swipeToClose: true,
      canDismiss: true,
      breakpoints: [0, 1],
      initialBreakpoint: 1,
      componentProps: {},
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalRetirarPedido(loja) {
    const modal = await this.modalController.create({
      component: RetiradaPedidoPage,
      cssClass: 'modal-retirada-pedido',
      id: 'retirada-pedido',
      canDismiss: true,
      componentProps: { loja: loja },
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalRetirarPedidoBrowser(loja) {
    const modal = await this.modalController.create({
      component: RetiradaPedidoBrowserPage,
      cssClass: 'modal-retirada-pedido-browser',
      id: 'retirada-pedido',
      canDismiss: true,
      componentProps: { loja: loja },
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalRetiradaLoja(localizacaoAtual, loja) {
    const modal = await this.modalController.create({
      component: ConfirmaRetiradaLojaPage,
      cssClass: 'modal-confirma-retirada-loja',
      id: 'confirma-retirada-loja',
      canDismiss: true,
      componentProps: { localizacaoAtual: localizacaoAtual, loja: loja },
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalRetiradaLojaBrowser(localizacaoAtual, loja) {
    const modal = await this.modalController.create({
      component: ConfirmaRetiradaLojaBroswerPage,
      cssClass: 'modal-confirma-retirada-loja-browser',
      id: 'confirma-retirada-loja',
      canDismiss: true,
      componentProps: { localizacaoAtual: localizacaoAtual, loja: loja },
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalConfirmarLojaRefazerPedido(pedido) {
    const modal = await this.modalController.create({
      component: ConfirmarLojaRefazerPedidoPage,
      cssClass: 'modal-confirmar-loja-refazer-pedido',
      swipeToClose: true,
      breakpoints: [0, 1],
      initialBreakpoint: 1,
      componentProps: { pedido },
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalConfirmarLojaRefazerPedidoBrowser(pedido) {
    const modal = await this.modalController.create({
      component: ConfirmarLojaRefazerPedidoBrowserPage,
      cssClass: 'modal-confirmar-loja-refazer-pedido-browser',
      swipeToClose: true,
      breakpoints: [0, 1],
      initialBreakpoint: 1,
      componentProps: { pedido },
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async abrirModal(
    id: string,
    cssClass: string,
    component: Function,
    data: any = {},
    callback: any = ''
  ) {
    this.sizeObservable();

    const modal = await this.modalController.create({
      id: id,
      cssClass: cssClass,
      component: component,
      canDismiss: true,
      breakpoints: [0, 1],
      backdropDismiss: false,
      initialBreakpoint: this.initialBreakpoint,
      componentProps: {
        data: data,
      },
    });

    await modal.present();
    return await modal.onDidDismiss().then(callback);
  }
}
