import { ApiService } from 'src/app/services/api.service';
import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UtilsService } from 'src/app/services/utils.service';
import { Endereco } from 'src/app/interfaces/endereco';
import { ModalsService } from 'src/app/services/modals/modals.service';
import { EventsService } from 'src/app/services/events.service';
@Component({
  selector: 'app-excluir-endereco',
  templateUrl: './excluir-endereco.page.html',
  styleUrls: ['./excluir-endereco.page.scss'],
})
export class ExcluirEnderecoPage implements OnInit {
  public loading: boolean = false;
  public enderecos: any;
  public endereco: any;
  public isLocal: any;
  public index: any;

  constructor(
    private utils: UtilsService,
    private api: ApiService,
    private modalController: ModalController,
    private modals: ModalsService,
    private events: EventsService
  ) {}

  ngOnInit() {
    if (typeof this.endereco != 'number') {
      this.enderecos = this.endereco;
    } else {
      this.enderecos = this.utils.getStorage('enderecos')[this.endereco];
    }
  }

  async continuar() {
    const req = {
      enderecoId: this.enderecos.id,
    };

    this.loading = true;

    const res = await this.api.delete('endereco', req);

    this.loading = false;

    if (res.status === 'success') {

      if (this.utils.getStorage('usuario') != null) {
        let endereco_pedido = await this.api.get('endereco_pedido');

        let enderecos = endereco_pedido.data;
        enderecos.forEach((endereco) => {
          endereco.principal = false;
        });
  
        enderecos[0].principal = true;
  
        this.utils.removeStorage('enderecos');
        this.utils.setStorage('enderecos', enderecos);
        this.events.publish('atualizar-lista-endereco');
        this.events.publish('atualizar-endereco', enderecos);
        this.utils.exibirToast(res.message);
        this.modalController.dismiss();
      }else{

        let enderecos = this.utils.getStorage('enderecos');

        enderecos.splice(this.index, 1)

        //caso o endereço excluído tenha sido o principal
        enderecos[0].principal = true

        this.events.publish('atualizar-endereco', {exclusao: true, enderecos: enderecos});
        this.utils.removeStorage('enderecos');
        this.utils.setStorage('enderecos', enderecos);
        this.modalController.dismiss();

      }
   
    }
  }

  cancelar() {
    this.modalController.dismiss();
  }
}
