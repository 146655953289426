import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';
import { EventsService } from 'src/app/services/events.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-excluir-endereco-browser',
  templateUrl: './excluir-endereco-browser.page.html',
  styleUrls: ['./excluir-endereco-browser.page.scss'],
})
export class ExcluirEnderecoBrowserPage implements OnInit {

  public loading: boolean = false;
  public index: any;
  public enderecos: any;

  constructor(
    private utils: UtilsService,
    private api: ApiService,
    private modalController: ModalController,
    private events: EventsService,
  ) {
  }

  ngOnInit() {
    this.enderecos = this.utils.getStorage('enderecos');
  }

  async continuar() {

    let enderecos = this.utils.getStorage('enderecos');
    if (enderecos.length == 1) {
      this.utils.exibirToast('É preciso de no mínimo 1 endereço cadastrado!')
      return
    }
    else {

      const req = {
        enderecoId: enderecos[this.index].id
      };

      const res = await this.api.delete('endereco', req);

      enderecos.splice(this.index, 1)

      //caso o endereço excluído tenha sido o principal
      enderecos[0].principal = true

      this.loading = false;

      if (res.status === 'success') {

        this.events.publish('atualizar-endereco', {exclusao: true, enderecos: enderecos});
        this.utils.removeStorage('enderecos');
        this.utils.setStorage('enderecos', enderecos);
        this.modalController.dismiss();
      }

      this.loading = true;
    }
  }

  cancelar() {
    this.modalController.dismiss()
  }

}
