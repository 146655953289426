import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';
import { ModalsService } from 'src/app/services/modals/modals.service';
import { UtilsService } from 'src/app/services/utils.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EventsService } from 'src/app/services/events.service';

@Component({
  selector: 'app-editar-endereco-browser',
  templateUrl: './editar-endereco-browser.page.html',
  styleUrls: ['./editar-endereco-browser.page.scss'],
})
export class EditarEnderecoBrowserPage implements OnInit {
  public formulario: FormGroup;
  public endereco;
  public enderecos;
  public index;
  public controladores: Object;
  public loading: boolean = false;

  constructor(
    private utils: UtilsService,
    private modals: ModalsService,
    private modalController: ModalController,
    private api: ApiService,
    private events: EventsService,
    private formBuilder: FormBuilder,

  ) {
    this.controladores = {
      apelido: 'O apelido',
      cep: 'O CEP',
      endereco: 'O logradouro',
      numero: 'O número',
      bairro: 'O bairro',
      cidade: 'A cidade',
      estado: 'O estado',
      tipo: 'O tipo de endereço',
    };
  }

  ngOnInit() {
    this.endereco = this.utils.getStorage('enderecos')[this.index];

    this.formulario = this.formBuilder.group({
      apelido: [this.endereco?.apelido, ''],
      cep: [this.endereco?.cep, Validators.required],
      logradouro: [this.endereco?.logradouro, Validators.required],
      numero: [this.endereco?.numero, Validators.required],
      complemento: [this.endereco?.complemento],
      bairro: [this.endereco?.bairro, Validators.required],
      cidade: [this.endereco?.cidade, Validators.required],
      estado: [this.endereco?.estado, Validators.required],
      pontoReferencia: [this.endereco?.pontoReferencia],
      tipo: [this.endereco?.tipo, Validators.required],
    });
  }

  async buscarCep() {
    let cep: string = this.formulario.get('cep').value;

    if (cep.length === 9) {
      const req = {
        cep: cep,
      };

      const res = await this.api.get('buscar_cep', req);

      if (res.status === 'success') {
        this.formulario.patchValue({
          logradouro: (res.data.logradouro == '') ? this.endereco.logradouro : res.data.logradouro,
          bairro: (res.data.bairro == '') ? this.endereco.bairro : res.data.bairro,
          cidade: res.data.cidade,
          estado: res.data.estado,
        });
      } else {
        this.utils.exibirToast(res.message, 'erro');
      }
    }
  }

  fechar() {
    this.modalController.dismiss();
  }

  async salvar() {
    if (this.utils.validaForm(this.formulario, this.controladores)) {
      if (!this.utils.validaCEP(this.formulario.get('cep').value)) {
        this.utils.exibirToast('CEP inválido!', 'erro');
        return;
      }

      this.loading = true;

      this.enderecos = this.utils.getStorage('enderecos');
      const req = {
        id: this.endereco.id,
        apelido: this.formulario.get('apelido').value,
        cep: this.formulario.get('cep').value,
        logradouro: this.formulario.get('logradouro').value,
        numero: this.formulario.get('numero').value,
        complemento: this.formulario.get('complemento').value,
        bairro: this.formulario.get('bairro').value,
        cidade: this.formulario.get('cidade').value,
        estado: this.formulario.get('estado').value,
        pontoReferencia: this.formulario.get('pontoReferencia').value,
        tipo: this.formulario.get('tipo').value,
        principal: this.endereco.principal,
        lat: this.endereco.lat,
        lng: this.endereco.lng,
      };

      this.loading = true;

      const res = await this.api.put('endereco_pedido', req);

      this.loading = false;

      if (res.status === 'success') {

        this.enderecos[this.index] = req;
        this.utils.removeStorage('enderecos');
        this.utils.setStorage('enderecos', this.enderecos);
        this.events.publish('atualizar-endereco', { enderecos: this.enderecos });
      }

      this.loading = false;
      this.modalController.dismiss();
    }
  }
}
