import { Keyboard } from '@ionic-native/keyboard/ngx';
import { ModalController, Platform } from '@ionic/angular';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Endereco } from 'src/app/interfaces/endereco';
import { UtilsService } from 'src/app/services/utils.service';
import { ApiService } from 'src/app/services/api.service';
import { ModalsService } from 'src/app/services/modals/modals.service';

@Component({
  selector: 'app-novo-chamado',
  templateUrl: './novo-chamado.page.html',
  styleUrls: ['./novo-chamado.page.scss'],
})
export class NovoChamadoPage implements OnInit {

  public loading: boolean = false;
  public botao: boolean = true;

  public formulario: FormGroup;
  public controladores: Object;

  public endereco: Endereco;
  public rede: any;


  constructor(
    private formBuilder: FormBuilder,
    private utils: UtilsService,
    private api: ApiService,
    private keyboard: Keyboard,
    private platform: Platform,
    private modalService: ModalsService,
    private modalController: ModalController
  ) { }

  ngOnInit() {
    this.formulario = this.formBuilder.group({
      nome: [this.utils.getStorage('usuario')['nome'], Validators.required],
      email: [this.utils.getStorage('usuario')['email'], Validators.required],
      assunto: ['', Validators.required],
      pedido: [''],
      marca: [''],
      mensagem: ['',Validators.required],
    });

    this.controladores = {
      nome: 'O nome',
      email: 'O e-mail',
      assunto: 'O assunto',
      mensagem: 'A mensagem ',
    };

        // this.platform.ready().then(() => {
    //   this.keyboard.onKeyboardDidShow().subscribe(() => this.botao = false);
    //   this.keyboard.onKeyboardDidHide().subscribe(() => this.botao = true);
    // });

    this.buscarRedes();
  }

  ionViewWillEnter() {
    this.buscarRedes();
  }

  fechar() {
    this.modalController.dismiss();
  }

  async buscarRedes() {
    this.rede = [];


    const res = await this.api.get('marca');
    if (res.status === 'success') {
      setTimeout(() => {
        if (res.data[0].id == '2') {
          this.rede = res.data[0];
        }
        this.loading = false;
      }, 500);
    } else if (res.responseCode == 404) {
      this.loading = false;
    }
  }

  async enviarChamado(){

    if(this.utils.validaForm(this.formulario, this.controladores)) {

      const req = {
        nome: this.formulario.get('nome').value,
        email: this.formulario.get('email').value,
        assunto: this.formulario.get('assunto').value,
        pedidoId: this.formulario.get('pedido').value,
        marcaId: this.formulario.get('marca').value,
        mensagem: this.formulario.get('mensagem').value,
      };
      this.loading = true;

      const res = await this.api.post('fale_conosco', req);

      this.loading = false;
      if (res.status === 'success') {
        this.fechar();
      }
    }

  }

}
