import { UtilsService } from 'src/app/services/utils.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { EventsService } from 'src/app/services/events.service';
import { ModalsService } from 'src/app/services/modals/modals.service';
import { Endereco } from 'src/app/interfaces/endereco';
import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';
import {
  AlertController,
  IonSlides,
  ModalController,
  Platform,
} from '@ionic/angular';
import { MapsService } from 'src/app/services/maps.service';

@Component({
  selector: 'app-topo',
  templateUrl: './topo.component.html',
  styleUrls: ['./topo.component.scss'],
})
export class TopoComponent implements OnInit {
  @Input() voltar: boolean = false;
  @Input() busca: boolean = false;
  @Input() localizacao: boolean = true;
  @Input() apresenta_logo: boolean = true;
  @Input() fidelidade: boolean = false;
  @Input() logo_marca: string = null;
  @Input() logo: boolean = false;
  @Input() mostra_carrinho: boolean = true;
  @Input() marca: any;
  @Input() tempoEntrega: string = null;
  @Input() horario: string = null;
  @Input() nome: string = null;

  @Input() mapa: string = null;

  @Input() statusLoja: boolean = false;
  @Output() volta = new EventEmitter<any>();
  @Output() openCarrinho = new EventEmitter<any>();
  @ViewChild('slides') slides: IonSlides;
  ////mapa
  @ViewChild('idMapa') HoldPosition: ElementRef;

  public option = {
    loop: false,
    effect: 'slide',

    freeMode: true,
    freeModeSticky: false,
    initialSlide: 0,
    slidesPerView: 6,
    spaceBetween: 25,
  };

  public elementPosition;
  public carrinho;
  public loja: any = {};
  public loja_dados: any;
  public modalidade: any;
  public modalidadeAtiva: any;
  public tipoCompra: string;
  public cor: string;
  public cardapio: Array<any> = [];
  public cardapioCompleto: Array<any> = [];
  public banners: Array<any> = [];
  public semBanner: boolean = false;
  public exibirHeader: boolean = false;
  public load: boolean = false;
  public usuario: any = {};
  public totalSacola: any = {};
  public sugestoes: Array<any> = [];
  public endereco: Endereco;
  public enderecos = [];
  public isEndereco: boolean = false;
  public loading: Boolean = false;
  public tabAtual;
  public showEndereco: Boolean = false;
  public showMapa: Boolean = false;
  // public responsive;
  // public isPhone;

  public showInfoLoja: Boolean = false;
  public sacola: Boolean;
  public qtdCarrinho: any;
  // Localização do dispositivo do usuário
  public location: any;
  public infoLocalizacao: Endereco;
  public isLocalizacao: boolean;
  public userData: any;
  public display: boolean = true;
  public options_endereco;
  public hidden: boolean = false;
  public delivery: boolean = true;
  // public mapaLeft;
  // public enderecoLeft;

  constructor(
    private utils: UtilsService,
    private modalService: ModalsService,
    private events: EventsService,
    private api: ApiService,
    private router: Router,
    private platform: Platform,
    private alertController: AlertController,
    private maps: MapsService,
    private modalController: ModalController,
    public breakpointObserver: BreakpointObserver
  ) {
    this.loja = this.utils.getStorage('loja');
    this.sacola = this.utils.getStorage('sacola');

    this.qtdCarrinho = this.utils.getStorage('qtdCarrinho');
    this.carrinho = this.utils.getStorage('carrinho');

    this.tabAtual =  this.router.url.split('?')[0];

    this.modalidade = this.utils.getStorage('modalidade');
    this.modalidadeAtiva = this.utils.getStorage('modalidade')?.codigo;

    this.enderecos = this.utils.getStorage('enderecos');
    if (this.utils.getStorage('enderecos') != null) {
      this.endereco = this.utils.getStorage('enderecos')[0];
    }

    this.events.subscribe('carrinho-numero', (date) => {
      if (this.utils.getStorage('carrinho') !== null) {
        const carrinho = this.utils.getStorage('carrinho');

        const loja_carrinho = this.utils.getStorage('loja_carrinho');

        carrinho.forEach((item: any) => {
          item.preco.unitario.valor = item.preco.total.valor;
          this.totalSacola.valor += item.preco.total.valor;
          this.totalSacola.texto = this.utils.floatToReal(
            this.totalSacola.valor
          );
        });

        this.sacola = true;
        this.utils.setStorage('sacola', this.sacola);

        this.qtdCarrinho = carrinho.reduce(
          (total: number, item: any) => total + item.qtd,
          0
        );
        this.utils.setStorage('qtdCarrinho', this.qtdCarrinho);
      } else {
        this.sacola = false;
        this.showEndereco = false;
        this.showMapa = false;
        this.utils.setStorage('sacola', this.sacola);
      }
    });

    this.events.subscribe('limpar-carrinho', () => {
      this.limparCarrinho();
    });
    this.events.subscribe('atualizar-cashback', () => {
      this.usuario = this.utils.getStorage('usuario');
    });

    this.events.subscribe('atualizar-loja-modalidade', (tab) => {
      setTimeout(() => {
        this.modalidade = this.utils.getStorage('modalidade');
        this.modalidadeAtiva = this.utils.getStorage('modalidade')?.codigo;
        this.loja = this.utils.getStorage('loja');
        this.tempoEntrega = this.loja?.tempoEntrega;
        this.statusLoja = this.loja?.statusLoja;
        this.horario = this.loja?.horario;
      }, 200);
    });
    this.events.subscribe('change-tab', (tab) => {
      this.tabAtual = tab;
    });

    this.events.subscribe('atualizar-endereco', (res) => {
      this.limparCarrinho();
      if (res.exclusao == true) {
        this.enderecos = res.enderecos;
        this.endereco = this.utils.getStorage('enderecos')[0];
        this.buscarCardapio();
        return;
      }

      let principal = [];
      this.enderecos = [];
      res.forEach((element) => {
        this.enderecos.push(element);
      });

      this.enderecos.forEach((element, index) => {
        if (element.principal == true) {
          principal = element;
          this.enderecos.splice(index, 1);
        }
      });
      this.enderecos.unshift(principal);
      this.utils.setStorage('enderecos', this.enderecos);
      this.utils.setStorage('endereco', this.enderecos[0]);
      this.endereco = this.utils.getStorage('enderecos')[0];

      this.buscarCardapio();
    });

    this.events.subscribe('abrirEnderecos', (res) => {
      this.showEndereco = res.showEndereco;
    });

    this.events.subscribe('abrirMapa', (res) => {
      this.showMapa = res.showMapa;
    });

    this.events.subscribe('atualizar-loja', (res) => {
      this.loja = this.utils.getStorage('loja');
      this.tempoEntrega = this.loja?.tempoEntrega;
      this.mapa = this.loja?.mapa;
      this.nome = this.loja?.nome;
      this.horario = this.loja?.horario;
      this.statusLoja = this.loja?.statusloja;
    });
  }

  ionViewWillEnter() {
    this.loja = this.utils.getStorage('loja');
    this.buscarCardapio();
    this.getLocation();
  }

  trocarModalidade(event) {
    this.utils.setStorage('modalidade', { codigo: event.target.value });
    this.modalidadeAtiva = event.target.value;

    this.loading = true;
    this.buscarCardapio();
  }

  async ngOnInit() {
    if (!this.utils.getStorage('semLogin')) {
      this.usuario = this.utils.getStorage('usuario');

      this.getLocation();

      await this.platform.ready();

      if (this.utils.getStorage('pular') == null) {
        try {
          const localizacao = await this.maps.getLocalizacao();

          const req = {
            lat: localizacao.latLng.lat,
            lng: localizacao.latLng.lng,
          };

          const res = await this.api.get('geocode', req);
          if (res.status === 'success') {
            this.isEndereco = true;
            this.infoLocalizacao = res.data;
          } else {
            this.isEndereco = false;
          }
        } catch (error) {
          this.isEndereco = false;
        }
      }
    } else {
      this.usuario = null;
    }

    this.buscarLoja();
  }

  async buscarLoja() {
    const loja = this.utils.getStorage('loja');
    const req = {
      lojaId: loja?.id,
      marcaId: loja?.marcaId,
    };

    const res = await this.api.get('loja', req);

    if (res.status === 'success') {
      this.loja_dados = res.data;
      console.log(this.loja_dados);

    }
  }

  async buscarCardapio() {
    const loja = this.utils.getStorage('loja');
    const req = {
      jwt: this.usuario?.jwt,
      marcaId: 2,
      modalidadeId: this.modalidade.codigo,
      lat:
        this.modalidade.codigo == 'TTA'
          ? loja.latitude
          : this.endereco != null
          ? this.endereco.lat
          : '',
      lng:
        this.modalidade.codigo == 'TTA'
          ? loja.longitude
          : this.endereco != null
          ? this.endereco.lng
          : '',
    };
  }

  async buscarSugestoes() {
    this.sugestoes = [];

    const req = {
      lojaId: this.loja?.loja?.id,
      marcaId: 2,
      modalidadeId: this.modalidade?.codigo,
    };

    const res = await this.api.get('vendas_sugestivas', req);
    if (res.status === 'success') {
      this.sugestoes = res.data;
    }
  }

  getLocation() {
    if (this.utils.getStorage('localizacao')) {
      this.infoLocalizacao = this.utils.getStorage('localizacao');
      this.isLocalizacao = true;
      this.loading = false;
    } else {
      this.loading = true;
      this.utils.getLocationService().then(async (resp) => {
        this.location = resp;
        const req = {
          lat: this.location.lat,
          lng: this.location.lng,
        };

        const res = await this.api.get('geocode', req);

        if (res.status === 'success') {
          this.infoLocalizacao = res.data;
          this.isLocalizacao = true;
          this.utils.setStorage('localizacao', this.infoLocalizacao);
          this.loading = false;
        } else {
          this.utils.exibirToast(
            'Ops.. Tivemos um problema em pegar sua localização, por favor tente novamente.',
            'erro'
          );
          this.loading = false;
        }
      });
    }
  }

  abrirEnderecos() {
    this.showEndereco = !this.showEndereco;
    if (this.showEndereco == true && this.showMapa == true)
      this.showMapa = false;
    // this.mapaAlinhamento();
  }

  abrirMapa() {
    this.loja = this.utils.getStorage('loja');
    this.tempoEntrega = this.loja?.tempoEntrega;
    this.mapa = this.loja?.mapa;
    this.nome = this.loja?.nome;
    this.horario = this.loja?.horario;
    this.statusLoja = this.loja?.statusloja;

    this.showMapa = !this.showMapa;
    if (this.showMapa == true && this.showEndereco) this.showEndereco = false;
    // this.mapaAlinhamento();
  }

  abrirDetalhes(tipo: string) {
    if (this.loja[tipo] == true) this.loja[tipo] = false;
    else this.loja[tipo] = true;
  }

  abrirInfoLojas() {
    this.showInfoLoja = !this.showInfoLoja;
  }

  trocaEndereco(i: number) {
    if (this.enderecos[i].principal == false) {
      this.display = !this.display;
      this.options_endereco = null;
    }
    this.enderecos.forEach((element, index) => {
      element.principal = false;
    });

    this.enderecos[i].principal = true;

    this.events.publish('atualizar-endereco', this.enderecos);

    this.showEndereco = false;
  }

  async selecionarEndereco(i: number) {
    if (this.utils.getStorage('carrinho')) {
      const alert = await this.alertController.create({
        header: 'Trocar endereço',
        message:
          'Trocar o endereço irá limpar os itens do seu carrinho. Deseja continuar?',
        buttons: [
          {
            text: 'Não',
            role: 'cancel',
          },
          {
            text: 'Sim',
            handler: async () => {
              this.trocaEndereco(i);
            },
          },
        ],
      });

      return await alert.present();
    } else {
      this.trocaEndereco(i);
    }
  }

  editarEndereco(index) {
    this.modalService.modalEditarEnderecoBrowser(index, true).then(() => {
      this.enderecos = this.utils.getStorage('enderecos');
      this.display = !this.display;
      this.options_endereco = index;
    });
  }

  excluirEndereco(index) {
    let EnderecoAtual = this.utils.getStorage('enderecos')[0];

    if (
      EnderecoAtual.id != null &&
      this.enderecos[index].id != null &&
      this.enderecos[index].id == EnderecoAtual.id
    ) {
      this.utils.exibirToast(
        'Você não pode excluir o seu endereço atual!',
        'erro'
      );
      this.display = !this.display;
      this.options_endereco = index;
      return this.modalController.dismiss();
    } else if (index == 0) {
      this.utils.exibirToast(
        'Você não pode excluir o seu endereço atual!',
        'erro'
      );
      this.display = !this.display;
      this.options_endereco = index;
      return this.modalController.dismiss();
    }

    this.modalService.modalExcluirEnderecoBrowser(index, true).then(() => {
      this.enderecos = this.utils.getStorage('enderecos');
      this.display = !this.display;
      this.options_endereco = index;
    });
  }

  displayOptions(index) {
    this.display = !this.display;
    this.options_endereco = index;
  }

  pesquisarProdutos() {
    this.modalService.modalSearchProdutos();
  }

  chamarFuncao() {
    this.volta.emit();
  }

  buscar() {
    this.modalService.modalBuscaRestaurantes();
  }

  abrirSacola() {
    if (
      this.utils.getStorage('sacola') != false &&
      this.utils.getStorage('sacola') != null
    ) {
      this.showEndereco = false;
      this.showMapa = false;
      this.modalService.modalCarrinhoBrowser();
    }
  }

  limparCarrinho() {
    this.sacola = false;

    this.utils.alteraStatusBar('claro');
    this.utils.removeStorage('sacola');
    this.utils.setStorage('sacola', false);
    this.utils.removeStorage('carrinho');
    this.utils.removeStorage('qtdCarrinho');
    this.utils.removeStorage('modalidade_carrinho');
  }

  irPara(pagina) {
    if (
      this.utils.getStorage('semLogin') &&
      pagina != '/tabs/perfil' &&
      pagina != '/tabs/home'
    ) {
      this.utils.removeStorage('endereco_confirmado');
      this.utils.exibirToast('Faça login para continuar!', 'erro');
      this.utils.navegarPaginaRoot('login');
      return;
    } else {
      this.utils.navegarPaginaRoot(pagina);
      this.tabAtual = pagina;
      this.showEndereco = false;
      this.showMapa = false;
      this.events.publish('change-tab', this.tabAtual);
    }
  }

  link(link: string) {
    this.utils.link(link);
  }

  abrirLojas(loja) {
    this.modalService.modalRetirarPedido(loja);
  }
}
