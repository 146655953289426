import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';
import { ModalsService } from 'src/app/services/modals/modals.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-cadastrar-senha',
  templateUrl: './cadastrar-senha.page.html',
  styleUrls: ['./cadastrar-senha.page.scss'],
})
export class CadastrarSenhaPage implements OnInit {

  public usuario: any = {};
  public botao: boolean = true;
  public loading: boolean = false;

  public novaSenha: string = '';
  public confirmaSenha: string = '';
  constructor(
    private utils: UtilsService,
    private modalService: ModalsService,
    private api: ApiService,
    private modalController: ModalController,
  ) { }

  ngOnInit() {
    
  }

  fechar(){    
    this.modalController.dismiss();
  }

  async alterarSenha() {

    if (!this.novaSenha.length) {
      this.utils.exibirToast('Informe a nova senha!', 'erro');
      return;
    }

    if (!this.confirmaSenha.length) {
      this.utils.exibirToast('A confirmação da senha é obrigatória!', 'erro');
      return;
    }

    if (this.novaSenha !== this.confirmaSenha) {
      this.utils.exibirToast('As senhas devem ser iguais!', 'erro');
      return;
    }

    if (!this.utils.validaSenha(this.novaSenha)) {
      this.utils.exibirToast('A senha não atende os requisitos mínimos!', 'erro');
      return;
    }

    const req = {
      jwt: this.usuario.jwt,
      senhaAtual: '',
      novaSenha: this.novaSenha,
      plataforma: 'chatbot'
    };

    this.loading = true;

    const res = await this.api.put('alterar_senha', req);

    this.loading = false;

    if (res.status === 'success') {
      this.utils.exibirToast(res.message);
      this.usuario.atualizado = true;
      this.utils.setStorage('usuario',this.usuario)
      this.modalService.fecharModal();
    } else {
      this.utils.exibirToast(res.message, 'erro');
    }
  }

}
