import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { LoadingController, NavController, ToastController } from '@ionic/angular';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

declare let dataLayer: any;
@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(
    private navController: NavController,
    private loadingController: LoadingController,
    private toastController: ToastController,
    private statusBar: StatusBar,
    private iab: InAppBrowser
  ) { }

  alteraStatusBar(tipo: string) {

    if (tipo === 'claro') {
      this.statusBar.backgroundColorByHexString('#FFFFFF');
      this.statusBar.styleDefault();
    } else {
      this.statusBar.backgroundColorByHexString('#000000');
      this.statusBar.styleLightContent();
    }

  }

  navegarPagina(pagina: string, animacao: boolean = true) {
    this.navController.navigateForward(pagina, { animated: animacao });
  }

  navegarPaginaRoot(pagina: string) {
    this.navController.navigateRoot(pagina);
  }

  voltarPagina(pagina: string, animacao: boolean = true) {
    this.navController.navigateBack(pagina, { animated: animacao });
  }

  voltarUltima() {
    this.navController.back();
  }

  public setStorage(key: string, valor: any) {
    localStorage.setItem(`cib.${key}`, JSON.stringify(valor));
  }

  public getStorage(key: string) {
    return JSON.parse(localStorage.getItem(`cib.${key}`));
  }

  public removeStorage(key: string) {
    localStorage.removeItem(`cib.${key}`);
  }

  public checkStorage(key: string) {
    return localStorage.getItem(`cib.${key}`) == null ? false : true;
  }

  public async exibirLoading() {
    const loading = await this.loadingController.create({
      message: '<ion-spinner name="crescent"></ion-spinner>',
      spinner: null,
      cssClass: 'loading'
    });
    return await loading.present();
  }

  public async exibirToast(mensagem: string, status: string = 'sucesso') {
    const toast = await this.toastController.create({
      position: 'top',
      message: mensagem,
      buttons: [
        {
          icon: status === 'erro' ? 'close-circle-sharp' : ''
        }
      ],
      cssClass: 'toast-' + status,
      duration: 1500
    });

    dataLayer.push({ ecommerce: null });
    if (status == 'erro') {
      dataLayer.push({
        'event': 'showErrorMessage',
        'message': mensagem,
      })
    }

    return await toast.present();
  }

  public async ocultarLoading() {

    try {
      return await this.loadingController.dismiss();
    } catch (error) {
      return false;
    }

  }

  public validaCelular(numero: string) {
    const regExp = /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})\-?(\d{4}))$/;
    return regExp.test(numero);
  }

  public validaSenha(senha: string) {
    const regExp = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
    return regExp.test(senha);
  }

  public validaCEP(cep: string) {
    const regExp = /[0-9]{5}-[\d]{3}/g;
    return regExp.test(cep);
  }

  public validaForm(form: FormGroup, control: Object) {

    if (form.valid) {
      return true;
    } else {

      for (const key in form.controls) {
        if (form.controls[key].errors != null && form.controls[key].errors?.required) {
          this.exibirToast(`O campo ${control[key]} é obrigatório!`, 'erro');
          return false;
        }

        if (form.controls[key].errors?.email) {
          this.exibirToast('E-mail inválido!', 'erro');
          return false;
        }

        if (form.controls[key].errors?.minlength.requiredLength > form.controls[key].errors?.minlength.actualLength) {
          this.exibirToast('A senha deve conter no mínimo 8 caracteres!', 'erro');
          return false;
        }

      }

      return false;

    }

  }

  public validaCPF(cpf: string) {

    cpf = cpf.replace(/\D/g, '');

    let soma: number = 0;
    let resto: number;

    const cpfs = [
      '00000000000',
      '11111111111',
      '22222222222',
      '33333333333',
      '44444444444',
      '55555555555',
      '66666666666',
      '77777777777',
      '88888888888',
      '99999999999'
    ];

    if (cpfs.includes(cpf)) return false;

    for (let index = 1; index <= 9; index++) {
      soma += parseInt(cpf.substring(index - 1, index)) * (11 - index);
    }

    resto = (soma * 10) % 11;
    resto = (resto === 10 || resto === 11) ? 0 : resto;

    if (resto !== parseInt(cpf.substring(9, 10))) return false;

    soma = 0;

    for (let index = 1; index <= 10; index++) {
      soma += parseInt(cpf.substring(index - 1, index)) * (12 - index);
    }

    resto = (soma * 10) % 11;
    resto = (resto === 10 || resto === 11) ? 0 : resto;

    if (resto !== parseInt(cpf.substring(10, 11))) return false;

    return true;

  }

  public validaValidade(data: string) {

    let [mes, ano] = data.split('/');

    ano = Number(ano) > 40 ? '19' + ano : '20' + ano;

    let hoje = new Date();
    let validade = new Date(Number(ano), Number(mes), 1);

    if (hoje.getTime() > validade.getTime()) {
      return false;
    } else {
      return true
    }

  }

  public floatToReal(valor: number) {
    return valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
  }

  public RealtoFloat(valor: string) {
    return Number.parseFloat(valor.replace('R$ ', '').replace(',', '.'));
  }

  public formataDecimal(valor: number) {
    return Number(valor.toFixed(2));
  }

  link(link: string, isALink: boolean = false) {
    const links = {
      facebook: 'https://pt-br.facebook.com/chinainboxoficial/',
      instagram: 'https://www.instagram.com/chinainboxoficial/',
      linkedin: 'https://www.linkedin.com/company/grupotrigo/',
      twitter: 'https://twitter.com/chinainbox?lang=pt',
      playstore: 'https://chinainbox.page.link/pop-upweb',
      appstore: 'https://chinainbox.page.link/pop-upweb',
      franqueado: 'https://www.grupotrigo.com.br/?utm_source=chinainbox.com.br&utm_medium=button_menu&utm_campaign=EXPANSAO&utm_content=SEJA_UM_FRANQUEADO',
      biscoito: 'https://www.chinainbox.com.br/biscoito-sorte',
    };

    const url = links[link];
    if (url) {
      this.iab.create(url, '_system').show();
    } else if (isALink) {
      this.iab.create(link, '_system').show();
    }
  }

  getLocationService(): Promise<any> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resp => {
        resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude })
      }, function (error) {
        reject(error);
      });
    })
  }

  public removeChar(string: String) {
    return string.replace(/[^a-zA-Z0-9 ]/g, '');
  }
}
