import { ApiService } from 'src/app/services/api.service';
import { UtilsService } from 'src/app/services/utils.service';
import { ModalController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';
import { MERCADOPAGO_TOKEN } from 'src/environments/environment';
declare var MercadoPago

@Component({
  selector: 'app-confirma-cartao',
  templateUrl: './confirma-cartao.page.html',
  styleUrls: ['./confirma-cartao.page.scss'],
})
export class ConfirmaCartaoPage implements OnInit {

  public token: any = null;
  public cartao: any;
  public cvv: any;

  public mp: any;
  constructor(
    private utils: UtilsService,
    private modalController: ModalController,
    private api: ApiService
  ) { 

    this.mp = new MercadoPago(MERCADOPAGO_TOKEN);

  }

  async ngOnInit() {

    // const optMp = {
    //   height: "10vw",
    //   marginTop: "2vw",
    //   color: "#000000",
    //   border: "1px solid black",
    //   "placeholder-color": "#868686",
    //   "text-align": "center"
    // };

    // await this.mp.fields.create('securityCode', {
    //   placeholder: "CVV",
    //   style: optMp
    // }).mount('form-checkout__securityCode-container');
  }

  voltar() {
    this.modalController.dismiss();
  }
  async criarToken() {

    const res = await this.api.post('token', {cartao_id: this.cartao.id});
    
    if (res.status === 'success') {
      
      try {
        const token = await this.mp.createCardToken({
          cardId: res.data.cofre_id,
          securityCode: this.cvv,
        });
  
        this.token = token;
                
        this.modalController.dismiss({token: this.token});

      } catch (e) {
        this.utils.exibirToast('Houve um erro ao realizar o pedido!', 'erro');
        return false
      }

      return true

    } else {
      this.utils.exibirToast('Houve um erro ao realizar o pedido!', 'erro');
    }
  }
}