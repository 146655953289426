import { ModalController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-filtro-fidelidade',
  templateUrl: './filtro-fidelidade.page.html',
  styleUrls: ['./filtro-fidelidade.page.scss'],
})
export class FiltroFidelidadePage implements OnInit {
  public meses: number;

  constructor(
    private modalController: ModalController
  ) { }

  ngOnInit() {
  }

  closeModal(){
    this.modalController.dismiss()
  }
  selecionarMeses(){
    this.modalController.dismiss({meses: this.meses})
  }

}
