import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { EventsService } from 'src/app/services/events.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-simples-web',
  templateUrl: './simples-web.page.html',
  styleUrls: ['./simples-web.page.scss'],
})
export class SimplesWebPage implements OnInit {

  @Input() titulo: string;
  @Input() texto: string;
  @Input() pagina: string;
  @Input() elemento: string;
  @Input() input: Boolean;
  public rede_logo;
  public loading: boolean = false;

  constructor(
    private utils: UtilsService,
    private modalController: ModalController,
    private events: EventsService
  ) { }

  ngOnInit() {
    this.rede_logo = this.utils.getStorage('loja').icone
  }

  irPara() {
    this.utils.navegarPagina('tabs/home')
    this.modalController.dismiss();
    this.events.publish('abrirEnderecos', { showEndereco: true })
  }

  fechar() {
    this.modalController.dismiss();
  }

}
