import { ModalController } from '@ionic/angular';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-detalhes-loja',
  templateUrl: './detalhes-loja.page.html',
  styleUrls: ['./detalhes-loja.page.scss'],
})
export class DetalhesLojaPage implements OnInit {

  @Input() loja: any;

  constructor(
    private modalController: ModalController
  ) { }

  ngOnInit() {
    this.loja.descricao = this.loja.descricao.replace('\r\n', '<br><br>');
    
  }

  fechar(marca) {
    this.modalController.dismiss({marca});
  }
}
