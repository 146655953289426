import { Component, OnInit } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-pix-progresso',
  templateUrl: './pix-progresso.component.html',
  styleUrls: ['./pix-progresso.component.scss'],
})
export class PixProgressoComponent implements OnInit {

  constructor(private utilsService: UtilsService) { 

  }

  ngOnInit() {}

  irPara(){
    this.utilsService.navegarPagina('pagamento-pedido-pix');
  }

}
