import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { AlertController, IonInfiniteScroll, ModalController } from '@ionic/angular';
import { Endereco } from 'src/app/interfaces/endereco';
import { ApiService } from 'src/app/services/api.service';
import { EventsService } from 'src/app/services/events.service';
import { ModalsService } from 'src/app/services/modals/modals.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-retirada-pedido',
  templateUrl: './retirada-pedido.page.html',
  styleUrls: ['./retirada-pedido.page.scss'],
})
export class RetiradaPedidoPage implements OnInit {
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;

  public lojas: any;
  public loja: any;
  public isLocalizacao: boolean;
  public infoLocalizacao: any;
  public infoEndereco: Endereco;
  public localizacaoAtual: any;
  public localizacao: any;
  public localizacao_atual: any;

  public isLocalizacaoAtual: boolean = false;
  public pagina: number = 1;
  public loading: boolean;


  constructor(
    private api: ApiService,
    private charge: ChangeDetectorRef,
    private modalController: ModalController,
    private modalService: ModalsService,
    private utils: UtilsService,
    private alertCtrlr: AlertController,
    private events: EventsService,
  ) { }

  ngOnInit() {
    this.infoEndereco = this.utils.getStorage('endereco');

    // this.buscarLojas(this.infoEndereco);
    this.getLocation();

  }

  async abrirLoja(loja) {

    if (!loja.statusLoja) {
      this.utils.exibirToast('Restaurante fechado no momento!', 'erro');
      return
    }
    if (this.utils.getStorage('carrinho')) {
      const alert = await this.alertCtrlr.create({
        header: 'Trocar endereço',
        message:
          'Trocar o endereço irá limpar os itens do seu carrinho. Deseja continuar?',
        buttons: [
          {
            text: 'Não',
            role: 'cancel',
          },
          {
            text: 'Sim',
            handler: async () => {
              this.modalService.modalRetiradaLoja(this.localizacaoAtual, loja);
              this.modalController.dismiss({ confirmar: true });
              this.events.publish('limpar-carrinho');
              this.events.publish('carrinho-numero', Date.now());

            },
          },
        ],
      });

      return await alert.present();
    } else {
      this.modalService.modalRetiradaLoja(this.localizacaoAtual, loja);
      this.modalController.dismiss({ confirmar: true });
    }
  }

  closeModal() {
    this.modalController.dismiss();
  }

  async buscarLojas(localizacao, isLocalizacaoAtual: boolean = false) {
    this.loading = true;

    this.isLocalizacaoAtual = isLocalizacaoAtual;

    const req = {
      latitude: localizacao ? localizacao.lat : '',
      longitude: localizacao ? localizacao.lng : '',
      start: isLocalizacaoAtual ? (this.pagina = 1) : this.pagina,
      limit: 30,
    };

    this.localizacaoAtual = req;

    const res = await this.api.get('pedido_retirada', req);

    this.loading = false

    setTimeout(() => {
      if (res.status === 'success') {
        this.lojas = res.data;
        this.pagina++;
      } else if (res.responseCode == 404) {
        this.lojas = null;
      }

      this.charge.detectChanges();
    }, 500);
  }

  async buscarPaginacao(localizacao) {
    const req = {
      latitude: localizacao ? localizacao.lat : '',
      longitude: localizacao ? localizacao.lng : '',
      start: this.pagina,
      limit: 30,
    };

    const res = await this.api.get('pedido_retirada', req);

    if (res.status === 'success') {
      res.data.forEach((item: any) => {
        this.lojas.push(item);
      });

      this.pagina++;
    } else {
      this.infiniteScroll.disabled = true;
    }
  }

  buscar(event: any) {
    setTimeout(async () => {
      if (this.localizacao_atual) {
        await this.buscarPaginacao(this.localizacao_atual);
      } else if (this.utils.getStorage('usuario')) {
        await this.buscarPaginacao(this.infoEndereco);
      } else {
        await this.buscarPaginacao('');
      }

      event.target.complete();
    }, 500);
  }

  getLocation() {
    this.loading = true;

    this.utils.getLocationService().then(async (resp) => {
      this.localizacao = resp;
      const req = {
        lat: this.localizacao.lat,
        lng: this.localizacao.lng
      };


      const res = await this.api.get('geocode', req);
      if (res.status === 'success') {
        // this.infoLocalizacao = res.data;

        this.localizacao_atual = res.data;
        // this.isLocalizacao = true;

        // if (this.infoEndereco == null) {
        this.buscarLojas(this.localizacao_atual);
        // } else {
        //   this.buscarLojas(this.infoEndereco);
        // }

        // this.utils.setStorage('endereco', this.infoLocalizacao);
      } else {
        this.buscarLojas(this.infoEndereco);

      }
    }).catch(async (resp) => {
      this.buscarLojas(this.infoEndereco);
    });
  }
}
