import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController, Platform } from '@ionic/angular';
import { Endereco } from 'src/app/interfaces/endereco';
import { ApiService } from 'src/app/services/api.service';
import { EventsService } from 'src/app/services/events.service';
import { MapsService } from 'src/app/services/maps.service';
import { ModalsService } from 'src/app/services/modals/modals.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-lista-enderecos',
  templateUrl: './lista-enderecos.page.html',
  styleUrls: ['./lista-enderecos.page.scss'],
})
export class ListaEnderecosPage implements OnInit {
  public enderecos = [];
  public isEndereco: boolean = false;
  public infoEndereco: Endereco;
  public display = false;
  public options_endereco;
  public selected: Boolean = false;
  public loading: Boolean = false;

  constructor(
    private utils: UtilsService,
    private modalService: ModalsService,
    private maps: MapsService,
    private platform: Platform,
    private events: EventsService,
    private api: ApiService,
    private alertCtrlr: AlertController,
    private modalController: ModalController
  ) {
    this.enderecos = this.utils.getStorage('enderecos');
  }

  async ngOnInit() {
    await this.platform.ready();

    if (this.utils.getStorage('pular') == null) {
      this.getLocation();
    }
  }

  async irPara() {
    await this.modalService.fecharModal();
    this.utils.setStorage('pagina', 'tabs/home');
    this.utils.removeStorage('endereco_confirmado');
    this.utils.navegarPagina('endereco');
  }

  async selecionarLocalizacao() {
    await this.modalService.fecharModal();
    this.utils.setStorage('endereco', this.infoEndereco);
    this.utils.setStorage('pagina_anterior', 'tabs/home');
    this.utils.navegarPagina('mapa-confirmacao', false);
  }

  async trocaEndereco(i) {
    if (this.enderecos[i].principal == false) {
      this.display = !this.display;
      this.options_endereco = null;
    }
    this.enderecos.forEach((element, index) => {
      element.principal = false;
    });

    this.enderecos[i].principal = true;
    this.events.publish('atualizar-endereco', this.enderecos);
    this.modalController.dismiss(this.enderecos);
  }

  async selecionarEndereco(i: number) {
    if (this.utils.getStorage('carrinho')) {
      const alert = await this.alertCtrlr.create({
        header: 'Trocar endereço',
        message:
          'Trocar o endereço irá limpar os itens do seu carrinho. Deseja continuar?',
        buttons: [
          {
            text: 'Não',
            role: 'cancel',
          },
          {
            text: 'Sim',
            handler: async () => {
              this.trocaEndereco(i);
            },
          },
        ],
      });

      return await alert.present();
    } else {
      this.trocaEndereco(i);
    }
  }

  async enderecosCliente() {
    this.enderecos = [];
    const res = await this.api.get('endereco_pedido');
    if (res.status === 'success') {
      this.enderecos = res.data;
      this.utils.setStorage('enderecos', this.enderecos);
    }
  }

  editarEndereco(index) {
    this.modalService.modalEditarEndereco(null, index, true).then(() => {
      this.enderecos = this.utils.getStorage('enderecos');
      this.display = !this.display;
      this.options_endereco = index;
    });
  }

  excluirEndereco(index) {
    let EnderecoAtual = this.utils.getStorage('enderecos')[0]
    if (EnderecoAtual.id != null && this.enderecos[index].id != null && this.enderecos[index].id == EnderecoAtual.id) {
      this.utils.exibirToast('Você não pode excluir o seu endereço atual!', 'erro')
      this.display = !this.display;
      this.options_endereco = index;
      return;
    }else if(index == 0){
      this.utils.exibirToast('Você não pode excluir o seu endereço atual!', 'erro');
      this.display = !this.display;
      this.options_endereco = index;
      return;
    }

    this.modalService.modalExcluirEndereco(null, index, true, index).then(() => {
      this.enderecos = this.utils.getStorage('enderecos');
      this.display = !this.display;
      this.options_endereco = index;
    });
  }

  displayOptions(index) {
    // if(this.enderecos[index].principal == true){
    //   if(this.options_endereco == index)
    //      this.display = !this.display
    //   else
    this.display = !this.display;
    this.options_endereco = index;
    // }
  }

  getLocation() {
    if (this.utils.getStorage('localizacao')) {
      this.infoEndereco = this.utils.getStorage('localizacao')
      this.isEndereco = true
      this.loading = false
    }
    else {
      this.loading = true
      this.utils.getLocationService().then(async resp => {
        let location = resp;
        const req = {
          lat: location.lat,
          lng: location.lng
        };

        const res = await this.api.get('geocode', req);

        if (res.status === 'success') {
          this.infoEndereco = res.data;
          this.isEndereco = true
          this.utils.setStorage('localizacao', this.infoEndereco);
          this.loading = false
        } else {
          this.utils.exibirToast('Ops.. Tivemos um problema em pegar sua localização, por favor tente novamente.', 'erro');
          this.loading = false
        }
      })
    }

  }
}
