import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface ISegmentButton {
  valor_padrao: string;
  opcoes: Array<IOpcoes>;
}

interface IOpcoes {
  valor: string;
  opcao: string;
}

@Component({
  selector: 'app-segment',
  templateUrl: './segment.component.html',
  styleUrls: ['./segment.component.scss'],
})
export class SegmentComponent implements OnInit {
  @Input() segment_buttons: ISegmentButton;
  @Output() alterarOpcao: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}

  ngOnInit() {}

  public switch(event: any): void {
    this.alterarOpcao.emit(event);
  }
}
