import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';


@Component({
  selector: 'app-detalhes-loja-browser',
  templateUrl: './detalhes-loja-browser.page.html',
  styleUrls: ['./detalhes-loja-browser.page.scss'],
})
export class DetalhesLojaBrowserPage implements OnInit {

  @Input() loja: any;

  constructor(
    private modalController: ModalController
  ) { }

  ngOnInit() {
    this.loja.descricao = this.loja.descricao.replace('\r\n', '<br><br>');
    
  }

  fechar(marca) {
    this.modalController.dismiss({marca});
  }

}
