import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import {
  IonRouterOutlet,
  LoadingController,
  ModalController,
  Platform,
} from '@ionic/angular';
import { Endereco } from 'src/app/interfaces/endereco';
import { ApiService } from 'src/app/services/api.service';
import { EventsService } from 'src/app/services/events.service';
import { ModalsService } from 'src/app/services/modals/modals.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-meus-enderecos-web',
  templateUrl: './meus-enderecos-web.component.html',
  styleUrls: ['./meus-enderecos-web.component.scss'],
})
export class MeusEnderecosWebComponent implements OnInit {
  public enderecos;
  public load: boolean = false;
  public abrirEdicao: boolean = false;
  public abrirAdicao: boolean = false;

  public endereco: Endereco;
  public loading: boolean = false;
  public formulario: FormGroup;
  public controladores: Object;
  public botao: boolean = true;
  public cor: string;

  constructor(
    private utils: UtilsService,
    private events: EventsService,
    private modals: ModalsService,
    private modalController: ModalController,
    private api: ApiService,
    private formBuilder: FormBuilder,
    private keyboard: Keyboard,
    private platform: Platform,
    public loadingController: LoadingController
  ) {
    this.endereco = this.utils.getStorage('endereco');

    if (this.utils.getStorage('enderecos_edicao')) {
      this.endereco = this.utils.getStorage('enderecos_edicao');
    }

    this.formulario = this.formBuilder.group({
      apelido: [this.endereco?.nome, Validators.required],
      cep: [this.endereco?.cep, Validators.required],
      logradouro: [this.endereco?.endereco, Validators.required],
      numero: [this.endereco?.numero, Validators.required],
      complemento: [this.endereco?.complemento],
      bairro: [this.endereco?.bairro, Validators.required],
      cidade: [this.endereco?.cidade, Validators.required],
      estado: [this.endereco?.estado, Validators.required],
      pontoReferencia: [this.endereco?.ponto_referencia],
      tipo: [this.endereco?.tipo, Validators.required],
    });

    this.controladores = {
      apelido: 'O apelido',
      cep: 'O CEP',
      endereco: 'O logradouro',
      numero: 'O número',
      bairro: 'O bairro',
      cidade: 'A cidade',
      estado: 'O estado',
      tipo: 'O tipo de endereço',
    };

    // NOVO ENDERECO ------>>>>><<<<<------
    if (this.utils.getStorage('novo-endereco') === null) {
      this.endereco = this.utils.getStorage('endereco');
    }

    this.utils.removeStorage('novo-endereco');

    this.formulario = this.formBuilder.group({
      apelido: [this.endereco.nome, Validators.required],
      cep: [this.endereco?.cep, Validators.required],
      logradouro: [this.endereco?.logradouro, Validators.required],
      numero: [this.endereco?.numero, Validators.required],
      complemento: [this.endereco?.complemento],
      bairro: [this.endereco?.bairro, Validators.required],
      cidade: [this.endereco?.cidade, Validators.required],
      estado: [this.endereco?.estado, Validators.required],
      pontoReferencia: [this.endereco?.ponto_referencia],
      tipo: ['Residencial', Validators.required],
    });
  }

  ionViewWillEnter() {
    this.events.subscribe('atualizar-lista-endereco', () => {
      this.enderecos = this.utils.getStorage('enderecos');
      this.enderecos.forEach((element) => {
        element.nome = element.apelido;
        element.endereco = element.logradouro;
      });
    });
  }

  ngOnInit() {
    this.enderecosCliente();

    this.events.subscribe('novo-endereco', () => {
      this.enderecosCliente();
    });

    this.events.subscribe('atualizar-endereco', () => {
      this.enderecosCliente();
    });

    // this.platform.ready().then(() => {
    //   this.keyboard.onKeyboardDidShow().subscribe(() => this.botao = false);
    //   this.keyboard.onKeyboardDidHide().subscribe(() => this.botao = true);
    // });
  }

  addEndereco() {
    this.abrirAdicao = true;

    this.abrirEdicao = false;

    this.utils.setStorage('novo-endereco', true);

    this.formulario = this.formBuilder.group({
      apelido: ['', Validators.required],
      cep: ['', Validators.required],
      logradouro: ['', Validators.required],
      numero: ['', Validators.required],
      complemento: [''],
      bairro: ['', Validators.required],
      cidade: ['', Validators.required],
      estado: ['', Validators.required],
      pontoReferencia: [''],
      tipo: ['', Validators.required],
    });
  }

  excluirEndereco(endereco: Endereco) {
    if (this.enderecos.length == 1) {
      this.utils.exibirToast('É preciso de no mínimo 1 endereço cadastrado!');
      return;
    }

    let EnderecoAtual = this.utils.getStorage('enderecos')[0];
    if (endereco.id == EnderecoAtual.id) {
      this.utils.exibirToast('Você não pode excluir o seu endereço atual!', 'erro');
      return;
    }

    this.modals.modalExcluirEnderecoWeb(endereco).then((data) => {
      if (data.data.exclusao) {
        this.enderecos = this.utils.getStorage('enderecos');
      }
    });
  }

  abriredicao(endereco) {
    this.formulario = this.formBuilder.group({
      apelido: [endereco.nome, Validators.required],
      cep: [endereco?.cep, Validators.required],
      logradouro: [endereco?.endereco, Validators.required],
      numero: [endereco?.numero, Validators.required],
      complemento: [endereco?.complemento],
      bairro: [endereco?.bairro, Validators.required],
      cidade: [endereco?.cidade, Validators.required],
      estado: [endereco?.estado, Validators.required],
      pontoReferencia: [endereco?.ponto_referencia],
      tipo: [endereco?.tipo, Validators.required],
    });

    this.abrirEdicao = true;
    this.abrirAdicao = false;
    this.utils.setStorage('enderecos_edicao', endereco);
  }

  voltar() {
    this.abrirEdicao = false;
    this.abrirAdicao = false;
  }

  async enderecosCliente() {
    this.enderecos = [];
    this.load = true;
    const res = await this.api.get('endereco_pedido');

    if (res.status === 'success') {
      this.enderecos = res.data;
    }
    this.load = false;
  }

  async buscarCep() {
    let cep: string = this.formulario.get('cep').value;

    if (cep.length === 9) {
      const req = {
        cep: cep,
      };

      const loading = await this.loadingController.create({
        message: 'Buscando CEP...',
      });

      await loading.present();

      const res = await this.api.get('buscar_cep', req);

      if (res.status === 'success') {
        this.formulario.patchValue({
          logradouro: res.data.logradouro,
          bairro: res.data.bairro,
          cidade: res.data.cidade,
          estado: res.data.estado,
        });

        await loading.onDidDismiss();
      } else {
        this.utils.exibirToast(res.message, 'erro');
      }
    }
  }

  async salvar() {
    if (this.utils.validaForm(this.formulario, this.controladores)) {
      if (!this.utils.validaCEP(this.formulario.get('cep').value)) {
        this.utils.exibirToast('CEP inválido!', 'erro');
        return;
      }

      this.endereco = this.utils.getStorage('enderecos_edicao');
      const req = {
        id: this.endereco.id,
        apelido: this.formulario.get('apelido').value,
        cep: this.formulario.get('cep').value,
        logradouro: this.formulario.get('logradouro').value,
        numero: this.formulario.get('numero').value,
        complemento: this.formulario.get('complemento').value,
        bairro: this.formulario.get('bairro').value,
        cidade: this.formulario.get('cidade').value,
        estado: this.formulario.get('estado').value,
        pontoReferencia: this.formulario.get('pontoReferencia').value,
        tipo: this.formulario.get('tipo').value,
      };

      this.loading = true;

      const res = await this.api.put('endereco_pedido', req);
      
      this.loading = false;

      if (res.status === 'success') {
        this.enderecos = req;

        let endereco_pedido = await this.api.get('endereco_pedido');

        let enderecos = this.utils.getStorage('enderecos');

        enderecos.forEach((endereco,index) => {
          if (endereco.id == req.id) {
            res.data.forEach(e => {
              if (endereco.id == e.id) {

                  enderecos[index].apelido  = e.nome;
                  enderecos[index].bairro = e.bairro;
                  enderecos[index].cep = e.cep;
                  enderecos[index].cidade = e.cidade;
                  enderecos[index].cliente_id = e.cliente_id;
                  enderecos[index].complemento = e.complemento;
                  enderecos[index].endereco = e.endereco;
                  enderecos[index].estado = e.estado;
                  enderecos[index].id = Number(e.id);
                  enderecos[index].lat = e.latitude;
                  enderecos[index].latitude = e.latitude;
                  enderecos[index].lng = e.longitude;
                  enderecos[index].logradouro = e.endereco;              
                  enderecos[index].longitude = e.longitude;
                  enderecos[index].nome =  e.nome;
                  enderecos[index].numero = e.numero;
                  enderecos[index].principal = e.principal == 0 ? false  : true; 
                  enderecos[index].ponto_referencia = e.ponto_referencia;
                  enderecos[index].tipo = e.tipo;
                                  
              }
            });
          }
        });

        enderecos[0].principal = true;
        
        let endereco_atual = this.utils.getStorage('endereco')
        if (endereco_atual.id == req.id) {
          res.data.forEach(e => {
            if (endereco_atual.id == e.id) {
              this.utils.setStorage('endereco', e);
            }
          });
        }

        this.utils.removeStorage('enderecos');
        this.utils.setStorage('enderecos', enderecos);
        this.events.publish('atualizar-lista-endereco');
        // this.events.publish('atualizar-endereco', enderecos);
        setTimeout(() => {
          this.abrirEdicao = false;

          this.enderecos = endereco_pedido.data;
        }, 500);
      }
    }
  }

  async addNovoEndereco() {
    if (this.utils.validaForm(this.formulario, this.controladores)) {
      if (!this.utils.validaCEP(this.formulario.get('cep').value)) {
        this.utils.exibirToast('CEP inválido!', 'erro');
        return;
      }

      const req = {
        apelido: this.formulario.get('apelido').value,
        cep: this.formulario.get('cep').value,
        logradouro: this.formulario.get('logradouro').value,
        numero: this.formulario.get('numero').value,
        complemento: this.formulario.get('complemento').value,
        bairro: this.formulario.get('bairro').value,
        cidade: this.formulario.get('cidade').value,
        estado: this.formulario.get('estado').value,
        pontoReferencia: this.formulario.get('pontoReferencia').value,
        tipo: this.formulario.get('tipo').value,
      };

      this.loading = true;

      const res = await this.api.post('endereco_pedido', req);

      this.loading = false;

      if (res.status === 'success') {
        let endereco_pedido = await this.api.get('endereco_pedido');
        let enderecos = endereco_pedido.data;
        enderecos.forEach((endereco) => {
          endereco.principal = false;
        });

        enderecos.splice(0, 1);

        res.data.principal = true;

        enderecos.unshift(res.data);

        this.utils.removeStorage('enderecos');
        this.utils.setStorage('enderecos', enderecos);
        this.events.publish('atualizar-endereco', enderecos);
        setTimeout(() => {
          this.events.publish('atualizar-lista-endereco');
          this.abrirAdicao = false;
        }, 500);
      }
    }
  }
}
