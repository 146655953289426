import { MeusCartoesMpWebComponent } from './components/meus-cartoes-mp-web/meus-cartoes-mp-web.component';
import { PesquisarProdutosComponent } from './components/pesquisar-produtos/pesquisar-produtos.component';
import { FooterWebComponent } from './components/footer-web/footer-web.component';
import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { TopoComponent } from './components/topo/topo.component';
import { CaixaLogoComponent } from './components/caixa-logo/caixa-logo.component';
import { CommonModule } from '@angular/common';
import { DadosPessoaisWebComponent } from './components/dados-pessoais-web/dados-pessoais-web.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrMaskerModule } from 'br-mask';
import { MeusCartoesWebComponent } from './components/meus-cartoes-web/meus-cartoes-web.component';
import { MeusEnderecosWebComponent } from './components/meus-enderecos-web/meus-enderecos-web.component';
import { NotificacoesWebComponent } from './components/notificacoes-web/notificacoes-web.component';
import { TermosPoliticasWebComponent } from './components/termos-politicas-web/termos-politicas-web.component';
import { DuvidasFrequentesWebComponent } from './components/duvidas-frequentes-web/duvidas-frequentes-web.component';
import { SobreWebComponent } from './components/sobre-web/sobre-web.component';
import { MissaoWebComponent } from './components/missao-web/missao-web.component';
import { CuponsWebComponent } from './components/cupons-web/cupons-web.component';
import { ShowHidePasswordComponent } from './components/show-hide-password/show-hide-password.component';
import { TabsFooterComponent } from './components/tabs-footer/tabs-footer.component';
import { CaixaVoltarWebComponent } from './components/caixa-voltar-web/caixa-voltar-web.component';
import { EntregaSegmentComponent } from './components/entrega-segment/entrega-segment.component';
import { LojaCardComponent } from './components/loja-card/loja-card.component';
import { RouterModule } from '@angular/router';
import { EfetuarPagamentoPixComponent } from './components/efetuar-pagamento-pix/efetuar-pagamento-pix.component';
import { DadosDebitoComponent } from './components/dados-debito/dados-debito.component';
import { SegmentComponent } from './components/segment/segment.component';
import { PixProgressoComponent } from './components/pix-progresso/pix-progresso.component';

@NgModule({
  declarations: [
    TopoComponent,
    CaixaLogoComponent,
    DadosPessoaisWebComponent,
    MeusCartoesWebComponent,
    MeusEnderecosWebComponent,
    NotificacoesWebComponent,
    TermosPoliticasWebComponent,
    DuvidasFrequentesWebComponent,
    SobreWebComponent,
    MissaoWebComponent,
    CuponsWebComponent,
    ShowHidePasswordComponent,
    FooterWebComponent,
    TabsFooterComponent,
    PesquisarProdutosComponent,
    CaixaVoltarWebComponent,
    EntregaSegmentComponent,
    LojaCardComponent,
    EfetuarPagamentoPixComponent,
    DadosDebitoComponent,
    SegmentComponent,
    MeusCartoesMpWebComponent,
    PixProgressoComponent
  ],
  imports: [
    IonicModule,
    CommonModule,
    ReactiveFormsModule,
    BrMaskerModule,
    FormsModule,
    RouterModule,
  ],
  exports: [
    TopoComponent,
    CaixaLogoComponent,
    DadosPessoaisWebComponent,
    MeusCartoesWebComponent,
    MeusEnderecosWebComponent,
    NotificacoesWebComponent,
    TermosPoliticasWebComponent,
    DuvidasFrequentesWebComponent,
    SobreWebComponent,
    MissaoWebComponent,
    CuponsWebComponent,
    ShowHidePasswordComponent,
    FooterWebComponent,
    TabsFooterComponent,
    PesquisarProdutosComponent,
    CaixaVoltarWebComponent,
    EntregaSegmentComponent,
    LojaCardComponent,
    EfetuarPagamentoPixComponent,
    DadosDebitoComponent,
    MeusCartoesMpWebComponent,
    SegmentComponent,
    PixProgressoComponent
  ],
})
export class ComponentsModule { }
