import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { MapsService } from './maps.service';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root'
})
export class FinalizarPedidoService {

  constructor(
    private utils: UtilsService,
    private maps: MapsService,
    private platform: Platform
  ) { }

  async canActivate(): Promise<boolean> {
    await this.platform.ready().then(async () => {
        if(!this.utils.getStorage('carrinho')){
            if(this.utils.getStorage('endereco')){
                this.utils.navegarPagina('/tabs/home')
            }
            else{
                this.utils.navegarPagina('localizacao');
            }
        }
    });

    return true;

  }

}
