import { EventsService } from './../../services/events.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { Platform } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';
import { UtilsService } from 'src/app/services/utils.service';
import { ModalsService } from 'src/app/services/modals/modals.service';

import { MERCADOPAGO_TOKEN } from 'src/environments/environment';
import { MobileService } from 'src/app/services/mobile/mobile.service';
declare var MercadoPago;
@Component({
  selector: 'app-adicionar-cartao',
  templateUrl: './adicionar-cartao.page.html',
  styleUrls: ['./adicionar-cartao.page.scss'],
})
export class AdicionarCartaoPage implements OnInit {
  public formulario: FormGroup;
  public controladores: Object;
  public botao: boolean = true;
  public loading: boolean = false;
  public celular: boolean;

  public mp: any;

  constructor(
    private utils: UtilsService,
    private formBuilder: FormBuilder,
    private api: ApiService,
    private keyboard: Keyboard,
    private platform: Platform,
    private events: EventsService,
    private modals: ModalsService,
    private mobileService: MobileService
  ) {
    this.mobileService.celular$.subscribe((celular) => {
      this.celular = celular;
    });

    this.formulario = this.formBuilder.group({
      apelido: ['', Validators.required],
      numero: ['', Validators.required],
      titular: ['', Validators.required],
      cpf: ['', Validators.required],
      validade: ['', Validators.required],
      cvv: ['', Validators.required]
    });

    this.controladores = {
      apelido: 'O apelido',
      numero: 'O número',
      titular: 'O titular',
      cpf: 'O CPF',
      validade: 'A validade',
      cvv: 'O CVV'
    };

    // setTimeout(() => {
    //   this.mp = new MercadoPago(MERCADOPAGO_TOKEN);

    //   const optMp = {
    //     height: '5vw',
    //     marginTop: '3vw',
    //     color: '#626264',
    //     border: '1px solid black',
    //     'border-radius': '10px',
    //     'placeholder-color': '#626264',
    //   };
    //   const optMpDuplo = {
    //     height: '10vw',
    //     marginTop: '6vw',
    //     color: '#626264',
    //     border: '1px solid black',
    //     'border-radius': '10px',
    //     'placeholder-color': '#626264',
    //   };

    //   const cardNumberElement = this.mp.fields
    //     .create('cardNumber', {
    //       placeholder: 'Número*',
    //       style: optMp,
    //     })
    //     .mount('cardNumber');

    //   const expirationDateElement = this.mp.fields
    //     .create('expirationDate', {
    //       placeholder: 'Validade*',
    //       style: optMpDuplo,
    //     })
    //     .mount('expirationDate');

    //   const securityCodeElement = this.mp.fields
    //     .create('securityCode', {
    //       placeholder: 'Cód. Segurança*',
    //       style: optMpDuplo,
    //     })
    //     .mount('securityCode');
    // }, 1000);
  }

  ngOnInit() {
    // this.platform.ready().then(() => {
    //   this.keyboard.onKeyboardDidShow().subscribe(() => this.botao = false);
    //   this.keyboard.onKeyboardDidHide().subscribe(() => this.botao = true);
    // });
  }

  async validarCartao(req) {
    let token;

    try {
      token = await this.mp.fields.createCardToken(req);
      return token;
    } catch (error) {
      this.utils.exibirToast(
        'Cartão não autorizado pela operadora! Revise os dados e tente novamente.',
        'erro'
      );
    }
  }

  async cadastrar() {
    if (this.utils.validaForm(this.formulario, this.controladores)) {
      if (!this.utils.validaCPF(this.formulario.get('cpf').value)) {
        this.utils.exibirToast('CPF inválido!', 'erro');
        return;
      }

      if (!this.utils.validaValidade(this.formulario.get('validade').value)) {
        this.utils.exibirToast('Data de validade expirada!', 'erro');
        return;
      }

      let usuario = this.utils.getStorage('usuario');
      let cartoes: Array<any> = usuario.cartoes;

      if (cartoes.length === 5) {
        this.utils.exibirToast('Você só pode cadastrar até 5 cartões!', 'erro');
        return;
      }

      // const tokenReq = {
      //   cardholderName: this.formulario.get('titular').value,
      //   identificationType: 'CPF',
      //   identificationNumber: this.utils.removeChar(
      //     this.formulario.get('cpf').value
      //   ),
      // };

      let req = {
        apelido: this.formulario.get('apelido').value,
        numero: this.formulario.get('numero').value,
        titular: this.formulario.get('titular').value,
        cpf: this.formulario.get('cpf').value,
        validade: this.formulario.get('validade').value,
        cvv: this.formulario.get('cvv').value,
      };

      // const token = await this.validarCartao(tokenReq);

      // if (token != undefined) {
        this.loading = true;
        // if (token.id != null || token.status == 'active') {
        //   req['token'] = token.id;
        //   req['final'] = token.last_four_digits;

          this.api.post('cartao_cielo', req).then((res: any) => {
            this.loading = false;

            if (res.status === 'success') {
              cartoes.unshift(res.data);

              usuario.cartoes = cartoes;
              this.utils.setStorage('usuario', usuario);

              this.utils.exibirToast(res.message);
              this.events.publish('atualizar-cartoes');
              this.modals.fecharModal().then(() => { });
            } else {
              this.utils.exibirToast(res.message, 'erro');
            }
          });
        // } else {
        //   this.loading = false;
        //   this.utils.exibirToast(
        //     'Cartão não autorizado pela operadora! Revise os dados e tente novamente.',
        //     'erro'
        //   );
        // }
      
    }
  }
}
