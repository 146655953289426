import { Component, Renderer2 } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { v4 as uuidv4 } from 'uuid';
declare let dataLayer: any;
declare let google: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(private router: Router, private readonly renderer: Renderer2) {
    this.startApp();
  }

  startApp() {
    // Send data to Metrix

    let uuid = uuidv4();

    const script = this.renderer.createElement('script') as HTMLScriptElement;
    script.src = `https://h.online-metrix.net/fp/tags.js?org_id=k8vif92e&session_id=braspag_grupotrigo_split` + uuid;
    script.id = 'metrix_head';
    script.async = true;
    this.renderer.appendChild(document.head, script);

    const script2 = this.renderer.createElement('noscript') as HTMLScriptElement;
    const scriptBody = this.renderer.createElement('iframe') as HTMLScriptElement;
    scriptBody.src = `https://h.online-metrix.net/fp/tags.js?org_id=k8vif92e&session_id=braspag_grupotrigo_split` + uuid;
    scriptBody.id = 'metrix_body';
    script2.id = 'metrix_noscript';
    scriptBody.style.width = '100px'
    scriptBody.style.height = '100px'
    scriptBody.style.border = '0'
    scriptBody.style.position = 'absolute';
    scriptBody.style.top = '-5000px';

    this.renderer.appendChild(script2, scriptBody);
    this.renderer.appendChild(document.body, script2);

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        dataLayer.push({
          event: 'virtual_page_view',
          path: event.url
        });
      }
    });
  }
}
