import { UtilsService } from 'src/app/services/utils.service';
import { ApiService } from './../../services/api.service';
import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { EventsService } from 'src/app/services/events.service';

declare let dataLayer: any;

@Component({
  selector: 'app-busca-restaurantes',
  templateUrl: './busca-restaurantes.page.html',
  styleUrls: ['./busca-restaurantes.page.scss'],
})
export class BuscaRestaurantesPage implements OnInit {
  public localizacao = this.utils.getStorage('endereco');
  public modalidade = this.utils.getStorage('modalidade');
  public cardapios = [];
  public no_results = false;
  public error_msg: any;
  public brand: any;
  public loja: any;
  public typingTimer; //timer identifier
  public doneTypingInterval = 1000; //time in ms, 5 seconds for example

  constructor(
    private api: ApiService,
    private utils: UtilsService,
    public modalController: ModalController,
    private events: EventsService
  ) { }

  async ngOnInit() {
    this.brand = this.utils.getStorage('toda_loja')
    this.loja = this.utils.getStorage('loja')

    const res = await this.api.get('cardapio', {
      lat: (this.modalidade.codigo == 'TTA') ? this.loja.latitude : ((this.localizacao != null) ? this.localizacao.lat : ''),
      lng: (this.modalidade.codigo == 'TTA') ? this.loja.longitude : ((this.localizacao != null) ? this.localizacao.lng : ''),
      loja_id: (this.modalidade.codigo == 'TTA') ? this.loja.loja_id : '',
      modalidadeId: this.modalidade.codigo,
      marcaId: 2,
    });

    if (res.status == 'success') {
      this.cardapios = res.data.cardapio;
    } else {
      this.no_results = true;
      this.error_msg = res.message;
    }

    this.cardapios.forEach((item) => {
      let event = {
        event: 'view_item_list',
        ecommerce: {
          origin: this.utils.getStorage('canal-compra') == "whatsapp" ? 'CHATBOT' : 'PWA',
          items: this.itemsEvent(item),
        },
      };

      dataLayer.push({ ecommerce: null });
      dataLayer.push(event);
    });
  }

  startCountdown(keyword) {
    clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(() => {
      this.searchProducts(keyword);
    }, this.doneTypingInterval);
  }

  clearCountdown() {
    clearTimeout(this.typingTimer);
  }

  async searchProducts(keyword) {
    const loja = this.utils.getStorage('loja')

    if (keyword == '') {
      this.cardapios = [];
      const res = await this.api.get('cardapio', {
        lat: (this.modalidade.codigo == 'TTA') ? loja.latitude : ((this.localizacao != null) ? this.localizacao.lat : ''),
        lng: (this.modalidade.codigo == 'TTA') ? loja.longitude : ((this.localizacao != null) ? this.localizacao.lng : ''),
        loja_id: (this.modalidade.codigo == 'TTA') ? loja.loja_id : '',
        modalidadeId: this.modalidade.codigo,
        marcaId: 2,
      });

      if (res.status == 'success') {
        this.cardapios = res.data.cardapio;
        this.no_results = false;
      } else {
        this.no_results = true;
        this.error_msg = res.message;
      }
      return;
    }

    this.no_results = false;
    let req = {
      keyword,
      lat: (this.modalidade.codigo == 'TTA') ? loja.latitude : ((this.localizacao != null) ? this.localizacao.lat : ''),
      lng: (this.modalidade.codigo == 'TTA') ? loja.longitude : ((this.localizacao != null) ? this.localizacao.lng : ''),
      modalidade: this.modalidade,
      marcaId: 2,
    };

    let event = {
      event: 'search',
      search_term: keyword,
    };

    dataLayer.push({ ecommerce: null });
    dataLayer.push(event);

    const res = await this.api.post('buscar_produtos', req);

    if (res.status == 'success') {
      this.cardapios = [];
      this.cardapios = res.data.cardapio;

      let event = {
        event: 'view_search_results',
        search_term: keyword,
      };

      dataLayer.push({ ecommerce: null });
      dataLayer.push(event);
    } else {
      this.no_results = true;
      this.error_msg = res.message;
    }
  }

  async selecionarProduto(produto: any) {
    produto.pesquisa = true;
    this.utils.setStorage('produto', produto);
    this.utils.navegarPagina('produto');

    this.modalController.dismiss();

    let event = {
      event: 'select_item',
      ecommerce: {
        origin: this.utils.getStorage('canal-compra') == "whatsapp" ? 'CHATBOT' : 'PWA',
        items: [
          {
            item_id: String(produto.id),
            item_list_id: String(produto.categoriaId),
            item_list_name: "Pesquisa",
            item_name: produto.produto,
            currency: 'BRL',
            item_brand: this.brand.nome,
            item_category: produto.categoria,
            price: (produto.preco_obs == 1) ? produto.preco_partir.valor : produto.preco.valor,
            quantity: 1
          },
        ],
      },
    };

    dataLayer.push({ ecommerce: null });
    dataLayer.push(event);
  }

  closeModal() {
    this.modalController.dismiss();
  }

  itemsEvent(produtos) {
    let items = [];

    produtos.cardapio.forEach((item) => {
      items.push({
        item_id: String(item.id),
        item_list_id: String(item.id),
        item_list_name: "Pesquisa",
        item_name: item.produto,
        currency: 'BRL',
        price: (item.preco.valor === 0) ? item.preco_partir.valor : item.preco.valor,
        item_category: produtos.categoria,
        quantity: 1,
      });
    });

    return items;
  }
}
