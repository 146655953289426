import { Component, Input, OnInit } from '@angular/core';

import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-termos-politicas-web',
  templateUrl: './termos-politicas-web.component.html',
  styleUrls: ['./termos-politicas-web.component.scss'],
})
export class TermosPoliticasWebComponent implements OnInit {
  public termos: Boolean = true;
  public politica: Boolean = false;

  safeFrame;

  @Input() frame;

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.safeFrame = this.sanitizer.bypassSecurityTrustResourceUrl(this.frame);
  }

  trocarStatus(ev: any) {
    if (ev.detail.value === "termos") {
      this.termos = true;
      this.politica = false;
    } else {
      this.politica = true;
      this.termos = false;
    }
  }
}
