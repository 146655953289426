import { Component, OnInit, Input } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { ModalsService } from 'src/app/services/modals/modals.service';

@Component({
  selector: 'app-confirmar-loja-refazer-pedido',
  templateUrl: './confirmar-loja-refazer-pedido.page.html',
  styleUrls: ['./confirmar-loja-refazer-pedido.page.scss'],
})
export class ConfirmarLojaRefazerPedidoPage implements OnInit {

  @Input() pedido: any;

  constructor(
    private modalController: ModalController,
    private modals: ModalsService
  ) { }

  ngOnInit() {
  }

  fechar() {
    this.modals.fecharModal();
  }

  confirmarPedido() {
    this.modalController.dismiss('sim');
  }


}
