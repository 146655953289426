import { Component, Input, OnInit } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-caixa-logo',
  templateUrl: './caixa-logo.component.html',
  styleUrls: ['./caixa-logo.component.scss'],
})
export class CaixaLogoComponent implements OnInit {
  @Input() voltar: boolean = false;
  @Input() menor: boolean = false;

  constructor(
    private utils: UtilsService
  ) { }

  ngOnInit() { }

  voltarPage() {

    if (this.utils.getStorage('pagina_anterior')) {
      if (this.utils.getStorage('pagina_anterior') == 'cardapio') {
        this.utils.voltarPagina('cardapio');
      } else {
        this.utils.voltarPagina('tabs/home');
      }
      this.utils.removeStorage('pagina_anterior');
    } else {
      this.utils.voltarUltima();
    }

  }

}
