import { EventsService } from './../../services/events.service';
import { ModalsService } from 'src/app/services/modals/modals.service';
import { UtilsService } from './../../services/utils.service';
import { ModalController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sugestoes',
  templateUrl: './sugestoes.page.html',
  styleUrls: ['./sugestoes.page.scss'],
})
export class SugestoesPage implements OnInit {
  public produto: any;
  public sugestoes = this.utils.getStorage('sugestoes-loja')
  constructor(
    private modal: ModalController,
    private utils: UtilsService,
    private modalService: ModalsService,
    private events: EventsService,
    private modalController: ModalController
    ) { }

  ngOnInit() {
    this.sugestoes.forEach(element => {
        if(!element.preco_partir){
          element.preco_partir = '';
        }
        else{
          
          element.preco_partir = element.preco_partir.texto
        }
    });
    this.produto = this.utils.getStorage('produto');
  }

  fechar(){
    this.modal.dismiss();
    this.utils.voltarPagina('cardapio')
  }

  seguir(){
    this.modal.dismiss();

    // this.modalService.modalFinalizarPedido().then(async (res) => {

    //   if (res.data === 'sucesso') {

    //     this.utils.alteraStatusBar('claro');
    //     await this.modalController.getTop();
    //     await this.modalController.dismiss('', '', 'carrinho');

    //     if (this.utils.getStorage('edicao-restaurante') == 'sim') {
    //       this.utils.navegarPagina('/home');
    //       this.utils.removeStorage('edicao-restaurante');
    //     }

    //   }

    //   if (res.data === 'sucesso_cpf') {
    //     this.utils.alteraStatusBar('claro');
    //     await this.modalController.getTop();
    //     await this.modalController.dismiss('', '', 'carrinho');
    //     await this.utils.exibirToast('Para fazer um pedido é necessário cadastrar o CPF', 'erro');
    //     this.utils.navegarPagina('dados-pessoais');
    //   }

    // });
    // this.utils.voltarPagina('cardapio');
    // this.utils.exibirToast('Produto adicionado com sucesso!');
  }

  async goToSugestao(produto){
    this.utils.alteraStatusBar('claro');
    await this.modalController.getTop();
    this.utils.setStorage('produto', produto);
    this.modalController.dismiss('', 'sugestao', 'modal-sugestoes');
  }
}
