import { Component, OnInit } from '@angular/core';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { Platform } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';
import { ModalsService } from 'src/app/services/modals/modals.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-alterar-senha',
  templateUrl: './alterar-senha.page.html',
  styleUrls: ['./alterar-senha.page.scss'],
})
export class AlterarSenhaPage implements OnInit {

  public usuario: any = {};
  public botao: boolean = true;
  public loading: boolean = false;

  public senhaAtual: string = '';
  public novaSenha: string = '';
  public confirmaSenha: string = '';

  constructor(
    private utils: UtilsService,
    private modalService: ModalsService,
    private api: ApiService,
    private keyboard: Keyboard,
    private platform: Platform
  ) {
    this.usuario = this.utils.getStorage('usuario');
  }

  ngOnInit() {

        // this.platform.ready().then(() => {
    //   this.keyboard.onKeyboardDidShow().subscribe(() => this.botao = false);
    //   this.keyboard.onKeyboardDidHide().subscribe(() => this.botao = true);
    // });

  }

  async alterarSenha() {

    if (!this.senhaAtual.length) {
      this.utils.exibirToast('Informe a senha atual!', 'erro');
      return;
    }

    if (!this.novaSenha.length) {
      this.utils.exibirToast('Informe a nova senha!', 'erro');
      return;
    }

    if (!this.confirmaSenha.length) {
      this.utils.exibirToast('A confirmação da senha é obrigatória!', 'erro');
      return;
    }

    if (this.novaSenha !== this.confirmaSenha) {
      this.utils.exibirToast('As senhas devem ser iguais!', 'erro');
      return;
    }

    if (!this.utils.validaSenha(this.novaSenha)) {
      this.utils.exibirToast('A senha não atende os requisitos mínimos!', 'erro');
      return;
    }

    const req = {
      jwt: this.usuario.jwt,
      senhaAtual: this.senhaAtual,
      novaSenha: this.novaSenha
    };

    this.loading = true;

    const res = await this.api.put('alterar_senha', req);

    this.loading = false;

    if (res.status === 'success') {
      this.utils.exibirToast(res.message);
      this.modalService.fecharModal();
    } else {
      this.utils.exibirToast(res.message, 'erro');
    }

  }
}
