import { ModalsService } from './../../services/modals/modals.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pesquisar-produtos',
  templateUrl: './pesquisar-produtos.component.html',
  styleUrls: ['./pesquisar-produtos.component.scss'],
})
export class PesquisarProdutosComponent implements OnInit {

  constructor(
    private modalService: ModalsService

  ) { }

  ngOnInit() {}


  buscar(){
    this.modalService.modalBuscaRestaurantes();
  }

}
