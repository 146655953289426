import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { MapsService } from './maps.service';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root'
})
export class AutenticacaoService {

  constructor(
    private utils: UtilsService,
    private maps: MapsService,
    private platform: Platform
  ) { }

  async canActivate(): Promise<boolean> {

    const enderecoConfirmado = this.utils.getStorage('endereco_confirmado');
    const usuario = this.utils.getStorage('usuario');
    const carrinho = this.utils.getStorage('carrinho');
    const tutorial = this.utils.getStorage('tutorial');
    await this.platform.ready().then(async () => {
      if (usuario != null) {
        this.utils.navegarPaginaRoot('/tabs/home');
        return false;
      }
    });

    return true;

  }

}
