import { UtilsService } from 'src/app/services/utils.service';
import { Component, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';
import { EventsService } from 'src/app/services/events.service';
import { SlideOptions } from 'src/app/interfaces/slide';
import { ModalsService } from 'src/app/services/modals/modals.service';

declare let dataLayer: any;

@Component({
  selector: 'app-modal-carrinho-browser',
  templateUrl: './modal-carrinho-browser.page.html',
  styleUrls: ['./modal-carrinho-browser.page.scss'],
})
export class ModalCarrinhoBrowserPage implements OnInit {
  public rede_logo;
  public optionProdutos: SlideOptions;
  public total: number;
  public total_sem_cashback: number;
  public subtotal: number;
  public carrinho: Array<any>;
  public sugestoes: Array<any>;
  public marca: number;
  public cor: string;
  public loja: any;
  public usuario: any;
  public cupom: string;
  public loading: boolean;
  public modalidade: any;
  public frete: number;
  public usar_cashback: boolean;
  public opcaoRemover: boolean = false;
  public cashback: number = 0;
  public descontoFrete: number = 0;
  public brand: any = {};
  public adicionais_selecionado: any;
  public carrinhoFilter: any = null;
  public dados_produto: any;
  public valor_excessao: any;

  public desconto: {
    cupom?: string;
    cupomId?: number;
    valor?: number;
    tipo?: string;
    porcentagem?: number;
    frete?: number;
    categoriaId?: number;
    codProduto?: string;
  };

  constructor(
    private utils: UtilsService,
    private modalService: ModalsService,
    private modalController: ModalController,
    private api: ApiService,
    private events: EventsService,
    private platform: Platform
  ) {
    // this.events.subscribe('atualizar-endereco', (res) => {
    //   if(this.utils.getStorage('loja').length == 0){
    //     this.utils.setStorage('carrinho', {})
    //     this.utils.setStorage('loja_carrinho',{})
    //     this.utils.setStorage('qtdCarrinho',0)
    //   }
    // });

    this.inicializarPagina();
  }

  inicializarPagina() {
    this.rede_logo = this.utils.getStorage('loja').cabecalho;

    // Inicializando variáveis
    this.optionProdutos = {
      slidesPerView: 3.6,
      spaceBetween: 10,
      navigation: true,
      pagination: {
        el: '.swiper-pagination',
        type: 'progressbar',
      },
    };

    this.cupom = '';

    // this.sugestoes = [];

    this.loading = false;

    this.desconto = {
      cupom: '',
      cupomId: 0,
      valor: 0,
      tipo: '',
      porcentagem: 0,
      frete: 0,
    };

    // Removendo e buscando valores no localStorage
    this.utils.removeStorage('desconto');
    this.loja = this.utils.getStorage('loja');
    this.modalidade = this.utils.getStorage('modalidade');
    this.carrinho = this.utils.getStorage('carrinho');
    this.usuario = this.utils.getStorage('usuario');
    this.brand = this.utils.getStorage('toda_loja');
    if (this.utils.getStorage('cupomDesconto') != null) {
      this.cupom = this.utils.getStorage('cupomDesconto');
      this.buscarDesconto();
    }

    // Setar paleta de cores marca
    const root = document.documentElement;
    root.style.setProperty('--cor', this.loja.paletaCor);

    // Calculando valor do carrinho
    this.calcularTotal();
    this.buscarSaldo();
  }

  itemsEvent() {
    let items = [];
    let opcionais = '';
    let obrigatorios = '';
    let preco = '';

    this.carrinho.forEach((item) => {
      this.dados_produto = item;
      this.adicionais_selecionado = this.filtroAdicionais(item.adicionais);

      item.opcionais.forEach((opc) => {
        if (opc.selecionado) {
          opcionais = opc.opcional;
        }
      });

      item.obrigatorios.forEach((obr) => {
        if (obr.selecionado) {
          obrigatorios = obr.opcional;
          preco = obr.preco.unitario.valor;
        }
      });

      if (item.pesquisa === true) {
        items.push({
          item_name: item.nome,
          item_id: String(item.id),
          price:
            item.preco.unitario.valor == '' ? preco : item.preco.unitario.valor,
          item_brand: this.brand.nome,
          item_category: item.categoria,
          item_category2: false,
          item_list_name: 'Pesquisa',
          item_list_id: String(item.categoriaId),
          quantity: item.qtd,
          item_variant:
            item.obrigatorios == '' && item.opcionais != ''
              ? opcionais
              : item.obrigatorios != '' && item.opcionais != ''
                ? obrigatorios + ' | ' + opcionais
                : item.opcionais == '' && item.obrigatorios != ''
                  ? obrigatorios
                  : '',
        });

        this.adicionais_selecionado.forEach((item) => {
          items.push({
            item_id: String(item.cod),
            item_name: item.nome,
            currency: 'BRL',
            item_brand: this.brand.nome,
            item_category: this.dados_produto.categoria,
            item_list_id: String(this.dados_produto.categoriaId),
            item_list_name: 'Pesquisa',
            item_variant: '',
            price: item.preco.unitario.valor,
            quantity:
              item.qtd && this.dados_produto.qtd > 1
                ? this.dados_produto.qtd
                : item.qtd,
            item_category2: item.itemCategory2,
            item_category3: this.dados_produto.id,
          });
        });
      } else {
        items.push({
          item_name: item.nome,
          item_id: String(item.id),
          price:
            item.preco.unitario.valor == '' ? preco : item.preco.unitario.valor,
          item_brand: this.brand.nome,
          item_category: item.categoria,
          item_category2: false,
          item_list_name: item.categoria,
          item_list_id: String(item.categoriaId),
          quantity: item.qtd,
          item_variant:
            item.obrigatorios == '' && item.opcionais != ''
              ? opcionais
              : item.obrigatorios != '' && item.opcionais != ''
                ? obrigatorios + ' | ' + opcionais
                : item.opcionais == '' && item.obrigatorios != ''
                  ? obrigatorios
                  : '',
        });

        this.adicionais_selecionado.forEach((item) => {
          items.push({
            item_id: String(item.cod),
            item_name: item.nome,
            currency: 'BRL',
            item_brand: this.brand.nome,
            item_category: this.dados_produto.categoria,
            item_list_id: String(this.dados_produto.categoriaId),
            item_list_name: this.dados_produto.categoria,
            item_variant: '',
            price: item.preco.unitario.valor,
            quantity:
              item.qtd && this.dados_produto.qtd > 1
                ? this.dados_produto.qtd
                : item.qtd,
            item_category2: item.itemCategory2,
            item_category3: this.dados_produto.id,
          });
        });
      }
    });

    return items;
  }

  itemsRemove(produto: any) {
    let items = [];
    let opcionais = '';
    let obrigatorios = '';
    let preco = '';

    this.dados_produto = produto;
    this.adicionais_selecionado = this.filtroAdicionais(produto.adicionais);

    produto.opcionais.forEach((opc) => {
      if (opc.selecionado) {
        opcionais = opc.opcional;
      }
    });

    produto.obrigatorios.forEach((obr) => {
      if (obr.selecionado) {
        obrigatorios = obr.opcional;
        preco = obr.preco.unitario.valor;
      }
    });

    if (produto.pesquisa === true) {
      items.push({
        item_name: produto.nome,
        item_id: String(produto.id),
        price:
          produto.preco.unitario.valor == ''
            ? preco
            : produto.preco.unitario.valor,
        item_brand: this.brand.nome,
        item_category: produto.categoria,
        item_category2: false,
        item_list_name: 'Pesquisa',
        item_list_id: String(produto.categoriaId),
        quantity: produto.qtd,
        item_variant:
          produto.obrigatorios == '' && produto.opcionais != ''
            ? opcionais
            : produto.obrigatorios != '' && produto.opcionais != ''
              ? obrigatorios + ' | ' + opcionais
              : produto.opcionais == '' && produto.obrigatorios != ''
                ? obrigatorios
                : '',
      });

      this.adicionais_selecionado.forEach((item) => {
        items.push({
          item_id: String(item.cod),
          item_name: item.nome,
          currency: 'BRL',
          item_brand: this.brand.nome,
          item_category: this.dados_produto.categoria,
          item_list_id: String(this.dados_produto.categoriaId),
          item_list_name: 'Pesquisa',
          item_variant: '',
          price: item.preco.unitario.valor,
          quantity: item.qtd && produto.qtd > 1 ? produto.qtd : item.qtd,
          item_category2: item.itemCategory2,
          item_category3: this.dados_produto.id,
        });
      });
    } else {
      items.push({
        item_name: produto.nome,
        item_id: String(produto.id),
        price:
          produto.preco.unitario.valor == ''
            ? preco
            : produto.preco.unitario.valor,
        item_brand: this.brand.nome,
        item_category: produto.categoria,
        item_category2: false,
        item_list_name: produto.categoria,
        item_list_id: String(produto.categoriaId),
        quantity: produto.qtd,
        item_variant:
          produto.obrigatorios == '' && produto.opcionais != ''
            ? opcionais
            : produto.obrigatorios != '' && produto.opcionais != ''
              ? obrigatorios + ' | ' + opcionais
              : produto.opcionais == '' && produto.obrigatorios != ''
                ? obrigatorios
                : '',
      });

      this.adicionais_selecionado.forEach((item) => {
        items.push({
          item_id: String(item.cod),
          item_name: item.nome,
          currency: 'BRL',
          item_brand: this.brand.nome,
          item_category: this.dados_produto.categoria,
          item_list_id: String(this.dados_produto.categoriaId),
          item_list_name: this.dados_produto.categoria,
          item_variant: '',
          price: item.preco.unitario.valor,
          quantity: item.qtd && produto.qtd > 1 ? produto.qtd : item.qtd,
          item_category2: item.itemCategory2,
          item_category3: this.dados_produto.id,
        });
      });
    }

    return items;
  }

  observationsEvent(adicionais) {
    let observations = '';
    adicionais.forEach((element) => {
      observations += '|' + element.nome;
    });
    return observations;
  }

  async ngOnInit() {
    await this.platform.ready();

    if (this.platform.is('ios') || this.platform.is('iphone')) {
      this.utils.alteraStatusBar('escuro');
    }

    await this.buscarSaldo();

    this.events.subscribe('pedido-finalizado', async () => {
      this.utils.alteraStatusBar('claro');
      await this.modalController.getTop();
      await this.modalController.dismiss(undefined, undefined, 'carrinho');

      this.utils.navegarPagina('detalhes-pedido');
    });

    let event = {
      event: 'view_cart',
      ecommerce: {
        currency: 'BRL',
        value: this.total,
        items: this.itemsEvent(),
      },
    };

    dataLayer.push({ ecommerce: null });
    dataLayer.push(event);
  }

  async ionViewWillEnter() {
    this.sugestoes = this.utils.getStorage('sugestoes');

    await this.buscarSaldo();

    if (this.usuario.cashback && this.usuario.cashback > 0) {
      this.usar_cashback = true;
      if (this.usuario.cashback >= this.total - this.frete) {
        this.cashback = this.total - this.frete;
        this.total = this.frete;
      } else {
        this.cashback = this.usuario.cashback;
        this.total -= this.cashback;
      }
    } else {
      this.usar_cashback = false;
    }

    this.inicializarPagina();
  }

  voltar() {
    this.utils.alteraStatusBar('claro');
    this.modalService.fecharModal();
  }

  calcularTotal() {

    this.valor_excessao = 0;
    this.carrinho.forEach(element => {
      if (element.precos != null) {

        let produto = null;

        element.precos.forEach(e => {
          if (e.minimo <= this.carrinho.reduce(
            (total: number, item: any) => total + ((item.categoriaId != 95) ? item.preco.total.valor : 0),
            0
          )) {
            if (this.carrinho.length > 1) {
              if (element.categoriaId == 95) {
                produto = ((e.preco.unitario != null) ? e.preco.unitario : e.preco.valor) * element.qtd;
                this.valor_excessao += (e.minimo > 0 ) ? produto : 0;
              }
            } else {
              produto = ((e.preco.unitario != null) ? e.preco.unitario : e.preco.valor) * element.qtd;
            }
          }
        });

        element.preco.total.valor = (produto == null) ? element.preco.total.valor : produto;
      }
    });

    this.utils.setStorage('carrinho', this.carrinho);

    this.carrinhoFilter = this.carrinho.filter(el => (el.categoriaId != 95)).length

    this.subtotal = this.carrinho.reduce(
      (total: number, item: any) => total + item.preco.total.valor,
      0
    );

    if (this.desconto.cupomId > 0 && this.desconto.tipo === 'porcentagem') {
      this.aplicarPorcentagem(
        this.desconto.porcentagem,
        this.desconto.codProduto,
        this.desconto.categoriaId
      );
    } else {
      this.carrinho.forEach((item) => {
        item.desconto.unitario.valor = item.preco.unitario.valor;
        item.desconto.total.valor = item.preco.total.valor;

        if (item.adicionais.length > 0) {
          item.adicionais.forEach((adicional: any) => {
            if (adicional.qtd > 0) {
              adicional.desconto.unitario.valor =
                adicional.preco.unitario.valor;
              adicional.desconto.total.valor = adicional.preco.total.valor;
            }
          });
        }
      });
    }

    this.descontoFrete = (this.loja.frete.valor * this.desconto.frete) / 100;

    let totalFrete = (this.frete =
      this.modalidade.codigo === 'DLV'
        ? this.loja.frete.valor -
        (this.loja.frete.valor * this.desconto.frete) / 100
        : 0);
    let subtotalCarrinho = this.subtotal - this.desconto.valor;

    if (subtotalCarrinho < 0) {
      subtotalCarrinho = 0;
    }

    let cashback = 0;
    if (this.cashback > 0) {
      if (
        this.usuario.cashback == this.cashback &&
        this.cashback < subtotalCarrinho
      ) {
        cashback = this.cashback;
      } else {
        if (this.usuario.cashback >= subtotalCarrinho) {
          cashback = this.cashback = subtotalCarrinho;
        } else {
          cashback = this.cashback = this.usuario.cashback;
        }
      }
    }

    this.total = subtotalCarrinho - cashback + totalFrete;
    this.total_sem_cashback = subtotalCarrinho + totalFrete;
  }

  filtroAdicionais(adicionais: Array<any>) {
    return adicionais.filter((item: any) => item.qtd > 0);
  }

  filtroOpcionais(opcionais: Array<any>) {
    return opcionais.filter((item: any) => item.selecionado);
  }

  filtroSugestoes(sugestoes: Array<any>) {
    return sugestoes.filter(
      (sugestao: any) =>
        this.carrinho.findIndex((item) => sugestao.cod === item.cod) === -1
    );
  }

  async selecionarProduto(produto: any) {
    this.utils.alteraStatusBar('claro');
    // await this.modalController.getTop();
    this.fechar();
    this.utils.setStorage('produto', produto);
    this.modalService.modalProdutoBrowser();
    this.modalController.dismiss('', 'sugestao', 'carrinho');
  }

  calcularCashback(event) {
    if (event) {
      if (this.usuario.cashback >= this.total - this.frete) {
        this.cashback = this.total - this.frete;
        this.total = this.frete;
      } else {
        this.cashback = this.usuario.cashback;
        this.total -= this.cashback;
      }
    } else {
      this.total += this.cashback;
      this.cashback = 0;
    }
  }

  editarProduto(index: number) {
    if (this.carrinho[index].tipo === 'Individual') {
      this.utils.setStorage('carrinho-index', index);
      this.modalService.modalEditarProduto().then(() => {
        this.carrinho = this.utils.getStorage('carrinho');
        this.calcularTotal();
      });
    }
  }

  async addItens() {
    this.utils.setStorage('modalidade', this.modalidade);
    this.utils.setStorage('loja', this.loja);
    this.utils.navegarPagina('cardapio');
    await this.modalService.fecharModal();
  }

  async limparCarrinho() {
    const event = {
      event: 'remove_from_cart',
      currency: 'BRL',
      value: this.dados_produto.preco.total.valor,
      ecommerce: {
        items: this.itemsEvent(),
      },
    };

    dataLayer.push({ ecommerce: null });
    dataLayer.push(event);

    this.carrinho = [];

    if (this.carrinho.length === 0) {
      this.utils.alteraStatusBar('claro');
      this.utils.removeStorage('sacola');
      this.utils.setStorage('sacola', false);
      this.utils.removeStorage('carrinho');
      this.utils.removeStorage('qtdCarrinho');
      this.utils.removeStorage('modalidade_carrinho');
      await this.modalService.fecharModal();
      this.utils.exibirToast('Seu carrinho está vazio!');
      this.events.publish('carrinho-numero', Date.now());
      this.events.publish('atualizar-tabs');
    }
  }

  async alterarProduto(tipo: string, i: number) {
    let produto = this.carrinho[i];

    if (tipo === 'add') {
      produto.qtd++;
    } else {
      if (produto.qtd === 1) {
        this.carrinho.splice(i, 1);

        let event = {
          event: 'remove_from_cart',
          currency: 'BRL',
          value: produto.preco.total.valor,
          ecommerce: {
            items: this.itemsRemove(produto),
          },
        };

        dataLayer.push({ ecommerce: null });
        dataLayer.push(event);


        if (this.carrinho.length === 0) {
          this.utils.alteraStatusBar('claro');
          this.utils.removeStorage('carrinho');
          this.utils.removeStorage('qtdCarrinho');
          this.utils.removeStorage('modalidade_carrinho');
          await this.modalService.fecharModal();
          this.events.publish('carrinho-numero', Date.now());
          this.events.publish('atualizar-tabs');
          this.utils.exibirToast('Seu carrinho está vazio!');
          return;
        } else {
          this.utils.exibirToast('Produto removido com sucesso do carrinho!');
        }

      }

      produto.qtd--;

    }

    let totalAdicionais = 0;

    const adicionais: Array<any> = produto.adicionais;

    if (adicionais.length > 0) {
      totalAdicionais = adicionais.reduce(
        (total, item) => total + item.preco.total.valor,
        0
      );
    }

    produto.preco.total.valor =
      produto.qtd * produto.preco.unitario.valor +
      produto.qtd * produto.preco.opcional.valor;

    if(this.cupom && this.utils.getStorage('cupomDesconto') != null){
      this.buscarDesconto();
    }

    this.calcularTotal();

    this.utils.setStorage('carrinho', this.carrinho);
  }

  async buscarDesconto() {
    if (this.cupom === '') {
      this.utils.exibirToast('O cupom é obrigatório!', 'erro');
      return;
    }

    const req = {
      cupom: this.cupom,
      lojaId: this.loja.id,
      carrinho: this.carrinho,
      modalidade: this.modalidade.codigo,
      plataforma: this.utils.getStorage('canal-compra') == "whatsapp" ? 'CHATBOT' : 'PWA'
    };

    this.loading = true;
    let couponIsValid = false;
    const res = await this.api.post('cupom', req);

    this.loading = false;

    if (res.status === 'success') {
      this.desconto = {
        cupom: this.cupom,
        cupomId: res.data.id,
        tipo: res.data.tipo,
        frete: res.data.frete,
        categoriaId: res.data.categoriaId,
        codProduto: res.data.codProduto,
      };


      if (this.desconto.frete > 0) {
        this.frete = (this.loja.frete.valor * this.desconto.frete) / 100
      }

      if (this.desconto.tipo === 'fixo') {
        this.desconto.valor = res.data.desconto.valor;
        this.desconto.porcentagem = 0;
      } else {
        this.desconto.porcentagem = res.data.desconto.valor;
      }
      this.utils.setStorage('cupomDesconto', this.cupom);
      this.opcaoRemover = true;
      this.calcularTotal();
      couponIsValid = true;
    } else {
      this.desconto.valor = 0;
      this.desconto.porcentagem = 0;
      this.desconto.frete = 0;
      this.opcaoRemover = false;
      this.utils.removeStorage('cupomDesconto');
      this.utils.exibirToast(res.message, 'erro');
      couponIsValid = false;
      this.calcularTotal();
    }

    dataLayer.push({
      event: 'addCoupon',
      coupon: this.cupom,
      itsValid: couponIsValid,
    });
  }

  removerDesconto() {
    this.utils.removeStorage('cupomDesconto');
    this.cupom = '';
    this.opcaoRemover = false;
    this.inicializarPagina();
  }

  aplicarPorcentagem(
    porcentagem: number,
    codProduto: string = null,
    categoriaId: number = 0
  ) {
    let totalDesconto: number = 0;

    this.carrinho.forEach((item) => {
      if (codProduto !== null && codProduto != '0') {
        if (item.cod !== codProduto) {
          return;
        }
      }

      if (categoriaId > 0) {
        if (item.categoriaId !== categoriaId) {
          return;
        }
      }

      item.desconto.unitario.valor =
        item.preco.unitario.valor -
        (item.preco.unitario.valor * porcentagem) / 100;
      item.desconto.total.valor =
        item.preco.total.valor - (item.preco.total.valor * porcentagem) / 100;

      if (item.adicionais.length > 0) {
        item.adicionais.forEach((adicional: any) => {
          if (adicional.qtd > 0) {
            adicional.desconto.unitario.valor =
              adicional.preco.unitario.valor -
              (adicional.preco.unitario.valor * porcentagem) / 100;
            adicional.desconto.total.valor =
              adicional.preco.total.valor -
              (adicional.preco.total.valor * porcentagem) / 100;
          }
        });
      }

      if (item.tipo === 'Combo') {
        this.aplicarPorcentagemCombo(
          item.produtos,
          porcentagem,
          codProduto,
          categoriaId
        );
      }

      totalDesconto +=
        item.preco.total.valor - Number(item.desconto.total.valor.toFixed(2));
    });

    this.desconto.valor = totalDesconto;
  }

  aplicarPorcentagemCombo(
    produtos: Array<any>,
    porcentagem: number,
    codProduto: string = null,
    categoriaId: number = 0
  ) {
    produtos.forEach((item) => {
      if (codProduto !== null && codProduto != '0') {
        if (item.cod !== codProduto) {
          return;
        }
      }

      if (categoriaId > 0) {
        if (item.categoriaId !== categoriaId) {
          return;
        }
      }

      item.desconto.unitario.valor =
        item.preco.unitario.valor -
        (item.preco.unitario.valor * porcentagem) / 100;
      item.desconto.total.valor =
        item.preco.total.valor - (item.preco.total.valor * porcentagem) / 100;

      if (item.adicionais.length > 0) {
        item.adicionais.forEach((adicional: any) => {
          if (adicional.qtd > 0) {
            adicional.desconto.unitario.valor =
              adicional.preco.unitario.valor -
              (adicional.preco.unitario.valor * porcentagem) / 100;
            adicional.desconto.total.valor =
              adicional.preco.total.valor -
              (adicional.preco.total.valor * porcentagem) / 100;
          }
        });
      }
    });
  }

  async buscarSaldo() {
    const res = await this.api.get('fidelidade_saldo', {
      jwt: this.utils.getStorage('usuario').jwt,
    });

    if (res.status == 'success') {
      this.usuario.cashback = res.data.valor;
      this.usuario.percentual_valor = res.data.percentual_valor;
      this.utils.setStorage('usuario', this.usuario);
      this.events.publish('atualizar-cashback');
    }
  }

  finalizar() {

    if (this.loja.valor_minimo?.valor > (this.subtotal - this.valor_excessao) && this.modalidade.codigo === 'DLV') {
      this.utils.exibirToast('O pedido mínimo para essa loja é de ' + this.loja.valor_minimo.texto, 'erro');
      return
    }

    this.utils.setStorage('cashback', this.cashback);
    this.utils.setStorage('carrinho', this.carrinho);
    this.utils.setStorage('desconto', this.desconto);
    this.utils.setStorage('modalidade_carrinho', this.modalidade);
    this.utils.navegarPagina('finalizar-pedido-browser');
    this.modalController.dismiss();

    let event = {
      event: 'begin_checkout',
      ecommerce: {
        currency: 'BRL',
        value: this.total,
        coupon: this.cupom,
        items: this.itemsEvent(),
      },
    };

    dataLayer.push({ ecommerce: null });
    dataLayer.push(event);
  }

  fechar() {
    this.events.publish('carrinho-numero', this.carrinho);
    this.modalController.dismiss();
  }
}
