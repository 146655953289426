import { Component, Input, OnInit } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { EventsService } from 'src/app/services/events.service';
import { ModalsService } from 'src/app/services/modals/modals.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-entrega-segment',
  templateUrl: './entrega-segment.component.html',
  styleUrls: ['./entrega-segment.component.scss'],
})
export class EntregaSegmentComponent implements OnInit {
  public modalidadeAtiva;
  public trocar_modalidade: boolean = false;

  constructor(
    private modalService: ModalsService,
    private utils: UtilsService,
    private events: EventsService,
    private alertCtrlr: AlertController,
  ) {
    this.events.subscribe('atualizar-segment', (modalidade) => {
      this.modalidadeAtiva = modalidade;
    });
  }

  ngOnInit() {
    this.modalidadeAtiva = this.utils.getStorage('modalidade')?.codigo;
  }

  async trocarModalidade(event) {
    if (this.trocar_modalidade) {
      this.trocar_modalidade = false;
      return
    }
    const loja = {};
    if (event.target != undefined) {
      event = event.target.value;
    }
    this.utils.setStorage('modalidade', { codigo: event });
    this.modalidadeAtiva = event;

    if (event == 'DLV') {
      if (this.utils.getStorage('carrinho')) {
        const alert = await this.alertCtrlr.create({
          header: 'Trocar modalidade',
          message:
            'Trocar a modalidade irá limpar os itens do seu carrinho. Deseja continuar?',
          buttons: [
            {
              text: 'Não',
              role: 'cancel',
              handler: async () => {
                this.trocar_modalidade = true;
                this.utils.setStorage('modalidade', { codigo: 'TTA' });
                this.modalidadeAtiva = 'TTA';
                this.events.publish('atualizar-loja-modalidade');
  
              },
            },
            {
              text: 'Sim',
              handler: async () => {
                this.events.publish('atualizar-cardapio', this.modalidadeAtiva);
                this.events.publish('atualizar-loja-modalidade');
                this.events.publish('limpar-carrinho');
                this.events.publish('carrinho-numero', Date.now());
  
              },
            },
          ],
        });
  
        return await alert.present();
      } else {
        this.events.publish('atualizar-cardapio', this.modalidadeAtiva);
        this.events.publish('atualizar-loja-modalidade');
      }
   
    } else {
      this.modalService.modalRetirarPedido(loja).then(async (res) => {
        if (res.data?.confirmar == null) {
          this.trocar_modalidade = true
          this.utils.setStorage('modalidade', { codigo: 'DLV' });
          this.modalidadeAtiva = 'DLV';
          this.events.publish('atualizar-loja-modalidade');
        }
      });
    }
  }
}
