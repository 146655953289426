import { ApiService } from './../../services/api.service';
import { UtilsService } from 'src/app/services/utils.service';
import { ModalController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-selecionar-modalidade',
  templateUrl: './selecionar-modalidade.page.html',
  styleUrls: ['./selecionar-modalidade.page.scss'],
})
export class SelecionarModalidadePage implements OnInit {
  public selected_modalidade = this.utils.getStorage('modalidade').codigo
  public modalidade:any;
  public usuario: any = {};
  public loja: any;

  constructor(
    private modalController: ModalController,
    private utils: UtilsService,
    private api: ApiService
    ) { }

  ngOnInit() {
    this.modalidade = 1
    this.usuario = this.utils.getStorage('usuario');
    this.loja = this.utils.getStorage('loja');
  }

  voltar(){
    this.modalController.dismiss();
  }

  async changeModalidade(codigo){

    const req = {
      jwt: this.usuario?.jwt,
      lojaId: this.loja.id,
      marcaId: this.loja.marcaId,
      modalidadeId: codigo
    };


    const res = await this.api.get('trocar_modalidade', req);

    if (res.status == 'success') {
        this.utils.removeStorage('modalidade');
        this.utils.removeStorage('modalidade_carrinho');
        this.utils.setStorage('modalidade',{codigo:codigo})
        this.utils.setStorage('modalidade_carrinho',{codigo:codigo})
        await this.modalController.dismiss();
        this.modalController.dismiss('', '', 'modal-confirma-modalidade');
        this.utils.voltarPagina('cardapio');
        await this.modalController.getTop();
    }else{
      this.utils.exibirToast('Modalidade não atendido no restaurante.', 'erro');
    }


  }

}
