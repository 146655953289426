// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { Loader } from "@googlemaps/js-api-loader"

export const environment = {
  production: false,
  apiUrl: 'http://localhost:8100/',
  clientId:
    '907434901144-df44qo0t0kdas1dmpogil8g92uo06qgp.apps.googleusercontent.com',
  firebase: {
    apiKey: 'AIzaSyATTgQQoMJpJikanCr3ilcgCHguZ-0SBY8',
    authDomain: 'chinainboxprod-d0890.firebaseapp.com',
    databaseURL: 'https://chinainboxprod-d0890.firebaseio.com/',
    projectId: 'chinainboxprod-d0890',
    storageBucket: 'chinainboxprod-d0890.appspot.com',
    messagingSenderId: '907434901144',
    appId: '1:907434901144:web:746381b5f377f56fa39aed',
    measurementId: 'G-0F7GEMWNE4',
  },
};

export const loader = new Loader({
  apiKey: "AIzaSyCUWuAs2yb7KlIvMFo_kj4IZBapLzTY_6A",
  version: "weekly",
  language: "pt-BR",
  libraries: ["places"]
});

// export const API_URL: string = 'http://cib.local/api/';
// export const API_URL: string = "https://cib.mac/api/";
export const API_URL: string = 'https://cib.alphacode.mobi/api/';
// export const API_URL: string = "https://cib.alphacode.com.br/api/";

export const PASSPHRASE_AUTH =
  'oUi0Z>,.eat?bM&[+@9zN%2M)Tp0ab)gAYTJk*w*,ZngB:H$!EH(TciZfjy@Zzod';

// export const MERCADOPAGO_TOKEN = "TEST-c231b171-81a7-427c-b8cb-4b99fc38905b";
export const MERCADOPAGO_TOKEN = 'APP_USR-a8fd7ffc-bb68-40ca-a27e-9d88bc7762e7';
export const SOFTWARE_VERSION = '1.1.13';

export const ENCRYPT = false;

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
