import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalController, Platform } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';
import { EventsService } from 'src/app/services/events.service';
import { ModalsService } from 'src/app/services/modals/modals.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-confirma-retirada-loja-broswer',
  templateUrl: './confirma-retirada-loja-broswer.page.html',
  styleUrls: ['./confirma-retirada-loja-broswer.page.scss'],
})
export class ConfirmaRetiradaLojaBroswerPage implements OnInit {


  public marker: any;
  public reload: boolean = false;
  public isMapa: boolean = false;
  public loading: boolean = false;
  public notFound: boolean = false;
  public loja: any;
  public localizacaoAtual: any;
  public modalidadeAtiva: string;

  @ViewChild('map1', { read: ElementRef, static: false }) mapRef: ElementRef;


  constructor(
    private modalService: ModalsService,
    private modalController: ModalController,
    private platform: Platform,
    private utils: UtilsService,
    private api: ApiService,
    private change: ChangeDetectorRef,
    private route: ActivatedRoute,
    private events: EventsService,
  ) {

  }

  ngOnInit() {
    this.carregarMapa();
    this.modalidadeAtiva = this.utils.getStorage('modalidade')?.codigo;

  }

  backModal() {
    this.modalService.modalRetirarPedidoBrowser(this.loja)
    this.modalController.dismiss()
  }

  confirmarLoja() {
    this.utils.setStorage('loja', this.loja);

    this.events.publish('atualizar-cardapio',this.modalidadeAtiva);
    this.events.publish('atualizar-loja-modalidade');
    this.modalController.dismiss()

  }

  async carregarMapa() {

    this.platform.ready().then(async () => {
      const location = new google.maps.LatLng(this.localizacaoAtual.latitude, this.localizacaoAtual.longitude);

      const options = {
        center: location,
        zoom: 15,
        draggable: false,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        disableDefaultUI: true
      }

      let mapaMobile = new google.maps.Map(this.mapRef.nativeElement, options);

      const markerClient = new google.maps.Marker({
        position: location,
        icon: 'assets/marker.svg',
        draggable: false,
        map: mapaMobile,
      });

      let localizacaoLoja = {
        lat: +this.loja.latitude,
        lng: +this.loja.longitude
      }

      const markerStore = new google.maps.Marker({
        position: localizacaoLoja,
        draggable: false,
        map: mapaMobile,
        
      });

      const infoWindowClient = new google.maps.InfoWindow({
        content: 'Você está aqui',
        disableAutoPan: true,

      });

      const infoWindowStore = new google.maps.InfoWindow({
        content: this.loja.nome,
        disableAutoPan: true
      });

      google.maps.event.addListener(infoWindowClient, 'closeclick', () => {
        infoWindowClient.open(mapaMobile, markerClient);
      });

      google.maps.event.addListener(markerStore, 'closeclick', () => {
        infoWindowStore.open(mapaMobile, markerStore);
      });

      infoWindowClient.open(mapaMobile, markerClient);
      infoWindowClient.setOptions()
      infoWindowStore.open(mapaMobile, markerStore);

    })

  }
}
