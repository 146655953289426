import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UtilsService } from 'src/app/services/utils.service';

declare let dataLayer: any;
@Component({
  selector: 'app-confirma-pedido-browser',
  templateUrl: './confirma-pedido-browser.page.html',
  styleUrls: ['./confirma-pedido-browser.page.scss'],
})
export class ConfirmaPedidoBrowserPage implements OnInit {
  public rede_logo;
  public pedido: any;
  
  constructor(
    private modalController: ModalController,
    private utils: UtilsService,
  ) { }

  ngOnInit() {
    this.rede_logo = this.utils.getStorage('loja').icone;

    dataLayer.push({ ecommerce: null });
    dataLayer.push({ event: 'modal_viewed_confirmation' });
  }

  fechar() {
    this.modalController.dismiss();

    dataLayer.push({ ecommerce: null });
    dataLayer.push({
      'event': 'order_decision_confirmation',
      'decision': 'Não'
    });
  }

  verPedidos() {
    this.modalController.dismiss();
    this.utils.navegarPaginaRoot('/tabs/historico');
  }

  confirmarPedido() {
    this.modalController.dismiss({ confirmar: true })

    dataLayer.push({ ecommerce: null });
    dataLayer.push({
      'event': 'order_decision_confirmation',
      'decision': 'Sim'
    });
  }
}
