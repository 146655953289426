import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-selecionar-modalidade-browser',
  templateUrl: './selecionar-modalidade-browser.page.html',
  styleUrls: ['./selecionar-modalidade-browser.page.scss'],
})
export class SelecionarModalidadeBrowserPage implements OnInit {
  public modalidade:any

  constructor(
    private modalController: ModalController,
    private utils: UtilsService
  ) { }

  ngOnInit() {
    this.modalidade=1;
  }

  async fechar(){
    await this.modalController.dismiss();
  }

  async changeModalidade(codigo){
    this.utils.removeStorage('modalidade');
    this.utils.setStorage('modalidade',{codigo:codigo})
    await this.modalController.dismiss();
    this.modalController.dismiss('', '', 'modal-confirma-modalidade');
    this.utils.voltarPagina('cardapio');
    await this.modalController.getTop();
  }

}
