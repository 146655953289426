import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IonRouterOutlet } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';
import { EventsService } from 'src/app/services/events.service';
import { ModalsService } from 'src/app/services/modals/modals.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-dados-pessoais-web',
  templateUrl: './dados-pessoais-web.component.html',
  styleUrls: ['./dados-pessoais-web.component.scss'],
})
export class DadosPessoaisWebComponent implements OnInit {
  // DADOS PESSOAIS **
  public formulario: FormGroup;
  public controladores: Object;
  public usuario: any;
  public alterarSenha: boolean = false;
  public loading: boolean = false;

  constructor(
    // DADOS PESSOAIS **
    private formBuilder: FormBuilder,
    private api: ApiService,
    private routerOutlet: IonRouterOutlet,
    private events: EventsService,
    private modalService: ModalsService,
    private utils: UtilsService
  ) {
    // DADOS PESSOAIS **
    this.usuario = this.utils.getStorage('usuario');

    this.formulario = this.formBuilder.group({
      nome: [this.usuario?.nome, Validators.required],
      cpf: [this.usuario?.cpf, Validators.required],
      celular: [this.usuario?.celular, Validators.required],
      data_nascimento: [this.usuario?.dataNascimento],
      email: [this.usuario?.email, [Validators.required, Validators.email]],
      senha: ['', [Validators.minLength(6)]],
    });

    this.controladores = {
      nome: 'nome',
      celular: 'celular',
      cpf: 'CPF',
      email: 'e-mail',
      data_nascimento: 'data de nascimento',
      senha: 'senha',
    };
  }

  ngOnInit() { }

  // DADOS PESSOAIS **
  abrirAlterarSenha() {
    this.modalService.modalAlterarSenhaWeb();
  }

  async salvar() {
    if (this.utils.validaForm(this.formulario, this.controladores)) {
      if (!this.utils.validaCelular(this.formulario.get('celular').value)) {
        this.loading = false;
        this.utils.exibirToast('O número de celular é inválido!', 'erro');
        return;
      }

      const dataAtual = new Date().getTime();
      const dataNascimento = this.formulario
        .get('data_nascimento')
        .value.split('/');
      const timeDataNascimento = new Date(
        dataNascimento[1] + '/' + dataNascimento[0] + '/' + dataNascimento[2]
      ).getTime();

      if (timeDataNascimento > dataAtual) {
        this.utils.exibirToast('Data de nascimento inválida!', 'erro');
        return;
      }

      if (this.formulario.get('cpf').value !== '') {
        if (!this.utils.validaCPF(this.formulario.get('cpf').value)) {
          this.loading = false;
          this.utils.exibirToast('CPF inválido!', 'erro');
          return;
        }
      }

      const req = {
        jwt: this.usuario.jwt,
        nome: this.formulario.get('nome').value,
        celular: this.formulario.get('celular').value,
        cpf: this.formulario.get('cpf').value,
        dataNascimento: this.formulario.get('data_nascimento').value,
        email: this.formulario.get('email').value,
        senha: this.formulario.get('senha').value,
      };

      this.loading = true;

      const res = await this.api.put('cliente', req);

      this.loading = false;

      if (res.status === 'success') {
        this.usuario = res.data;
        this.utils.setStorage('usuario', this.usuario);

        this.formulario.patchValue({ senha: '' });
        this.alterarSenha = false;

        this.utils.exibirToast(res.message);

        if (this.utils.getStorage('cadastro_cpf') != null) {
          this.utils.removeStorage('cadastro_cpf');
        }
      } else {
        this.utils.exibirToast(res.message, 'erro');
      }
    }
  }

  inativarConta() {
    this.modalService.modalInativarContaWeb();
  }

  excluirConta() {
    this.modalService.modalExcluirContaWeb();
  }
}
