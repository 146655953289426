import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-efetuar-pagamento-pix',
  templateUrl: './efetuar-pagamento-pix.component.html',
  styleUrls: ['./efetuar-pagamento-pix.component.scss'],
})
export class EfetuarPagamentoPixComponent implements OnInit {
  @Input() pedido: any;

  public buttonLabel: string = 'Copiar código Pix';
  public buttonIcon: string = 'documents-outline';

  constructor() {}

  ngOnInit() {}

  copyCode() {
    const input = document.createElement('input');
    input.value = this.pedido.codigoPix;
    document.body.appendChild(input);
    input.select();

    try {
      const copyed = document.execCommand('copy');
      document.body.removeChild(input);
      this.buttonLabel = 'Código PIX copiado';
      this.buttonIcon = 'checkbox-outline';
      return copyed;
    } catch (err) {
      this.buttonLabel = 'Erro ao copiar o código';
      this.buttonIcon = 'alert-circle-outline';
      document.body.removeChild(input);
      return false;
    }
  }
}
