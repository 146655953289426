import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UtilsService } from 'src/app/services/utils.service';
import { ModalsService } from 'src/app/services/modals/modals.service';

@Component({
  selector: 'app-confirmar-loja-refazer-pedido-browser',
  templateUrl: './confirmar-loja-refazer-pedido-browser.page.html',
  styleUrls: ['./confirmar-loja-refazer-pedido-browser.page.scss'],
})
export class ConfirmarLojaRefazerPedidoBrowserPage implements OnInit {
  public rede_logo;
  @Input() pedido: any;

  constructor(
    private modalController: ModalController,
    private utils: UtilsService,
    private modals: ModalsService
  ) { }

  ngOnInit() {
    this.rede_logo = this.utils.getStorage('loja').icone;
  }

  fechar() {
    this.modals.fecharModal();
  }

  confirmarPedido() {
    this.modalController.dismiss('sim');
  }

}
