import { UtilsService } from 'src/app/services/utils.service';
import { Component, OnInit } from '@angular/core';
import { EventsService } from 'src/app/services/events.service';
import { ModalsService } from 'src/app/services/modals/modals.service';

@Component({
  selector: 'app-produto',
  templateUrl: './produto.page.html',
  styleUrls: ['./produto.page.scss'],
})
export class ProdutoPage implements OnInit {
  public produto: any;
  public cor: string;
  public loja: any;

  constructor(
    private utils: UtilsService,
    private modalService: ModalsService,
    private events: EventsService
  ) {}

  ngOnInit() {
    this.loja = this.utils.getStorage('loja');
    this.produto = this.utils.getStorage('produto');
    this.produto.qtd = 1;
    this.produto.preco.unitario =
      this.produto.preco_obs === 1 ? 0 : this.produto.preco.valor;
    this.produto.preco.valor_obrigatorio = 0;
    }

  voltar() {
    this.modalService.fecharModal();
  }
  deselecionarItem(i, index) {
    this.produto.observacoes[index].opcionais.forEach((element) => {
      element.selecionado = false;
    });
    this.produto.observacoes[index].opcionais[i].selecionado = true;
  }
  alteraProduto(tipo: string) {
    if (tipo === 'add') {
      this.produto.preco.valor_obrigatorio = this.produto.preco.valor_obrigatorio / this.produto.qtd;
      this.produto.qtd++;
    } else {
      if (this.produto.qtd === 1) {
        return;
      }
      this.produto.preco.valor_obrigatorio = this.produto.preco.valor_obrigatorio / this.produto.qtd;
      this.produto.qtd--;
    }

    let totalAdicionais = 0;
    this.produto.observacoes?.forEach((observacoes: any) => {
      if (observacoes.tipo_opcional == 'Adicional') {
        observacoes.adicionais.forEach((item) => {
          totalAdicionais += item.qtd * item.preco.valor;
        });
      }
    });
    this.produto.preco.valor_obrigatorio = this.produto.qtd * this.produto.preco.valor_obrigatorio;

    this.produto.preco.valor =
    (this.produto.qtd * this.produto.preco.unitario) +
    this.produto.preco.valor_obrigatorio +
    (this.produto.qtd * totalAdicionais);
    this.produto.preco.texto = this.utils.floatToReal(this.produto.preco.valor);
  }

  alteraAdicional(tipo: string, i: number, index: number) {
    let observacoes = this.produto.observacoes[index];
    let qtdSelecionada = 0;
    if (tipo === 'add') {
      observacoes.adicionais.forEach((adicional) => {
        qtdSelecionada += adicional.qtd;
      });

      if (observacoes.max < qtdSelecionada + 1) {
        this.utils.exibirToast(
          'A quantidade máxima para essa opção já foi atingida.'
        );
        return;
      }

      this.produto.observacoes[index].adicionais[i].qtd++;
    } else {
      if (this.produto.observacoes[index].adicionais[i].qtd === 0) {
        return;
      }

      this.produto.observacoes[index].adicionais[i].qtd--;
    }

    let totalAdicionais = 0;

    this.produto.observacoes.forEach((element) => {
      element.adicionais.forEach((item: any) => {
        totalAdicionais += item.qtd * item.preco.valor;
      });
    });

    this.produto.preco.valor =
    (this.produto.qtd * this.produto.preco.unitario) +
    this.produto.preco.valor_obrigatorio +
    (this.produto.qtd * totalAdicionais);
    this.produto.preco.texto = this.utils.floatToReal(this.produto.preco.valor);
  }

  async addSacola() {
    if (this.utils.getStorage('usuario') == null) {
      this.utils.exibirToast(
        'Faça o login para adicionar um produto no carrinho!',
        'erro'
      );
      this.utils.setStorage('pagina_anterior', 'cardapio');
      this.utils.navegarPaginaRoot('login');
      return;
    }

    const loja = this.utils.getStorage('loja');
    const modalidade = this.utils.getStorage('modalidade');
    const modalidade_carrinho = this.utils.getStorage('modalidade_carrinho');

    if (!loja.statusLoja) {
      this.utils.exibirToast('Restaurante fechado no momento!', 'erro');
      return;
    }

    if (this.utils.getStorage('carrinho') != null) {
      if (
        loja.id != this.utils.getStorage('lojaId') ||
        loja.marcaId != this.utils.getStorage('loja_carrinho').marcaId
      ) {
        this.modalService.modalLimparSacola().then((res) => {
          if (res.data === 'sim') {
            this.utils.setStorage('lojaId', loja.id);
            this.utils.setStorage('loja_carrinho', loja);
            this.utils.setStorage(
              'modalidade_carrinho',
              this.utils.getStorage('modalidade')
            );
            this.addSacola();
          }
        });
        return;
      }
    }

    let carrinho: Array<any> = [];

    if (this.utils.getStorage('carrinho') !== null) {
      carrinho = this.utils.getStorage('carrinho');
    }

    let adicionais = [];
    let opcionais = [];
    let obrigatorios = [];
    let opcionais_gerais = [];
    let erros = 0;

    if (this.produto.observacoes != undefined) {
      this.produto.observacoes.forEach((observacoes: any, index) => {
        switch (observacoes.tipo_opcional) {
          case 'Adicional':
            let adicionalSelecionado = 0;

            observacoes.adicionais.forEach((adicional: any) => {
              adicionalSelecionado += adicional.qtd;

              adicionais.push({
                cod: adicional.cod,
                nome: adicional.opcional,
                preco: {
                  unitario: {
                    valor: adicional.preco.unitario,
                    texto: this.utils.floatToReal(adicional.preco.unitario),
                  },
                  total: {
                    valor: adicional.preco.unitario * adicional.qtd,
                    texto: this.utils.floatToReal(
                      adicional.preco.unitario * adicional.qtd
                    ),
                  },
                },
                desconto: {
                  unitario: {
                    valor: adicional.preco.unitario,
                    texto: this.utils.floatToReal(adicional.preco.unitario),
                  },
                  total: {
                    valor: adicional.preco.unitario * adicional.qtd,
                    texto: this.utils.floatToReal(
                      adicional.preco.unitario * adicional.qtd
                    ),
                  },
                },
                qtd: adicional.qtd,
                sku: adicional.sku,
              });

              opcionais_gerais.push({
                cod: adicional.cod,
                nome: adicional.opcional,
                tipo: 'adicional',
                categoriaId: observacoes.id_categoria,
                preco: {
                  unitario: {
                    valor: adicional.preco.unitario,
                    texto: this.utils.floatToReal(adicional.preco.unitario),
                  },
                  total: {
                    valor: adicional.preco.unitario * adicional.qtd,
                    texto: this.utils.floatToReal(
                      adicional.preco.unitario * adicional.qtd
                    ),
                  },
                },
                desconto: {
                  unitario: {
                    valor: adicional.preco.unitario,
                    texto: this.utils.floatToReal(adicional.preco.unitario),
                  },
                  total: {
                    valor: adicional.preco.unitario * adicional.qtd,
                    texto: this.utils.floatToReal(
                      adicional.preco.unitario * adicional.qtd
                    ),
                  },
                },
                qtd: adicional.qtd,
                sku: adicional.sku,
              });
            });
            if (adicionalSelecionado < observacoes.min) {
              this.produto.observacoes[index].sem_escolha = 'ativo';
              erros++;
            } else {
              this.produto.observacoes[index].sem_escolha = '';
            }
            break;

          case 'Opcional':
            let opcionalSelecionado = 0;

            if (observacoes.opcionais.length > 0) {
              observacoes.opcionais.forEach((item) => {
                if (item.selecionado) {
                  opcionalSelecionado++;
                }

                opcionais.push({
                  cod: item.cod,
                  opcional: item.opcional,
                  selecionado: item.selecionado,
                  obrigatorio: true,
                  sku: item.sku,
                });

                opcionais_gerais.push({
                  cod: item.cod,
                  opcional: item.opcional,
                  tipo: 'opcional',
                  categoriaId: observacoes.id_categoria,
                  selecionado: item.selecionado,
                  obrigatorio: true,
                  sku: item.sku,
                });
              });

              if (opcionalSelecionado == 0) {
                this.produto.observacoes[index].sem_escolha = 'ativo';
                erros++;
              } else {
                this.produto.observacoes[index].sem_escolha = '';
              }
            }
            break;

          case 'Obrigatório':
            let obrigatorioSelecionado = 0;

            if (observacoes.obrigatorios.length > 0) {
              observacoes.obrigatorios.forEach((item) => {
                if (item.selecionado) {
                  obrigatorioSelecionado++;
                }

                obrigatorios.push({
                  cod: item.cod,
                  opcional: item.opcional,
                  selecionado: item.selecionado,
                  obrigatorio: true,
                  preco: {
                    unitario: {
                      valor: item.preco.unitario,
                      texto: this.utils.floatToReal(item.preco.unitario),
                    },
                    total: {
                      valor: item.preco.unitario,
                      texto: this.utils.floatToReal(item.preco.unitario),
                    },
                  },
                  sku: item.sku,
                });

                opcionais_gerais.push({
                  cod: item.cod,
                  opcional: item.opcional,
                  tipo: 'obrigatorio',
                  categoriaId: observacoes.id_categoria,
                  selecionado: item.selecionado,
                  obrigatorio: true,
                  preco: {
                    unitario: {
                      valor: item.preco.unitario,
                      texto: this.utils.floatToReal(item.preco.unitario),
                    },
                    total: {
                      valor: item.preco.unitario,
                      texto: this.utils.floatToReal(item.preco.unitario),
                    },
                  },
                  sku: item.sku,
                });
              });

              if (obrigatorioSelecionado == 0) {
                this.produto.observacoes[index].sem_escolha = 'ativo';
                erros++;
              } else {
                this.produto.observacoes[index].sem_escolha = '';
              }
            }
            break;

          default:
            break;
        }
      });
    }

    if (erros != 0) {
      this.utils.exibirToast('Por favor preencha todos os campos.');
      return;
    }

    const produto = {
      sku: this.produto.sku,
      tipo: 'Individual',
      categoriaId: this.produto.categoriaId,
      cardapioId: this.produto.cardapioId,
      cod: this.produto.cod,
      imagem: this.produto.imagem,
      nome: this.produto.produto,
      descricao: this.produto.descricao,
      alt_produto: this.produto.alt_produto,
      observacao: this.produto.observacao,
      preco: {
        unitario: {
          valor: this.produto.preco.unitario,
          texto: this.utils.floatToReal(this.produto.preco.unitario),
        },
        opcional: {
          valor:
            (this.produto.preco.valor -
              this.produto.preco.unitario * this.produto.qtd) /
            this.produto.qtd,
          texto: this.utils.floatToReal(
            (this.produto.preco.valor -
              this.produto.preco.unitario * this.produto.qtd) /
              this.produto.qtd
          ),
        },
        total: {
          valor: this.produto.preco.valor,
          texto: this.utils.floatToReal(this.produto.preco.valor),
        },
      },
      desconto: {
        unitario: {
          valor: this.produto.preco.unitario,
          texto: this.utils.floatToReal(this.produto.preco.unitario),
        },
        total: {
          valor: this.produto.preco.valor,
          texto: this.utils.floatToReal(this.produto.preco.valor),
        },
      },
      qtd: this.produto.qtd,
      adicionais: adicionais,
      obrigatorios: obrigatorios,
      opcionais: opcionais,
      opcionais_gerais: opcionais_gerais,
    };

    carrinho.push(produto);

    // this.utils.setStorage('sugestoes', this.utils.getStorage('sugestoes-loja'));
    this.utils.setStorage('lojaId', loja.id);
    this.utils.setStorage('loja_carrinho', loja);
    this.utils.setStorage(
      'modalidade_carrinho',
      this.utils.getStorage('modalidade')
    );
    this.utils.setStorage('carrinho', carrinho);
    this.events.publish('atualizar-tabs');
    this.events.publish('carrinho-numero');

    if (this.utils.getStorage('usuario') == null) {
      this.utils.exibirToast(
        'Faça o login para adicionar um produto no carrinho!',
        'erro'
      );
      this.utils.setStorage('pagina_anterior', 'cardapio');
      this.utils.navegarPaginaRoot('login');
      return;
    }

    this.modalService.fecharModal();
    this.utils.exibirToast('Produto adicionado com sucesso!');

    this.events.publish('fechar-carrinho');
  }

  async selecionarObservacao(item, indexOpc, indexObs, tipo) {
    let regra = this.produto.observacoes[indexObs].regra;
    let itensObservacoes;

    switch (tipo) {
      case 'obrigatorio':
        itensObservacoes = this.produto.observacoes[indexObs].obrigatorios;
        break;

      case 'opcional':
        itensObservacoes = this.produto.observacoes[indexObs].opcionais;
        break;
      default:
        break;
    }

    let qtdSelecionado = 0;
    itensObservacoes.forEach((element) => {
      if (element.selecionado) {
        qtdSelecionado++;
      }
    });

    setTimeout(() => {
      if (qtdSelecionado > regra && regra > 1) {
        item.selecionado = false;
        this.utils.exibirToast(
          'A quantidade máxima para essa opção já foi atingida'
        );
      } else {
        if (this.produto.observacoes[indexObs].tipo_preco == 'preco') {
          let totalAdicionais = 0;

          this.produto.observacoes.forEach((element) => {
            element.adicionais.forEach((item: any) => {
              totalAdicionais += item.qtd * item.preco.valor;
            });
          });

          if (tipo == 'obrigatorio' && regra == 1 && qtdSelecionado >= regra) {
           this.produto.preco.valor -= this.produto.observacoes[indexObs].preco_escolha * this.produto.qtd;
            this.produto.preco.valor_obrigatorio = this.produto.preco.valor - (this.produto.qtd * totalAdicionais);
            this.produto.preco.texto = this.utils.floatToReal(
              this.produto.preco.valor
            );
            itensObservacoes.forEach((element, i) => {
              if (i != indexOpc) {
                if (element.selecionado) {
                  element.selecionado = false;
                }
              }

              if (i == indexOpc) {
                if (!element.selecionado) {
                  element.selecionado = false;
                } else {
                  this.produto.preco.valor += item.preco.valor * this.produto.qtd;
                  this.produto.preco.valor_obrigatorio = this.produto.preco.valor - (this.produto.qtd * totalAdicionais);
                  this.produto.preco.texto = this.utils.floatToReal(
                    this.produto.preco.valor
                  );
                  this.produto.observacoes[indexObs].preco_escolha = item.preco.valor;

                  element.selecionado = true;
                }
              }
            });
          } else if (item.selecionado) {
            this.produto.preco.valor += item.preco.valor * this.produto.qtd;
            this.produto.preco.valor_obrigatorio = this.produto.preco.valor - (this.produto.qtd * totalAdicionais);
            this.produto.preco.texto = this.utils.floatToReal(
              this.produto.preco.valor
            );

            this.produto.observacoes[indexObs].preco_escolha = item.preco.valor;

          } else {
            this.produto.preco.valor -= item.preco.valor * this.produto.qtd;
            this.produto.preco.valor_obrigatorio = this.produto.preco.valor - (this.produto.qtd * totalAdicionais);

            this.produto.preco.texto = this.utils.floatToReal(
              this.produto.preco.valor
            );

            this.produto.observacoes[indexObs].preco_escolha - item.preco.valor;
          }
        }
      }
    }, 10);
  }
}
