import { Component, OnInit } from '@angular/core';
import { EventsService } from 'src/app/services/events.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-footer-web',
  templateUrl: './footer-web.component.html',
  styleUrls: ['./footer-web.component.scss'],
})
export class FooterWebComponent implements OnInit {
  public tabAtual;

  constructor(
    private utils: UtilsService,
    private events: EventsService
  ) {}

  ngOnInit() {}

  link(link: string) {
    this.utils.link(link);
  }

  irPara(pagina) {
    if (
      this.utils.getStorage('semLogin') &&
      pagina != '/tabs/perfil' &&
      pagina != '/tabs/home'
    ) {
      this.utils.removeStorage('endereco_confirmado');
      this.utils.exibirToast('Faça login para continuar!', 'erro');
      this.utils.navegarPaginaRoot('login');
      return;
    } else {
      this.utils.navegarPaginaRoot(pagina);
      this.tabAtual = pagina;
      this.events.publish('change-tab', this.tabAtual);
    }
  }

  neoAssist() {
    this.utils.navegarPaginaRoot('fale-conosco');
  }
}
