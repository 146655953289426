import { Component, OnInit, ViewChild } from '@angular/core';
import { IonInfiniteScroll, ModalController } from '@ionic/angular';
import { Endereco } from 'src/app/interfaces/endereco';
import { ApiService } from 'src/app/services/api.service';
import { MapsService } from 'src/app/services/maps.service';
import { ModalsService } from 'src/app/services/modals/modals.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-retirada-pedido-browser',
  templateUrl: './retirada-pedido-browser.page.html',
  styleUrls: ['./retirada-pedido-browser.page.scss'],
})
export class RetiradaPedidoBrowserPage implements OnInit {
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;

  public lojas: any;
  public loja: any;
  public localizacao: any;
  public isLocalizacao: boolean;
  public infoLocalizacao: any;
  public infoEndereco: Endereco;
  public localizacaoAtual: any;
  public localizacao_atual: any;

  public isLocalizacaoAtual: boolean = false;
  public pagina: number = 1;
  public loading: boolean;

  constructor(
    private modalController: ModalController,
    private modalService: ModalsService,
    private mapsService: MapsService,
    private api: ApiService,
    private utils: UtilsService,
  ) { 
  }

  async ngOnInit() {
    this.infoEndereco = this.utils.getStorage('endereco');    
    this.getLocation();
  }

  abrirLoja(loja) {
    this.modalService.modalRetiradaLojaBrowser(this.localizacaoAtual, loja);
    this.modalController.dismiss({ confirmar: true })

  }

  closeModal() {
    this.modalController.dismiss()
  }

  async buscarLojas(localizacao, isLocalizacaoAtual: boolean = false) {

    this.loading = true;

    this.isLocalizacaoAtual = isLocalizacaoAtual; 

    const req = {
      latitude: localizacao ? localizacao.lat : ((this.localizacao_atual != null) ? this.localizacao_atual.lat : ''),
      longitude: localizacao ? localizacao.lng : ((this.localizacao_atual != null) ? this.localizacao_atual.lng : ''),
      start: isLocalizacaoAtual ? (this.pagina = 1) : this.pagina,
      limit: 30,
    }

    this.localizacaoAtual = req

    const res = await this.api.get('pedido_retirada', req);
     
    this.loading = false
    if (res.status === 'success') {
      this.lojas = res.data;
      
    } else {
      this.lojas = null;
    }

  }

  async buscarPaginacao(localizacao) {
    const req = {
      latitude: localizacao ? localizacao.lat : '',
      longitude: localizacao ? localizacao.lng : '',
      start: this.pagina,
      limit: 30,
    };

    const res = await this.api.get('pedido_retirada', req);

    if (res.status === 'success') {
      res.data.forEach((item: any) => {
        this.lojas.push(item);
      });

      this.pagina++;
    } else {
      this.infiniteScroll.disabled = true;
    }
  }

  buscar(event: any) {
    setTimeout(async () => {
      if (this.isLocalizacaoAtual) {
        await this.buscarPaginacao(this.infoLocalizacao);
      } else if (this.utils.getStorage('usuario')) {
        await this.buscarPaginacao(this.infoEndereco);
      } else {
        await this.buscarPaginacao('');
      }
      
      event.target.complete();
    }, 500);
  }

  async getLocation() {
    this.loading = true;
    this.utils.getLocationService().then(async resp => {
      this.localizacao = resp;
      const req = {
        lat: this.localizacao.lat,
        lng: this.localizacao.lng
      };

      const res = await this.api.get('geocode', req);
      if (res.status === 'success') {
        // this.infoLocalizacao = res.data;

        this.localizacao_atual = res.data;
        // this.isLocalizacao = true;

        if (this.infoEndereco == null) {
          this.buscarLojas(this.localizacao_atual);
        }else{
          this.buscarLojas(this.infoEndereco);
        }

        // this.utils.setStorage('endereco', this.infoLocalizacao);
      } else {
        this.buscarLojas(this.infoEndereco);

      }
    })
  }
}
