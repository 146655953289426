import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { LoadingController, ModalController, Platform } from '@ionic/angular';
import { Endereco } from 'src/app/interfaces/endereco';
import { ApiService } from 'src/app/services/api.service';
import { EventsService } from 'src/app/services/events.service';
import { ModalsService } from 'src/app/services/modals/modals.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-editar-endereco',
  templateUrl: './editar-endereco.page.html',
  styleUrls: ['./editar-endereco.page.scss'],
})
export class EditarEnderecoPage implements OnInit {
  public endereco: Endereco;
  public enderecos;
  public index;
  public loading: boolean = false;
  public formulario: FormGroup;
  public controladores: Object;
  public botao: boolean = true;
  public cor: string;
  public isLocal: any;
  constructor(
    private utils: UtilsService,
    private api: ApiService,
    private formBuilder: FormBuilder,
    private events: EventsService,
    private keyboard: Keyboard,
    private platform: Platform,
    private modalController: ModalController,
    private modals: ModalsService,
    public loadingController: LoadingController
  ) {}

  ngOnInit() {
    this.endereco = this.utils.getStorage('enderecos')[this.index];

    if (this.endereco === undefined) {
      this.endereco = this.index;
    }

    this.controladores = {
      // ...(!this.isLocal && { nome: 'O apelido' }),
      apelido: 'O apelido',
      cep: 'O CEP',
      endereco: 'O logradouro',
      numero: 'O número',
      bairro: 'O bairro',
      cidade: 'A cidade',
      estado: 'O estado',
      tipo: 'O tipo de endereço',
    };

    // this.endereco = this.isLocal
    //   ? this.utils.getStorage('enderecos')[this.index]
    //   : this.utils.getStorage('enderecos_edicao');
    // this.endereco = this.utils.getStorage('enderecos')[this.index];

    this.formulario = this.formBuilder.group({
      // ...(!this.isLocal && {
      //   apelido: [this.endereco?.apelido, Validators.required],
      // }),
      apelido: [this.endereco?.apelido, Validators.required],
      cep: [this.endereco?.cep, Validators.required],
      logradouro: [this.endereco?.logradouro, Validators.required],
      numero: [this.endereco?.numero, Validators.required],
      complemento: [this.endereco?.complemento],
      bairro: [this.endereco?.bairro, Validators.required],
      cidade: [this.endereco?.cidade, Validators.required],
      estado: [this.endereco?.estado, Validators.required],
      pontoReferencia: [this.endereco?.ponto_referencia],
      tipo: [this.endereco?.tipo, Validators.required],
    });

    // this.platform.ready().then(() => {
    //   this.keyboard.onKeyboardDidShow().subscribe(() => this.botao = false);
    //   this.keyboard.onKeyboardDidHide().subscribe(() => this.botao = true);
    // });
  }

  async buscarCep() {
    let cep: string = this.formulario.get('cep').value;

    if (cep.length === 9) {
      const req = {
        cep: cep,
      };

      const loading = await this.loadingController.create({
        message: 'Buscando CEP...',
      });

      await loading.present();

      const res = await this.api.get('buscar_cep', req);

      if (res.status === 'success') {
        this.formulario.patchValue({
          logradouro: res.data.logradouro,
          bairro: res.data.bairro,
          cidade: res.data.cidade,
          estado: res.data.estado,
        });

        await loading.onDidDismiss();
      } else {
        this.utils.exibirToast(res.message, 'erro');
      }
    }
  }

  async voltar() {
    await this.modals.fecharModal();
  }

  async salvar() {
    if (this.utils.validaForm(this.formulario, this.controladores)) {
      if (!this.utils.validaCEP(this.formulario.get('cep').value)) {
        this.utils.exibirToast('CEP inválido!', 'erro');
        return;
      }

      this.loading = true;

      this.enderecos = this.utils.getStorage('enderecos');
      const req = {
        id: this.endereco.id,
        apelido: this.formulario.get('apelido').value,
        cep: this.formulario.get('cep').value,
        logradouro: this.formulario.get('logradouro').value,
        numero: this.formulario.get('numero').value,
        complemento: this.formulario.get('complemento').value,
        bairro: this.formulario.get('bairro').value,
        cidade: this.formulario.get('cidade').value,
        estado: this.formulario.get('estado').value,
        pontoReferencia: this.formulario.get('pontoReferencia').value,
        tipo: this.formulario.get('tipo').value,
        principal: this.endereco.principal,
        lat: this.endereco.lat,
        lng: this.endereco.lng,
      };

      this.loading = true;

      const res = await this.api.put('endereco_pedido', req);

      this.loading = false;

      if (res.status === 'success') {
        this.enderecos = req;

        let endereco_pedido = await this.api.get('endereco_pedido');

        let enderecos = this.utils.getStorage('enderecos');
        
        enderecos.forEach((endereco,index) => {
          if (endereco.id == req.id) {
            res.data.forEach(e => {
              if (endereco.id == e.id) {

                  enderecos[index].apelido  = e.nome;
                  enderecos[index].bairro = e.bairro;
                  enderecos[index].cep = e.cep;
                  enderecos[index].cidade = e.cidade;
                  enderecos[index].cliente_id = e.cliente_id;
                  enderecos[index].complemento = e.complemento;
                  enderecos[index].endereco = e.endereco;
                  enderecos[index].estado = e.estado;
                  enderecos[index].id = Number(e.id);
                  enderecos[index].lat = e.latitude;
                  enderecos[index].latitude = e.latitude;
                  enderecos[index].lng = e.longitude;
                  enderecos[index].logradouro = e.endereco;              
                  enderecos[index].longitude = e.longitude;
                  enderecos[index].nome =  e.nome;
                  enderecos[index].numero = e.numero;
                  enderecos[index].principal = e.principal == 0 ? false  : true; 
                  enderecos[index].ponto_referencia = e.ponto_referencia;
                  enderecos[index].tipo = e.tipo;
                                  
              }
            });
          }
        });

        enderecos[0].principal = true;
        
        this.utils.removeStorage('enderecos');
        this.utils.setStorage('enderecos', enderecos);
        this.events.publish('atualizar-lista-endereco');
        this.events.publish('atualizar-endereco', enderecos);
      }

      this.loading = false;
      this.modalController.dismiss();
    }
  }
}
