import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-filtro-fidelidade-web',
  templateUrl: './filtro-fidelidade-web.page.html',
  styleUrls: ['./filtro-fidelidade-web.page.scss'],
})
export class FiltroFidelidadeWebPage implements OnInit {
  public mes: number;
  public selectedRadioGroup: any;

  constructor(
    private modalController: ModalController
  ) { }

  ngOnInit() {
  }

  closeModal() {
    this.modalController.dismiss()
  }

  radioGroupChange(event) {
    this.selectedRadioGroup = event.detail.value;
  }

  selecionarMeses() {
    this.modalController.dismiss({ mes: this.selectedRadioGroup })
  }
}
