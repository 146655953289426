import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { LoadingController, ModalController, Platform } from '@ionic/angular';
import { Endereco } from 'src/app/interfaces/endereco';
import { ApiService } from 'src/app/services/api.service';
import { EventsService } from 'src/app/services/events.service';
import { ModalsService } from 'src/app/services/modals/modals.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-adicionar-endereco',
  templateUrl: './adicionar-endereco.page.html',
  styleUrls: ['./adicionar-endereco.page.scss'],
})
export class AdicionarEnderecoPage implements OnInit {

  public endereco: Endereco;
  public loading: boolean = false;
  public formulario: FormGroup;
  public controladores: Object;
  public botao: boolean = true;
  public cor: string;

  constructor(
    private utils: UtilsService,
    private api: ApiService,
    private formBuilder: FormBuilder,
    private events: EventsService,
    private keyboard: Keyboard,
    private platform: Platform,
    private modalController: ModalController,
    private modals: ModalsService,
    public loadingController: LoadingController

  ) {

    var root = document.documentElement;
    this.cor = this.utils.getStorage('cor');
    root.style.setProperty('--cor', this.cor);

    if (this.utils.getStorage('novo-endereco') === null) {
      this.endereco = this.utils.getStorage('endereco');
    }

    this.formulario = this.formBuilder.group({
      apelido: ['', Validators.required],
      cep: [this.endereco?.cep, Validators.required],
      logradouro: [this.endereco?.logradouro, Validators.required],
      numero: [this.endereco?.numero, Validators.required],
      complemento: [this.endereco?.complemento],
      bairro: [this.endereco?.bairro, Validators.required],
      cidade: [this.endereco?.cidade, Validators.required],
      estado: [this.endereco?.estado, Validators.required],
      pontoReferencia: [this.endereco?.ponto_referencia],
      tipo: ['Residencial', Validators.required]
    });

    this.controladores = {
      apelido: 'O apelido',
      cep: 'O CEP',
      logradouro: 'O logradouro',
      numero: 'O número',
      bairro: 'O bairro',
      cidade: 'A cidade',
      estado: 'O estado',
      tipo: 'O tipo de endereço'
    };

  }

  ngOnInit() {

    // this.platform.ready().then(() => {
    //   this.keyboard.onKeyboardDidShow().subscribe(() => this.botao = false);
    //   this.keyboard.onKeyboardDidHide().subscribe(() => this.botao = true);
    // });

  }

  async buscarCep() {

    let cep: string = this.formulario.get('cep').value;

    if (cep.length === 9) {

      const req = {
        cep: cep
      };

      const loading = await this.loadingController.create({
        message: 'Buscando CEP...',
      });

      await loading.present();

      const res = await this.api.get('buscar_cep', req);

      if (res.status === 'success') {

        this.formulario.patchValue({
          logradouro: res.data.logradouro,
          bairro: res.data.bairro,
          cidade: res.data.cidade,
          estado: res.data.estado
        });

        await loading.onDidDismiss()

      } else {
        this.formulario.patchValue({
          logradouro: "",
          bairro: "",
          cidade: "",
          estado: ""
        });
        this.utils.exibirToast(res.message, 'erro');
      }

    }

  }

  async salvar() {
    return;
    if (this.utils.validaForm(this.formulario, this.controladores)) {

      if (!this.utils.validaCEP(this.formulario.get('cep').value)) {
        this.utils.exibirToast('CEP inválido!', 'erro');
        return;
      }

      const req = {
        apelido: this.formulario.get('apelido').value,
        cep: this.formulario.get('cep').value,
        logradouro: this.formulario.get('logradouro').value,
        numero: this.formulario.get('numero').value,
        complemento: this.formulario.get('complemento').value,
        bairro: this.formulario.get('bairro').value,
        cidade: this.formulario.get('cidade').value,
        estado: this.formulario.get('estado').value,
        pontoReferencia: this.formulario.get('pontoReferencia').value,
        tipo: this.formulario.get('tipo').value
      };

      this.loading = true;

      const res = await this.api.post('endereco_pedido', req);

      this.loading = false;

      if (res.status === 'success') {

        let enderecos: Array<Endereco> = this.utils.getStorage('enderecos');

        enderecos.forEach(endereco => {
          endereco.principal = false;
        });

        let enderecosCliente: Array<Endereco> = this.utils.getStorage('usuario')['enderecos'];

        enderecosCliente.forEach(endereco => {
          endereco.principal = false;
        });

        const enderecoAtual = enderecos[0];

        enderecos.splice(0, 1);
        enderecosCliente.splice(0, 1)

        enderecos.unshift(res.data);
        enderecosCliente.unshift(res.data);

        enderecos.unshift(enderecoAtual);
        enderecosCliente.unshift(enderecoAtual);

        let usuario = this.utils.getStorage('usuario');
        usuario.enderecos = enderecosCliente;

        this.utils.setStorage('usuario', usuario);
        this.utils.setStorage('enderecos', enderecos);

        await this.modals.fecharModal();
        this.events.publish('novo-endereco');

      }

    }

  }

}

