import { EventsService } from './../../services/events.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { ModalController, Platform } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';
import { UtilsService } from 'src/app/services/utils.service';
import { ModalsService } from 'src/app/services/modals/modals.service';
import { MERCADOPAGO_TOKEN } from 'src/environments/environment';
declare var MercadoPago;

@Component({
  selector: 'app-adicionar-cartao-mp',
  templateUrl: './adicionar-cartao-mp.page.html',
  styleUrls: ['./adicionar-cartao-mp.page.scss'],
})
export class AdicionarCartaoMpPage implements OnInit {

  public formulario;
  public controladores: Object;
  public loading: boolean = false;
  public dados_cartao;
  public modal_title = 'Adicionar Cartão';

  public mp: any;

  constructor(
    private modalController: ModalController,
    private utils: UtilsService,
    private formBuilder: FormBuilder,
    private api: ApiService,
    private keyboard: Keyboard,
    private platform: Platform,
    private events: EventsService,
    private modals: ModalsService
  ) {}

  ngOnInit() {
    this.formulario = this.formBuilder.group({
      apelido: [this.dados_cartao.apelido, Validators.required],
      // numero: [this.dados_cartao.numero, Validators.required],
      titular: [this.dados_cartao.titular, Validators.required],
      cpf: [this.dados_cartao.cpf, Validators.required],
      // validade: [this.dados_cartao.validade, Validators.required],
      // cvv: [this.dados_cartao.cvv, Validators.required]
    });

    if (this.dados_cartao != '') {

      if(this.dados_cartao ==  'CREDIT'){
      this.modal_title = 'Adicionar cartão de crédito';
      } else if(this.dados_cartao ==  'DEBIT') {
        this.modal_title = 'Adicionar cartão de débito';
      } else if(this.dados_cartao == 'MEAL_VOUCHER') {
        this.modal_title = 'Adicionar vale refeição';
      } else {
        this.modal_title = 'Editar Cartão';
      }
    }

    setTimeout(() => {
      this.mp = new MercadoPago(MERCADOPAGO_TOKEN);

      const optMp = {
        height: '5vw',
        marginTop: '3vw',
        color: '#626264',
        border: '1px solid black',
        'border-radius': '10px',
        'placeholder-color': '#626264',
      };
      const optMpDuplo = {
        height: '10vw',
        marginTop: '6vw',
        color: '#626264',
        border: '1px solid black',
        'border-radius': '10px',
        'placeholder-color': '#626264',
      };

      const cardNumberElement = this.mp.fields
        .create('cardNumber', {
          placeholder: 'Número*',
          style: optMp,
        })
        .mount('cardNumber');

      const expirationDateElement = this.mp.fields
        .create('expirationDate', {
          placeholder: 'Validade*',
          style: optMpDuplo,
        })
        .mount('expirationDate');

      const securityCodeElement = this.mp.fields
        .create('securityCode', {
          placeholder: 'Cód. Segurança*',
          style: optMpDuplo,
        })
        .mount('securityCode');
    }, 1000);
  }

  async validarCartao(req) {
    let token;

    try {
      token = await this.mp.fields.createCardToken(req);
      return token;
    } catch (error) {
      this.utils.exibirToast(
        'Houve um problema ao cadastrar o cartão. Revise os dados do cartão!',
        'erro'
      );
    }
  }

  async cadastrar() {
    if (this.utils.validaForm(this.formulario, this.controladores)) {
      if (!this.utils.validaCPF(this.formulario.get('cpf').value)) {
        this.utils.exibirToast('CPF inválido!', 'erro');
        return;
      }

      // if (!this.utils.validaValidade(this.formulario.get('validade').value)) {
      //   this.utils.exibirToast('Data de validade expirada!', 'erro');
      //   return;
      // }

      let usuario = this.utils.getStorage('usuario');
      let cartoes: Array<any> = usuario.cartoes;

      if (cartoes.length === 5) {
        this.utils.exibirToast('Você só pode cadastrar até 5 cartões!', 'erro');
        return;
      }

      const tokenReq = {
        cardholderName: this.formulario.get('titular').value,
        identificationType: 'CPF',
        identificationNumber: this.utils.removeChar(
          this.formulario.get('cpf').value
        ),
      };

      let req = {
        apelido: this.formulario.get('apelido').value,
        // numero: this.formulario.get('numero').value,
        titular: this.formulario.get('titular').value,
        cpf: this.formulario.get('cpf').value,
        // validade: this.formulario.get('validade').value,
        // cvv: this.formulario.get('cvv').value,
      };

      const token = await this.validarCartao(tokenReq);

      if (token != undefined) {
        this.loading = true;
        if (token.id != null || token.status == 'active') {
          req['token'] = token.id;
          req['final'] = token.last_four_digits;

          this.api.post('cartao', req).then((res: any) => {
            this.loading = false;

            if (res.status === 'success') {
              cartoes.unshift(res.data);

              usuario.cartoes = cartoes;
              this.utils.setStorage('usuario', usuario);

              this.utils.exibirToast(res.message);
              this.events.publish('atualizar-cartoes');
              this.modals.fecharModal().then(() => {
                // this.events.publish('atualizar-cartoes');
              });
            } else {
              this.utils.exibirToast(res.message, 'erro');
            }
          });
        } else {
          this.loading = false;
          this.utils.exibirToast(
            'Houve um problema ao cadastrar o cartão!',
            'erro'
          );
        }
      }
    }
  }

  fechar() {
    this.modalController.dismiss();
  }

}
