import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loja-card',
  templateUrl: './loja-card.component.html',
  styleUrls: ['./loja-card.component.scss'],
})
export class LojaCardComponent implements OnInit {

  @Input() name: string;
  @Input() address: string;
  @Input() preparation: string;
  @Input() distance: string;
  @Input() icon: string;
  @Input() status: string;
  @Input() horario: string;

  constructor() { }

  ngOnInit() {}

}
